.template-search-container{
  height: 100%;
  overflow-y: auto;
  .search-filters{
    padding-left: 21px;
    padding-right: 21px;
    height: 90px;
    background-color: #ffffff;
    margin-bottom: 25px;
    .filters__mobile-toggle{
      width: 53px;
      background-color: #8273f6;
      color: white;
      height: 50px;
      border-radius: 6px;

      i{
        font-size: 26px;
      }
    }
    .query-search{
      padding: 23px 29px 23px 0;
      width: 35%;
      height: 100%;
      color: #273d52;
      font-size: 1.7rem;
      cursor: pointer;
      border-right: 1px solid #eef0f3;
      margin-right: 28px;
      i{
        width: 1.04em;
        margin-right: 17px;
        font-size: 2.4rem;
        color: #273d52;
      }
    }
    .template-search__filters__label {
      display: inline-block;
      color: #8996a2;
      font-size: 1.5rem;
      margin-right: 20px;
    }
    .template-search__filters__select {
      margin-right: 12px;
    }
    .u-select {
      position: relative;
      .template-search__filters__select__input {
        padding: 12px 45px 12px 15px;
        height: 52px;
        font-size: 1.7rem;
      }
      .fa-chevron-down{
        width: 0.58333em;
        right: 28px;
        position: absolute;
        top: 38%;
        font-size: 1.5rem;
      }
    }
  }
  .search-results{
    padding-bottom: 25px;
    .line-result{
      background-color: #ffffff;
      border-radius: 6px;
      margin-bottom: 15px;
      -webkit-transition: all 0.35s ease;
      -o-transition: all 0.35s ease;
      transition: all 0.35s ease;
      &.open{
        .result-content-right{
          .fa-chevron-up{
            -ms-transform: rotate(180deg);
            transform: rotate(180deg);
          }
        }
      }
      .result-header{
        padding: 28px 0;
        .thumb-wrapper{
          position: relative;
          margin-left: 21px;
          width: 115px;
          border-radius: 6px;
          overflow: hidden;
          display:block;
          height: 81px;
          img{
            display: block;
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
          }
        }
        .result-content{
          padding-left: 24px;
          padding-right: 10px;
          position: relative;
          .template-search__result__title {
            display: block;
            color: #273d52;
            font-size: 1.6rem;
            overflow: hidden;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
          }
          .template-search__result__description {
            padding-bottom: 8px;
            display: block;
            color: #8996a2;
            font-size: 1.5rem;
            #white-space: nowrap;
            overflow: hidden;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
          }


        }
        .result-content-right{
          .template-search__result__details{
            color: #8996a2;
            font-size: 1.4rem;
            .template-search__result__details__date {
              margin-right: 14px;
            }
            .progress {
              position: relative;
              display: inline-block;
              width: 97px;
              height: 5px;
              border-radius: 4px;
              background-color: #dedfe5;
              margin-right: 12px;
              .progress-bar {
                background-color: #8B77FB;
                height: 20px;
              }
            }
            .template-search__result__details__timer__percent {
              color: #8B77FB;
            }
          }
          .template-search__result__class {
            padding: 0 25px;
            border-left:1px solid #eef0f3;
            border-right:1px solid #eef0f3;
            a{
              background-color: #f25a45;
              color: white;
              padding: 5px 10px;
              font-size: 14px;
              &:hover{
                background-color: darken(#f25a45,15%);
              }
              &.reload{
                background-color: #8B77FB!important;
                border:1px solid #8B77FB;
                font-size: 15px;
                &:hover{
                  background-color: darken(#8B77FB,10%)!important;
                }
              }
            }
            .template-search__result__class__content {
              padding: 14px 22px;
              display: block;
              border-radius: 25px;
              color: #ffffff;
              font-family: "RubikMd";
              font-size: 16px;
            }

          }
          .template-search__result__icon{
            width: 116px;
            cursor: pointer;
            i{
              height: 1em;
              width: 0.58333em;
              font-size: 21px;
              -webkit-transition: -webkit-transform 0.35s ease;
              transition: -webkit-transform 0.35s ease;
              -o-transition: transform 0.35s ease;
              transition: transform 0.35s ease;
              transition: transform 0.35s ease, -webkit-transform 0.35s ease;
            }
          }
        }
      }
      .result-dropdown{
        display:none;
        &.open{
          display:block;
        }
        .result-dropdown-container{
            border-top: 1px solid #eef0f3;
          .row-result-dropdown{
            padding: 30px 0;
            width: 75%;
            border-bottom: 1px solid #eef0f3;
            margin: 0 auto;
            .result-dropdown-number {
              width: 32px;
              height: 32px;
              color: #ffffff;
              font-size: 1.4rem;
              text-align: center;
              border: 1px solid rgba(255, 255, 255, 0.2);
              border-radius: 50%;
              margin-right: 24px;
              &.completed{
                border: none;
                background-color: #27c281;
              }
              &.number{
                font-size: 1.4rem;
                text-align: center;
                background-color: #273d52;
              }
            }
            .template-search__result__dropdown__title {
              width: 175px;
              .template-search__result__dropdown__title__type {
                display: block;
                color: #273d52;
                font-size: 1.5rem;
              }
              .template-search__result__dropdown__title__name {
                display: block;
                color: #8996a2;
                font-size: 1.4rem;
              }
              .template-search__result__dropdown__resume {
                color: #273d52;
                font-size: 1.4rem;
              }
            }
            .number{

            }
          }
        }
      }
      .template-program-launch{
        a{
          background-color: #f25a45;
          color: white;
          padding: 5px 10px;
          font-size: 14px;
          &:hover{
            background-color: darken(#f25a45,15%);
          }
          &.reload{
            background-color: #8B77FB!important;
            border:1px solid #8B77FB;
            font-size: 15px;
            &:hover{
              background-color: darken(#8B77FB,10%)!important;
            }
          }
        }
      }
      @media (min-width: 767.98px) {
        .line-search-left-block{
          width: 81%;
          .thumb-wrapper{
            width: 12%;
          }
          .result-content{
            width: 88%;
          }
        }
        .line-search-right-block{

        }
      }

    }
  }
}
#js-search-popin-filer-mobile{
  height: 100vh;
  .search-popin__container{
    padding: 150px 9% 0 9%;
    .u-select .fa-chevron-down {
      width: 0.58333em;
      right: 28px;
      position: absolute;
      top: 38%;
      font-size: 1.5rem;
    }
  }
}