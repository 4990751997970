.menu-list-programs{
  position: absolute;
  border-radius: 0px;
  top: 0;
  left: 85px;
  bottom: 0;
  width: calc( 100% - 85px);
  background-color: rgba(0, 0, 0, 0.78);
  overflow: hidden;
  pointer-events: none;
  max-width: 1920px;
  margin: 0 auto;
  opacity: 0;
  -webkit-transition: opacity 0s ease 0.35s;
  -o-transition: opacity 0s ease 0.35s;
  transition: opacity 0s ease 0.35s;
  z-index: 45;
  &.open{
    opacity: 1;
    -webkit-transition: opacity 0s ease 0s;
    -o-transition: opacity 0s ease 0s;
    transition: opacity 0s ease 0s;
    pointer-events: all;

    .list-programs-main {
      -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      transform: translate(0, 0);
      -webkit-box-shadow: 0 0 15px 1px rgb(5 16 43 / 17%);
      box-shadow: 0 0 15px 1px rgb(5 16 43 / 17%);
      pointer-events: all;
    }

  }
  .list-programs-mobile-bar{
    width: 100%;
    height: 70px;
    color: #ffffff;
    background-color: #302b98;
  }
}

.list-programs-main {
  left: 0;
  background-color: #302b98;
  width: 25%;
  z-index: 30;
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transition: -webkit-transform 0.4s ease, -webkit-box-shadow 0.4s ease;
  transition: -webkit-transform 0.4s ease, -webkit-box-shadow 0.4s ease;
  -o-transition: transform 0.4s ease, box-shadow 0.4s ease;
  transition: transform 0.4s ease, box-shadow 0.4s ease, -webkit-transform 0.4s ease, -webkit-box-shadow 0.4s ease;
  .list-programs-items{
    display: block;
    height: calc( 100% - 85px);
  }
  .programs-item{
    height: 88px;
    .item-title {}
  }
}

.list-programs-col {
  position: absolute;
  top: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}
.programs-head{
  padding: 19px 31px 18px 31px;
  height: 85px;
  background-color: #3a35a6;
  border-bottom: 1px solid rgba(226, 226, 230, 0.11);
  .head-select{
    .select-label{
      position: absolute;
      top: 0;
      left: 0;
      color: rgba(255, 255, 255, 0.4);
      font-size: 1.4rem;
      pointer-events: none;
    }
    #level{
      padding-top: 25px;
      padding-bottom: 5px;
      padding-left: 0;
      color: #ffffff;
      border: none !important;
      font-size: 1.5rem;
      cursor: pointer;
      appearance: none;
      background: #3A35A6;
    }
    .fa-chevron-down{
      position: absolute;
      top: 50%;
      right: 15px;
      height: 1em;
      fill: currentColor;
      vertical-align: middle;
      overflow: hidden;
      color: white;
    }
  }
}

.list-programs-items{
  padding-left: 0;
  display: none;
  margin: 0;
  height: 100%;
  overflow-y: auto;
  list-style: none;
  .programs-item{
    padding: 32px 60px 31px 32px;
    &.mission-exam{
      background-color: $pink;
      font-weight: bold;
      color: white;
    }
    .item-title {
      display: block;
      width: 100%;
      font-size: 1.6rem;
      line-height: 2.5rem;
    }
    .item-details{
      margin-top: 9px;
      color: #8996a2;
      font-size: 1.4rem;
      .item-details-count{
        margin-right: 14px;
      }
      .details-progress{
        width: 97px;
        height: 5px;
        border-radius: 4px;
        margin-right: 12px;
        margin-top: 10px;
        background-color: #56548A;
        .progress-bar{
          background-color: #8B77FB;
        }
      }
      .item-details-percent{
        color: #8B77FB;
      }

    }
  }
  &.active{
    display:block;
  }

}

.programs-item{
  padding: 32px 60px 32px 45px;
  position: relative;
  height: 132px;
  width: 100%;
  color: rgba(255, 255, 255, 0.4);
  -webkit-transition: all 0.35s ease;
  -o-transition: all 0.35s ease;
  transition: all 0.35s ease;
  cursor: pointer;
  z-index: 5;
  &.active,&:hover{
    color: white;
    background-color: rgba(0, 0, 0, 0.15);
    .u-icon-play{
      display: flex !important;
    }
  }
  i.fa-chevron-right{
    position: absolute;
    top: 50%;
    right: 50px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    font-size: 1.4rem;
    height: 1em;
  }
}
.programs-item-title{
  display: block;
  width: 100%;
  font-size: 1.6rem;
  line-height: 2.5rem;
}

.col-second{
  &.active,&.open{
    width: 33%;
    //-webkit-transform: translate(0, 0);
    //-ms-transform: translate(0, 0);
    //transform: translate(0, 0);
    -webkit-box-shadow: 0 0 15px 1px rgb(5 16 43 / 17%);
    box-shadow: 0 0 15px 1px rgb(5 16 43 / 17%);
    pointer-events: all;
    transform:translate(0px, 0);
  }
  left: 25%;
  width: 0%;
  background-color: #262278;
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transition: -webkit-transform 0.4s ease, -webkit-box-shadow 0.4s ease;
  transition: -webkit-transform 0.4s ease, -webkit-box-shadow 0.4s ease;
  -o-transition: transform 0.4s ease, box-shadow 0.4s ease;
  transition: transform 0.4s ease, box-shadow 0.4s ease, -webkit-transform 0.4s ease, -webkit-box-shadow 0.4s ease;
  pointer-events: none;
  z-index: 20;

}
.col-third{
  left: calc( 33% + 25%);
  width: 0%;
  background-color: #221f6c;
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transition: -webkit-transform 0.4s ease, -webkit-box-shadow 0.4s ease;
  transition: -webkit-transform 0.4s ease, -webkit-box-shadow 0.4s ease;
  -o-transition: transform 0.4s ease, box-shadow 0.4s ease;
  transition: transform 0.4s ease, box-shadow 0.4s ease, -webkit-transform 0.4s ease, -webkit-box-shadow 0.4s ease;
  z-index: 10;
  &.active,&.open{
    width: calc( 100% - 25% - 30%);
    -webkit-box-shadow: 0 0 15px 1px rgb(5 16 43 / 17%);
    box-shadow: 0 0 15px 1px rgb(5 16 43 / 17%);
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    pointer-events: all;
  }
  .programs-item{
    text-decoration: none;
    padding: 32px 46px;
    &.selected{
      background-color: rgba(0, 0, 0, 0.15);
    }
    .item-thumb-wrapper{
      position: relative;
      width: 110px;
      height: 80px;
      border-radius: 6px;
      overflow: hidden;
      .item-thumb{
        display: block;
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
      }
      .u-icon-play {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        display: none;
        width: 41px;
        height: 41px;
        border-radius: 50%;
        background-color: #ffffff;
        z-index: 1;
        i {
          color: #8B77FB;
          font-size: 1.4rem;
          margin-left: 4px;
          z-index: 2;
        }
      }
    }
    .item-content{
      padding-left: 40px;
      width: calc(100% - 110px);
    }
    .item-access-free{
      background-color: darkgreen;
      color: white;
      font-size: 12px;
      padding: 2px 5px;
      border-radius: 3px;
      margin-bottom: 5px;
      font-weight: bold;
    }
  }
}