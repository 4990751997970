/*=============================================
=             Colours                         =
=============================================*/
/************ layout ***********************/
.bg-lbp {
  background-image: url("/public/static/img/commun/background-login.jpeg");
  background-size: cover;
  background-position: center;
  background-color: #423cb0;
}

.btn-raduis, .btn-update-o {
  border-radius: 30px;
}

.btn-pink {
  color: #fff;
  background-color: #8273f6 !important;
  border-color: #8273f6 !important;
}
.btn-pink.btn-form {
  -webkit-box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.11), 0 7px 10px 0 rgba(39, 61, 82, 0.13);
  box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.11), 0 7px 10px 0 rgba(39, 61, 82, 0.13);
  padding: 0px 20px 0px 20px;
  height: 46px;
  font-size: 1.5rem;
  line-height: 1;
}
.btn-pink:hover {
  background-color: #6868bf;
  border-color: #6868bf;
  color: #fff;
}

.btn-pink-o {
  color: #8273f6;
  background-color: white;
  border-color: #8273f6;
}
.btn-pink-o:hover {
  background-color: #6868bf;
  border-color: #6868bf;
  color: #fff;
}

.btn-purple {
  color: #fff;
  background-color: #e83279;
  border-color: #e83279;
}
.btn-purple.btn-form {
  -webkit-box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.11), 0 7px 10px 0 rgba(39, 61, 82, 0.13);
  box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.11), 0 7px 10px 0 rgba(39, 61, 82, 0.13);
  padding: 0px 20px 0px 20px;
  height: 46px;
  font-size: 1.5rem;
  line-height: 1;
}
.btn-purple:hover {
  background-color: #d5326a;
  border-color: #d5326a;
  color: #fff;
}

.btn-green {
  color: #fff;
  background-color: #27c281;
  border-color: #27c281;
}
.btn-green:hover {
  background-color: #1e9865;
  border-color: #1e9865;
  outline: none;
  color: #fff;
}

.btn-grey {
  color: black;
  background-color: #ececec;
  border-color: #ececec;
}
.btn-grey:hover {
  background-color: #c6c6c6;
  border-color: #c6c6c6;
  outline: none;
}

.btn-white-o {
  color: black;
  background-color: white;
  border-color: white;
}
.btn-white-o:hover {
  background-color: transparent;
  color: #fff;
}

.btn-update-o {
  border: 1px solid #273d52;
  color: black;
  -webkit-box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.11);
  box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.11);
  background-color: white;
}
.btn-update-o:hover {
  background-color: #8273f6;
  border-color: #8273f6;
  color: #fff;
  text-decoration: none;
}

.popin-style {
  background-color: #ffffff;
  border-radius: 6px;
  -webkit-box-shadow: 0 10px 25px 5px rgba(5, 16, 43, 0.24);
  box-shadow: 0 10px 25px 5px rgba(5, 16, 43, 0.24);
}
.popin-style .back-btn .back-btn-label {
  font-size: 16px;
}
.popin-style .back-btn .back-btn-label i {
  margin-right: 20px;
}
.popin-style .popin__close {
  position: absolute;
  top: 0px;
  right: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 70px;
  height: 70px;
  background-color: #8B77FB;
  border: none;
  cursor: pointer;
  color: white;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: background-color 0.35s ease 0s, -webkit-transform 0.45s ease 0s;
  transition: background-color 0.35s ease 0s, -webkit-transform 0.45s ease 0s;
  -o-transition: transform 0.45s ease 0s, background-color 0.35s ease 0s;
  transition: transform 0.45s ease 0s, background-color 0.35s ease 0s;
  transition: transform 0.45s ease 0s, background-color 0.35s ease 0s, -webkit-transform 0.45s ease 0s;
}
.popin-style .popin__close i {
  font-size: 20px;
}
.popin-style.open .popin__close {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 0.4s ease 0s;
  transition: -webkit-transform 0.4s ease 0s;
  -o-transition: transform 0.4s ease 0s;
  transition: transform 0.4s ease 0s, -webkit-transform 0.4s ease 0s;
}

.thumb-wrapper {
  position: relative;
  width: 130px;
  height: 130px;
  overflow: visible;
}
.thumb-wrapper .u-admin-clock {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  top: -10px;
  right: 0;
  height: 46px;
  width: 46px;
  border-radius: 50%;
  color: white;
  font-size: 15px;
  background-color: #423cb0;
}

.item-thumb-wrapper-play {
  position: relative;
}
.item-thumb-wrapper-play .u-icon-play {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: none;
  width: 41px;
  height: 41px;
  border-radius: 50%;
  background-color: #ffffff;
  z-index: 1;
}
.item-thumb-wrapper-play .u-icon-play i {
  color: #8273f6;
  font-size: 1.4rem;
  margin-left: 4px;
  z-index: 2;
}

.u-input textarea, .u-textarea textarea {
  height: 149px;
  width: 100%;
}

.u-input-label, .u-textarea-label {
  font-size: 1.4rem;
  color: #939ea8;
}

.u-input input, .u-input textarea, .u-textarea input, .u-textarea textarea {
  padding: 10px 13px;
  font-size: 1.5rem;
  border-radius: 6px;
  border: 1px solid rgba(137, 150, 162, 0.3);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  color: #273d52;
  -webkit-transition: border-color 0.35s ease;
  -o-transition: border-color 0.35s ease;
  transition: border-color 0.35s ease;
  resize: none;
}

.custom-select select {
  padding: 10px 35px 10px 13px;
  font-size: 1.5rem;
  border-radius: 6px;
  border: 1px solid rgba(137, 150, 162, 0.3);
  background: transparent;
  color: #273d52;
  transition: border-color 0.35s ease;
  cursor: pointer;
}
.custom-select i {
  position: absolute;
  top: 20px;
  right: 15px;
  font-size: 1.2rem;
}

.hide-element {
  display: none;
}

.thumb-wrapper .thumb-wrapper__letter {
  width: 100%;
  height: 100%;
  border-radius: 100px;
  display: flex;
  color: white;
  font-size: 3rem;
  background-color: #8273f6;
}
.thumb-wrapper.female .thumb-wrapper__letter {
  background-color: #e83279;
}

.portrait {
  object-fit: cover;
}

ol.custom {
  margin-left: 11px;
}
ol.custom.pink > li:before {
  background: #E83279;
}
ol.custom.purple > li:before {
  background: #8273f6;
}
ol.custom > li {
  counter-increment: item;
  margin-bottom: 7.5px;
  list-style: none;
  position: relative;
}
ol.custom > li:before {
  margin-right: 10px;
  content: counter(item);
  border-radius: 50%;
  color: white;
  width: 1.5em;
  text-align: center;
  display: inline-block;
  height: 1.5em;
  position: absolute;
  left: -33px;
}

@font-face {
  font-family: "Rubik";
  src: url("/assets/fonts/Rubik-Regular.ttf") format("ttf");
}
@font-face {
  font-family: "Rubik-Bold";
  src: url("/assets/fonts/Rubik-Bold.ttf") format("ttf");
}
@font-face {
  font-family: "NanumBrushScript";
  src: url("/assets/fonts/NanumBrushScript-Regular.ttf") format("ttf");
}
main {
  position: relative;
  min-height: 100%;
  background-color: #f3f4f6;
}

a:hover {
  text-decoration: none;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

user agent stylesheet main {
  display: block;
}

style attribute {
  --vh: 3.2px;
}

:root {
  --swiper-navigation-size: 44px;
  /* --swiper-navigation-color: var(--swiper-theme-color); */
}

:root {
  --swiper-theme-color: #007aff;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-size: 16px;
}

html {
  font-size: 62.5%;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

*, *::before, *::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

thead, tbody, tfoot, tr, td, th {
  border-width: 1px;
}

table {
  margin-bottom: 10px;
}

.u-spinner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition: all 0.35s ease;
  -o-transition: all 0.35s ease;
  transition: all 0.35s ease;
  visibility: hidden;
  opacity: 0;
  z-index: 99999999;
}
.u-spinner.open {
  visibility: visible;
  opacity: 1;
}
.u-spinner svg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  fill: #ffffff;
  font-size: 12rem;
}
.u-spinner .svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  overflow: hidden;
}

/***** CONNEXION ******/
.template-account-choice {
  overflow: hidden;
}
.template-account-choice .template-login__logo {
  position: absolute;
  top: 36px;
  left: 35px;
  width: 232px;
}
.template-account-choice .template-login__container {
  background-color: #ffffff;
  border-radius: 6px;
  -webkit-box-shadow: 0 0 35px 1px rgba(5, 16, 43, 0.06);
  box-shadow: 0 0 35px 1px rgba(5, 16, 43, 0.06);
  padding: 48px 42px;
  margin-top: 125px;
  min-width: 450px;
  margin-bottom: 125px;
}
.template-account-choice .template-login__container form[name=reset_password_request_form] .errors, .template-account-choice .template-login__container form[name=change_password_form] .errors {
  color: darkred;
  font-size: 14px;
  margin-bottom: 15px;
}
.template-account-choice .template-login__container form[name=reset_password_request_form] input, .template-account-choice .template-login__container form[name=change_password_form] input {
  height: 52px;
  font-size: 1.7rem;
  color: #273d52;
}
.template-account-choice .template-login__container form[name=reset_password_request_form] input::placeholder, .template-account-choice .template-login__container form[name=change_password_form] input::placeholder {
  text-align: center;
}
.template-account-choice .template-login__container form[name=reset_password_request_form] input::-ms-input-placeholder, .template-account-choice .template-login__container form[name=change_password_form] input::-ms-input-placeholder {
  text-align: center;
}
.template-account-choice .template-login__container form[name=reset_password_request_form] input::-ms-input-placeholder, .template-account-choice .template-login__container form[name=change_password_form] input::-ms-input-placeholder {
  text-align: center;
}
.template-account-choice .template-login__container form[name=reset_password_request_form] small, .template-account-choice .template-login__container form[name=change_password_form] small {
  font-size: 12px;
}
.template-account-choice .template-login__container form[name=reset_password_request_form] label, .template-account-choice .template-login__container form[name=change_password_form] label {
  font-size: 1.4rem;
  color: black;
}
.template-account-choice .template-login__container form[name=reset_password_request_form] button, .template-account-choice .template-login__container form[name=change_password_form] button {
  height: 40px;
  font-size: 15px;
}
.template-account-choice .template-login__container .custom-control-label, .template-account-choice .template-login__container .template-login__container__form__forgotten-password {
  font-size: 14px;
}
.template-account-choice .template-login__container label:not(.custom-control-label) {
  display: block;
  font-size: 1.4rem;
  color: #939ea8;
  margin-bottom: 12px;
}
.template-account-choice .template-login__container .template-login__container__form__title {
  display: block;
  font-size: 2.8rem;
  color: #273d52;
  margin-bottom: 32px;
}
.template-account-choice .template-login__container .template-login__container__form__group input {
  height: 52px;
  font-size: 1.7rem;
  color: #273d52;
}
.template-account-choice .template-login__container .template-login__container__form button {
  margin-top: 45px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 1.7rem !important;
  height: 60px !important;
  width: 100%;
}
.template-account-choice .template-login__container .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.template-account-choice .template-login__container .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #edebfe;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(130, 115, 246, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(130, 115, 246, 0.25);
}

/*************** CHOICE ACCOUNT ***************/
.template-account-choice__container {
  text-align: center;
  background-color: #ffffff;
  border-radius: 6px;
  -webkit-box-shadow: 0 0 35px 1px rgba(5, 16, 43, 0.06);
  box-shadow: 0 0 35px 1px rgba(5, 16, 43, 0.06);
  padding: 53px 0;
  margin-top: 125px;
}
.template-account-choice__container .template-account-choice__container__title {
  font-size: 2.8rem;
  color: #273d52;
}
.template-account-choice__container .c-account-choice-slider {
  position: relative;
}
.template-account-choice__container .c-account-choice-slider__items .c-account-choice-slider__item {
  text-decoration: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.template-account-choice__container .c-account-choice-slider__items .c-account-choice-slider__item + .c-account-choice-slider__item {
  border-left: 1px solid #eef0f3;
}
.template-account-choice__container .c-account-choice-slider__items .c-account-choice-slider__item .c-account-choice-slider__item__thumb-wrapper {
  position: relative;
  width: 130px;
  height: 130px;
  overflow: visible;
}
.template-account-choice__container .c-account-choice-slider__items .c-account-choice-slider__item .c-account-choice-slider__item__thumb-wrapper .u-admin-clock {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  top: -10px;
  right: 0;
  height: 46px;
  width: 46px;
  border-radius: 50%;
  color: white;
  font-size: 15px;
  background-color: #423cb0;
}
.template-account-choice__container .c-account-choice-slider__items .c-account-choice-slider__item .c-account-choice-slider__item__thumb {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}
.template-account-choice__container .c-account-choice-slider__items .c-account-choice-slider__item .c-account-choice-slider__item__thumb.no-image {
  font-size: 7rem;
  background-color: #8B77FB;
  color: black;
}
.template-account-choice__container .c-account-choice-slider__items .c-account-choice-slider__item .c-account-choice-slider__item__thumb.no-image.girly {
  background-color: #e83279;
  color: white;
}
.template-account-choice__container .c-account-choice-slider__items .c-account-choice-slider__item .c-account-choice-slider__item__textual .c-account-choice-slider__item__name {
  display: block;
  font-size: 1.7rem;
  font-weight: normal;
  color: #273d52;
}
.template-account-choice__container .c-account-choice-slider__items .c-account-choice-slider__item .c-account-choice-slider__item__textual .c-account-choice-slider__item__detail {
  display: block;
  color: #8996a2;
  font-size: 1.5rem;
  line-height: 2.3rem;
}
.template-account-choice__container .c-account-choice-slider__items .c-account-choice-slider__item .c-account-choice-slider__item__textual .c-account-choice-slider__item__class {
  display: block;
  color: #8996a2;
  font-size: 1.5rem;
  line-height: 2.3rem;
}
.template-account-choice__container .c-account-choice-slider__items .slick-track {
  display: flex;
  flex-direction: row;
}
.template-account-choice__container .template-account-choice__container__add-student {
  margin-top: 60px;
  display: inline-block;
  text-align: center;
  font-size: 1.6rem;
  color: #273d52;
}

.c-account-choice-slider__navigation .button-prev, .c-account-choice-slider__navigation .button-next {
  color: #ffffff;
  border-radius: 6px;
  -webkit-box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.11);
  box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.11);
  background-color: #211e6a;
  position: absolute;
  top: 0;
  width: 57px;
  height: 164px;
  border: none;
  outline: none;
  will-change: opacity, background-color, padding;
  pointer-events: all;
  z-index: 2;
}
.c-account-choice-slider__navigation .button-prev i, .c-account-choice-slider__navigation .button-next i {
  font-size: 20px;
}
.c-account-choice-slider__navigation .button-prev {
  left: -29px;
}
.c-account-choice-slider__navigation .button-next {
  right: -29px;
}

/************* DASHBOARD *****************/
.template-dashboard #template-dashboard-main {
  width: calc(100vw - 85px);
  height: calc((var(--vh, 1vh) * 100) - 85px);
}
.template-dashboard .menu-top {
  height: 85px;
  position: relative;
  z-index: 5;
}
.template-dashboard .logo-menu-top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  width: 85px;
  background-color: #423cb0;
  border-bottom: solid 1px rgba(226, 226, 230, 0.18);
  pointer-events: all;
}
.template-dashboard .logo-menu-top.bg-parents {
  background-color: #e83279;
}
.template-dashboard .menu__top__content {
  -webkit-box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.17);
  box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.17);
  z-index: 5;
  width: calc(100% - 85px);
}
.template-dashboard .menu__top__content__right {
  align-items: center;
}
.template-dashboard .menu__top__content__right .notifications {
  padding: 13px 25px;
  border-radius: 26px;
  -webkit-transition: background-color 0.4s ease;
  -o-transition: background-color 0.4s ease;
  transition: background-color 0.4s ease;
  border: none;
  color: #ffffff;
  font-size: 1.6rem;
  margin-right: 25px;
  background-color: rgba(137, 150, 162, 0.7);
}
.template-dashboard .menu__top__content__right .notifications.new-notification {
  background-color: #e83279;
}
.template-dashboard .menu__top__content__right .menu__top__content__right__account {
  padding: 19px 51px 19px 24px;
  height: 100%;
  border-left: 1px solid #eef0f3;
  cursor: pointer;
}
.template-dashboard .menu__top__content__right .menu__top__content__right__account .menu__top__content__right__account__textual {
  font-size: 1.6rem;
  line-height: 1.2;
}
.template-dashboard .menu__top__content__right .menu__top__content__right__account .menu__top__content__right__account__textual .account__name {
  color: #273d52;
}
.template-dashboard .menu__top__content__right .menu__top__content__right__account .menu__top__content__right__account__textual .account__level {
  color: #8996a2;
}
.template-dashboard .menu__top__content__right .menu__top__content__right__account .fa-chevron-down {
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  margin-left: 50px;
  display: inline;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  font-size: 18px;
  height: 1em;
  margin-top: 12px;
}
.template-dashboard .menu__top__content__right .menu__top__content__right__account.active .fa-chevron-down {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.template-dashboard .menu__top__content__right .menu__top__content__right__account .c-site-menu__top__content__right__account__thumb-wrapper--no-image {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  border-radius: 50%;
  color: #ffffff;
  background-color: #e83279;
}
.template-dashboard .menu__top__content__right .menu__top__content__right__account .menu__top__content__right__account__thumb-wrapper {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
  overflow: hidden;
}
.template-dashboard .menu__top__content__right .menu__top__content__right__account .menu__top__content__right__account__thumb-wrapper--no-image {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  border-radius: 50%;
  color: #ffffff;
  background-color: #e83279;
  text-align: center;
  line-height: 49px;
}
.template-dashboard .menu__top__content__right .menu__top__content__right__account .menu__top__content__right__account__thumb-wrapper--no-image .menu__top__content__right__account__thumb-wrapper__letter {
  display: block;
  font-size: 2.2rem;
}
.template-dashboard .menu__top__content__right .menu__top__content__right__burger {
  justify-content: center;
  height: 100%;
  width: 70px;
  lign-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-pack: center;
}
.template-dashboard .menu__top__content__right .menu__top__content__right__burger .menu__top__content__right__burger__wrapper {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: none;
}
.template-dashboard .menu__top__content__right .menu__top__content__right__burger .menu__top__content__right__burger__wrapper i {
  font-size: 20px;
}
.template-dashboard .menu__left {
  transition: width 0.35s cubic-bezier(0.175, 0.685, 0.32, 1);
  position: fixed;
  width: 85px;
  background-color: #423cb0;
  pointer-events: all;
  z-index: 50;
  height: calc( 100vh - 85px);
  overflow-y: auto;
}
.template-dashboard .menu__left:hover {
  width: 16em;
}
.template-dashboard .menu__left:hover .menu__left__item__tooltip {
  width: calc(100% - calc(14rem - 50px)) !important;
  opacity: 1 !important;
  color: white;
  margin-left: 74px;
}
.template-dashboard .menu__left .menu__left__item a {
  transition: all 0.2s cubic-bezier(0.175, 0.685, 0.32, 1);
}
.template-dashboard .menu__left .menu__left__item a .menu__left__item__tooltip {
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all 0.35s cubic-bezier(0.175, 0.685, 0.32, 1);
  overflow: hidden;
  opacity: 0;
  display: block;
  text-align: left;
}
.template-dashboard .menu__left.bg-parents {
  background-color: #e83279;
}
.template-dashboard .menu__left ul li {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  opacity: 0.2;
}
.template-dashboard .menu__left ul li .c-site-menu__left__item__tooltip {
  display: none;
}
.template-dashboard .menu__left ul li:hover {
  opacity: 1;
}
.template-dashboard .menu__left ul li.active {
  opacity: 1;
}
.template-dashboard .menu__left ul li a {
  white-space: nowrap;
  display: block;
  position: relative;
  text-decoration: none;
}
.template-dashboard .menu__left ul li a i {
  color: white;
  font-size: 2.8rem;
  position: absolute;
  left: 28px;
}
.menu__playlist-popin {
  position: absolute;
  -webkit-transform: translate(0, -10px);
  -ms-transform: translate(0, -10px);
  transform: translate(0, -10px);
  pointer-events: none;
  z-index: 2;
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.menu__playlist-popin.open {
  pointer-events: all;
  opacity: 1;
}

.menu__playlist-popin {
  border-radius: 6px 6px 0 0 !important;
  background-color: #302B98;
}
.menu__playlist-popin .list {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}
.menu__playlist-popin.open {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}
.menu__playlist-popin ul li {
  padding-left: 10px;
  padding-right: 10px;
}
.menu__playlist-popin ul li:hover {
  background-color: #232070;
}
.menu__playlist-popin ul li a {
  color: white;
}
.menu__playlist-popin ul li a .block-img {
  height: 49px;
  width: 69px;
}
.menu__playlist-popin ul li a span {
  width: calc(100% - 69px);
}
.menu__playlist-popin .exit {
  cursor: pointer;
  background-color: #e83279;
  color: white;
  border-radius: 0 0 6px 6px !important;
}
.menu__playlist-popin .exit i {
  font-size: 30px;
  margin-right: 10px;
}
.menu__playlist-popin .exit a {
  color: white;
  text-decoration: none;
}

.menu__notification-popin, .menu__profile-popin {
  position: absolute;
  -webkit-transform: translate(0, -10px);
  -ms-transform: translate(0, -10px);
  transform: translate(0, -10px);
  pointer-events: none;
  z-index: 999;
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.menu__notification-popin.open, .menu__profile-popin.open {
  pointer-events: all;
  opacity: 1;
}

.menu__notification-popin {
  top: 105px;
  right: 60px;
  background-color: #ffffff;
  width: 430px;
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.menu__notification-popin.open {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}
.menu__notification-popin .notifications-list .item {
  padding: 28px;
}
.menu__notification-popin .notifications-list .item:hover {
  background-color: rgba(243, 244, 246, 0.5);
}
.menu__notification-popin .notifications-list .item + .item {
  border-top: 1px solid #eef0f3;
}
.menu__notification-popin .notifications-list .item .item-icon {
  width: 59px;
  height: 59px;
  border-radius: 50%;
  background-color: #8996a2;
}
.menu__notification-popin .notifications-list .item .item-icon i {
  color: white;
  font-size: 2.1rem;
}
.menu__notification-popin .notifications-list .item.item-new .item-icon {
  background-color: #e83279;
}
.menu__notification-popin .notifications-list .item .item-content {
  padding-left: 21px;
  width: calc( 100% - 59px);
  color: #8996a2;
  font-size: 1.5rem;
  line-height: 2.3rem;
}
.menu__notification-popin .notifications-list .item .item-content a, .menu__notification-popin .notifications-list .item .item-content strong {
  color: #273d52;
  text-decoration: none;
}

.menu__profile-popin {
  padding: 30px 0;
  top: 105px;
  right: 20px;
  width: 346px;
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.menu__profile-popin.open {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}
.menu__profile-popin .menu__profile-popin-container .name {
  display: block;
  font-size: 1.6rem;
  color: #273d52;
}
.menu__profile-popin .menu__profile-popin-container a {
  text-decoration: none;
}
.menu__profile-popin .menu__profile-popin-container .level {
  display: block;
  font-size: 1.5rem;
  line-height: 2.3rem;
  color: #8996a2;
}
.menu__profile-popin .menu__profile-popin-container .thumb-wrapper {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 0 auto 17px auto;
  overflow: visible;
}
.menu__profile-popin .menu__profile-popin-container .thumb-wrapper .thumb {
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}
.menu__profile-popin .menu__profile-popin-container .btn-modify-profile {
  padding: 11px 22px;
  display: inline-block;
  font-size: 1.5rem;
  margin: 22px auto 0px auto;
  position: relative;
  z-index: 5;
}
.menu__profile-popin .menu__profile-popin-container .btn-modify-profile:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: block;
  width: 250%;
  height: 1px;
  background-color: #eef0f3;
  pointer-events: none;
  z-index: 1;
}
.menu__profile-popin .menu__profile-popin-container .accounts-others {
  padding: 32px 35px;
  text-align: left;
}
.menu__profile-popin .menu__profile-popin-container .accounts-others .others__label {
  padding-bottom: 15px;
  display: block;
  color: #8996a2;
  font-size: 1.4rem;
}
.menu__profile-popin .menu__profile-popin-container .accounts-others .others-items .thumb-wrapper {
  display: inline-block;
  width: 65px;
  height: 65px;
  overflow: visible;
}
.menu__profile-popin .menu__profile-popin-container .accounts-others .others-items .thumb-wrapper .u-admin-clock {
  height: 19px !important;
  width: 19px !important;
  top: 0px !important;
  font-size: 10px;
}
.menu__profile-popin .menu__profile-popin-container .accounts-others .others-items .thumb-wrapper.no-image {
  align-content: center;
  border-radius: 50%;
  color: #ffffff;
  background-color: #e83279;
}
.menu__profile-popin .menu__profile-popin-container .accounts-others .others-items .thumb-wrapper.no-image span:first-child {
  font-size: 3rem;
}
.menu__profile-popin .menu__profile-popin-container .accounts-others .others-items .name {
  display: block;
  font-size: 1.5rem;
  color: #273d52;
}
.menu__profile-popin .menu__profile-popin-container .accounts-others .others-items .level {
  display: block;
  font-size: 1.5rem;
  line-height: 2.3rem;
  color: #8996a2;
}
.menu__profile-popin .popin-bottom {
  border-top: 1px solid #eef0f3;
  padding: 24px 0 0 0;
}
.menu__profile-popin .popin-bottom a {
  display: inline-block;
  color: #273d52;
  margin-left: 35px;
  font-size: 1.6rem;
}

.search-popin-css {
  position: absolute;
  top: 0;
  left: 85px;
  bottom: 0;
  background-color: #2f2a7c;
  width: 0;
  overflow: hidden;
  pointer-events: none;
  max-width: 1920px;
  margin: 0 auto;
  -webkit-transition: all 0.45s ease 0.45s;
  -o-transition: all 0.45s ease 0.45s;
  transition: all 0.45s ease 0.45s;
  z-index: 45;
}
.search-popin-css.open {
  width: calc( 100% - 85px);
  -webkit-transition: all 0.35s ease 0s;
  -o-transition: all 0.35s ease 0s;
  transition: all 0.35s ease 0s;
  pointer-events: all;
}
.search-popin-css.open .search-popin__container form {
  opacity: 1;
  -webkit-transition: opacity 0.35s ease 0.3s;
  -o-transition: opacity 0.35s ease 0.3s;
  transition: opacity 0.35s ease 0.3s;
}
.search-popin-css.open .popin__close {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: background-color 0.35s ease 0s, -webkit-transform 0.45s ease 0.45s;
  -o-transition: transform 0.45s ease 0.45s, background-color 0.35s ease 0s;
}
.search-popin-css .popin__close {
  position: absolute;
  top: 45px;
  right: 35px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #8B77FB;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: background-color 0.35s ease 0s, -webkit-transform 0.45s ease 0s;
  transition: background-color 0.35s ease 0s, -webkit-transform 0.45s ease 0s;
  -o-transition: transform 0.45s ease 0s, background-color 0.35s ease 0s;
  transition: transform 0.45s ease 0s, background-color 0.35s ease 0s;
  transition: transform 0.45s ease 0s, background-color 0.35s ease 0s, -webkit-transform 0.45s ease 0s;
  cursor: pointer;
}
.search-popin-css .popin__close:hover {
  background-color: #765efa;
}
.search-popin-css .popin__close i {
  color: #ffffff;
  font-size: 2.8rem;
}
.search-popin-css .search-popin__container {
  padding: 100px 9% 0 9%;
}
.search-popin-css .search-popin__container form {
  position: relative;
  opacity: 0;
}
.search-popin-css .search-popin__container form .c-site-menu__search-popin__form__input {
  padding-left: 60px;
  display: block;
  height: 110px;
  width: 100%;
  font-size: 1.7rem;
  color: #ffffff;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  -webkit-transition: all 0.35s ease;
  -o-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.search-popin-css .search-popin__container form .c-site-menu__search-popin__form__input:active {
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.search-popin-css .search-popin__container form .c-site-menu__search-popin__form__input:focus-visible {
  outline: none;
}
.search-popin-css .search-popin__container form .c-site-menu__search-popin__form__button {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
}
.search-popin-css .search-popin__container form .c-site-menu__search-popin__form__button i {
  color: white;
  font-size: 3rem;
}

.contact-popin {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  -webkit-transition: opacity 0.45s ease 0.45s;
  -o-transition: opacity 0.45s ease 0.45s;
  transition: opacity 0.45s ease 0.45s;
  z-index: 9999;
}
.contact-popin .popin__close {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #8B77FB;
  cursor: pointer;
  z-index: 10;
  position: absolute;
  top: 30px;
  right: 35px;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: background-color 0.35s ease 0s, -webkit-transform 0.45s ease 0s;
  transition: background-color 0.35s ease 0s, -webkit-transform 0.45s ease 0s;
  -o-transition: transform 0.45s ease 0s, background-color 0.35s ease 0s;
  transition: transform 0.45s ease 0s, background-color 0.35s ease 0s;
  transition: transform 0.45s ease 0s, background-color 0.35s ease 0s, -webkit-transform 0.45s ease 0s;
}
.contact-popin .popin__close i {
  color: white;
  font-size: 20px;
}
.contact-popin.open {
  opacity: 1;
  -webkit-transition: opacity 0.35s ease 0s;
  -o-transition: opacity 0.35s ease 0s;
  transition: opacity 0.35s ease 0s;
}
.contact-popin.open .popin__close {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: background-color 0.35s ease 0s, -webkit-transform 0.45s ease 0.4s;
  transition: background-color 0.35s ease 0s, -webkit-transform 0.45s ease 0.4s;
  -o-transition: transform 0.45s ease 0.4s, background-color 0.35s ease 0s;
  transition: transform 0.45s ease 0.4s, background-color 0.35s ease 0s;
  transition: transform 0.45s ease 0.4s, background-color 0.35s ease 0s, -webkit-transform 0.45s ease 0.4s;
  pointer-events: all;
}
.contact-popin.open .contact-popin-container {
  width: 875px;
  -webkit-transition: width 0.35s ease 0.15s;
  -o-transition: width 0.35s ease 0.15s;
  transition: width 0.35s ease 0.15s;
  pointer-events: all;
}
.contact-popin.open .contact-popin-container .contact-popin-form {
  opacity: 1;
  -webkit-transition: opacity 0.4s ease 0.65s;
  -o-transition: opacity 0.4s ease 0.65s;
  transition: opacity 0.4s ease 0.65s;
}
.contact-popin .contact-popin-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 0px;
  overflow-y: auto;
  background-color: #ffffff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: width 0.55s ease 0.15s;
  -o-transition: width 0.55s ease 0.15s;
  transition: width 0.55s ease 0.15s;
}
.contact-popin .contact-popin-container .contact-popin-form {
  padding: 64px 100px;
  position: relative;
  opacity: 0;
  -webkit-transition: opacity 0.35s ease 0s;
  -o-transition: opacity 0.35s ease 0s;
  transition: opacity 0.35s ease 0s;
}
.contact-popin .contact-popin-container .contact-popin-form .form-title {
  padding-bottom: 30px;
  color: #273d52;
  font-size: 2.8rem;
  margin: 0;
}
.contact-popin .contact-popin-container .contact-popin-form .form-description {
  padding-bottom: 44px;
  color: #8996a2;
  font-size: 1.6rem;
  line-height: 2.7rem;
}
.contact-popin .contact-popin-container .contact-popin-form .form-row {
  width: 100%;
  margin-bottom: 39px;
}
.contact-popin .contact-popin-container .contact-popin-form .form-row .form-input {
  width: 45%;
}
.contact-popin .contact-popin-container .contact-popin-form .form-row .form-input .form-input-text {
  padding: 10px 13px;
  font-size: 1.5rem;
  border-radius: 6px;
  border: 1px solid rgba(137, 150, 162, 0.3);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  color: #273d52;
  -webkit-transition: border-color 0.35s ease;
  -o-transition: border-color 0.35s ease;
  transition: border-color 0.35s ease;
  resize: none;
}
.contact-popin .contact-popin-container .contact-popin-form .form-row .form-input .form-input-label {
  display: block;
  margin-bottom: 14px;
  font-size: 1.4rem;
  color: #939ea8;
}
.contact-popin .contact-popin-container .contact-popin-form .form-row .form-input .form-input-select .fa-chevron-down {
  position: absolute;
  top: 50%;
  right: 15px;
  font-size: 1rem;
  height: 1em;
}
.contact-popin .contact-popin-container .contact-popin-form .form-but-validate button {
  font-size: 1.5rem;
  line-height: 1;
  text-align: center;
  border-radius: 30px;
  -webkit-box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.11), 0 7px 10px 0 rgba(39, 61, 82, 0.13);
  box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.11), 0 7px 10px 0 rgba(39, 61, 82, 0.13);
  padding: 2px 60px 1px 60px;
  height: 60px;
  z-index: 2;
}

/**************** FIN ***********/
/************* TEmplate children *************/
.change-account-children {
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.17);
  box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.17);
  z-index: 2;
  position: relative;
}
.change-account-children .u-children-toggle__label {
  font-size: 1.5rem !important;
  line-height: 2.5rem !important;
}

.mobile-profile-thumb-wrapper {
  height: 45px;
  width: 45px;
}

.block-main {
  height: 100%;
}
.block-main.template-dashboard-parents {
  height: calc( 100% - 74px);
}

#block-right {
  height: 100%;
}
#block-right header {
  border-bottom: 1px solid #eef0f3;
  padding: 35px 26px 26px 25px;
}

#block-left {
  height: 100%;
  overflow-y: auto;
}
#block-left .dashboard-news {
  position: relative;
}
#block-left .dashboard-news a {
  position: absolute;
  right: 30px;
  bottom: 30px;
  height: 46px;
  padding: 0px 20px 1px 20px;
  font-size: 1.5rem;
  /* line-height: 1; */
  text-align: center;
  border-radius: 30px;
  -webkit-box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.11), 0 7px 10px 0 rgba(39, 61, 82, 0.13);
  box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.11), 0 7px 10px 0 rgba(39, 61, 82, 0.13);
}

.activity-list {
  overflow-y: auto;
  height: calc( 100% - 91px);
}
.activity-list a.list-item {
  padding: 28px 26px;
  text-decoration: none;
}
.activity-list a.list-item + .list-item {
  border-top: 1px solid #eef0f3;
}
.activity-list a.list-item:hover {
  background-color: rgba(243, 244, 246, 0.5);
  text-decoration: none;
}
.activity-list a.list-item:hover .item-thumb-wrapper .u-icon-play {
  display: flex !important;
}
.activity-list a.list-item .item-thumb-wrapper {
  height: 112px;
}
.activity-list a.list-item .item-thumb-wrapper .u-icon-play {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: none;
  width: 41px;
  height: 41px;
  border-radius: 50%;
  background-color: #ffffff;
  z-index: 1;
}
.activity-list a.list-item .item-thumb-wrapper .u-icon-play i {
  color: #8B77FB;
  font-size: 1.4rem;
  margin-left: 4px;
  z-index: 2;
}
.activity-list a.list-item .item-thumb-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}
.activity-list a.list-item .list-item-title {
  color: #273d52;
  font-size: 1.6rem;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.activity-list a.list-item .list-item-description {
  display: block;
  color: #8996a2;
  font-size: 1.5rem;
  white-space: nowrap;
  margin-bottom: 8px;
}
.activity-list a.list-item .list-item-details .details-date {
  color: #8996a2;
  font-size: 1.4rem;
  margin-right: 14px;
}
.activity-list a.list-item .list-item-details .details-progress {
  width: 97px;
  margin-right: 12px;
  margin-top: 10px;
  height: 5px;
}
.activity-list a.list-item .list-item-details .details-progress .progress-bar {
  background-color: #8B77FB;
}
.activity-list a.list-item .list-item-details .details-percent {
  color: #8B77FB;
  margin-top: 6px;
}
.activity-list a.list-item .list-item-details .details-completed {
  color: #27c281;
  font-size: 1.4rem;
}

.section-home {
  border-radius: 6px;
  padding: 35px 21px 35px 41px;
}

.news__description {
  color: #8996a2;
  font-size: 1.5rem;
  line-height: 2.3rem;
}

.u-dashboard-card {
  position: relative;
  width: calc( 50% - 10px);
  margin-bottom: 21px;
  background-color: #8B77FB;
  border-radius: 6px;
  -webkit-box-shadow: 0 0 35px 1px rgba(5, 16, 43, 0.06);
  box-shadow: 0 0 35px 1px rgba(5, 16, 43, 0.06);
  padding: 35px 21px 35px 41px;
}
.u-dashboard-card .card-title {
  text-transform: uppercase;
  font-size: 1.5rem;
  color: #ffffff;
  margin-bottom: 20px;
}
.u-dashboard-card .card-content {
  font-size: 1.5rem;
  line-height: 2.3rem;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 28px;
}
.u-dashboard-card img {
  position: absolute;
  right: 25px;
  bottom: 35px;
  z-index: 1;
  width: 147px;
  height: 123px;
}
.u-dashboard-card .btn {
  height: 46px;
  font-size: 1.5rem;
  line-height: 1;
  text-align: center;
  border-radius: 30px;
  -webkit-box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.11), 0 7px 10px 0 rgba(39, 61, 82, 0.13);
  box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.11), 0 7px 10px 0 rgba(39, 61, 82, 0.13);
  padding: 0px 20px 1px 20px;
}

.dashboard-statistics {
  margin-bottom: 21px;
  border-radius: 6px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 35px 1px rgba(5, 16, 43, 0.06);
  box-shadow: 0 0 35px 1px rgba(5, 16, 43, 0.06);
}
.dashboard-statistics h2 {
  color: #273d52;
  font-size: 1.5rem;
  text-transform: uppercase;
  margin: 0;
}
.dashboard-statistics h2::after {
  content: "";
  display: inline-block;
  width: 1px;
  height: 23px;
  margin: 0 20px 0 19px;
  background-color: #eef0f3;
}
.dashboard-statistics .statistics-head {
  padding: 31px 26px 26px 26px;
  border-bottom: 1px solid #eef0f3;
}
.dashboard-statistics .statistics-head .head-navigation .navigation-list {
  min-width: 115px;
  text-align: center;
}
.dashboard-statistics .statistics-head .head-navigation .navigation-list .template-dashboard__statistics__head__navigation__list__item {
  display: none;
  color: #273d52;
  font-size: 1.5rem;
  text-transform: uppercase;
}
.dashboard-statistics .statistics-head .head-navigation .navigation-list .template-dashboard__statistics__head__navigation__list__item.active {
  display: block;
}
.dashboard-statistics .statistics-head .head-navigation .navigation-arrow {
  padding: 0 7px;
  margin-top: 1px;
  font-size: 1.4rem;
  opacity: 1;
  pointer-events: all;
  -webkit-transition: opacity 0.35s ease;
  -o-transition: opacity 0.35s ease;
  transition: opacity 0.35s ease;
  cursor: pointer;
}
.dashboard-statistics .statistics-head .head-navigation .navigation-arrow.disabled {
  opacity: 0.3;
  pointer-events: none;
}
.dashboard-statistics .statistics-items {
  display: block;
}
.dashboard-statistics .statistics-items .statistics-item {
  display: none;
}
.dashboard-statistics .statistics-items .statistics-item.active {
  display: flex;
}
.dashboard-statistics .statistics-items .statistics-item .item-chart {
  padding: 37px 35px 37px 44px;
  width: calc( 100% - 289px);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-right: 1px solid #eef0f3;
}
.dashboard-statistics .statistics-items .statistics-item .item-chart canvas {
  width: 35% !important;
  height: auto !important;
}
.dashboard-statistics .statistics-items .statistics-item .item-chart .item-chart__title {
  padding-bottom: 27px;
  display: block;
  width: 100%;
  color: #273d52;
  text-align: center;
  font-size: 1.5rem;
  line-height: 2.3rem;
}
.dashboard-statistics .statistics-items .statistics-item .item-chart .ul-statistics-item-labels {
  width: 65%;
}
.dashboard-statistics .statistics-items .statistics-item .item-chart .ul-statistics-item-labels li {
  position: relative;
  display: block;
  width: 100%;
  vertical-align: top;
  font-size: 1.5rem;
  line-height: 1.3;
  margin-bottom: 20px;
  margin-right: 40px;
}
.dashboard-statistics .statistics-items .statistics-item .item-chart .ul-statistics-item-labels li .template-dashboard__statistics__item__label__color {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 13px;
  height: 13px;
  border-radius: 13px;
  margin-top: 2px;
  margin-right: 8px;
}
.dashboard-statistics .statistics-items .statistics-item .item-chart .ul-statistics-item-labels li .template-dashboard__statistics__item__label__color.exo {
  background-color: #ff904d;
}
.dashboard-statistics .statistics-items .statistics-item .item-chart .ul-statistics-item-labels li .template-dashboard__statistics__item__label__color.video {
  background-color: #02bf62;
}
.dashboard-statistics .statistics-items .statistics-item .item-chart .ul-statistics-item-labels li .template-dashboard__statistics__item__label__color.sj {
  background-color: #d53269;
}
.dashboard-statistics .statistics-items .statistics-item .item-chart .ul-statistics-item-labels li .template-dashboard__statistics__item__label__percentage {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}
.dashboard-statistics .statistics-items .statistics-item .item-chart .ul-statistics-item-labels li .template-dashboard__statistics__item__label__content {
  display: inline;
  vertical-align: top;
  color: #8996a2;
}
.dashboard-statistics .statistics-items .statistics-item .statistics-item-content {
  width: 289px;
}
.dashboard-statistics .statistics-items .statistics-item .statistics-item-content .statistics-item-content__row {
  padding: 28px 39px 24px 39px;
  border-bottom: 1px solid #eef0f3;
}
.dashboard-statistics .statistics-items .statistics-item .statistics-item-content .statistics-item-content__row .statistics-item-content__label {
  color: #8996a2;
  font-size: 1.5rem;
  line-height: 2.5rem;
}
.dashboard-statistics .statistics-items .statistics-item .statistics-item-content .statistics-item-content__row .statistics-item-content__label strong {
  padding-bottom: 5px;
  display: block;
  color: #273d52;
  font-weight: normal;
  font-size: 3rem;
}
.dashboard-statistics .statistics-items .statistics-item .template-dashboard__statistics__item__no-stats {
  padding: 47px 0px 88px 0px;
  text-align: center;
  width: 100%;
}
.dashboard-statistics .statistics-items .statistics-item .template-dashboard__statistics__item__no-stats__text {
  display: block;
  width: 55%;
  margin: 23px auto 0 auto;
  color: #273d52;
  font-size: 1.6rem;
  text-align: center;
  line-height: 2.9rem;
}

.menu-mobile {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  height: 100%;
  right: 0;
  bottom: 0;
}

.missionexam h4 {
  color: #273d52;
  font-size: 1.5rem;
  text-transform: uppercase;
  padding: 31px 26px 26px 26px;
}
.missionexam .block-table {
  padding: 0 26px 26px 26px;
}
.missionexam .block-table th {
  background-color: #8B77FB;
  color: white;
  font-weight: bold;
  vertical-align: middle;
}
.missionexam .block-table tr td:nth-child(1) {
  font-weight: bold;
}
.missionexam .block-table tr td:nth-child(3), .missionexam .block-table tr td:nth-child(4) {
  vertical-align: middle;
  height: 100%;
  margin: auto;
  text-align: center;
  color: #273d52;
  font-weight: normal;
  font-size: 20px;
}
.missionexam .block-table .line-program {
  padding: 3px;
}
.missionexam .block-table .line-program:nth-child(even) {
  background: #F1F2F4;
}
.missionexam .block-table .line-program div:nth-child(2) {
  font-weight: bold;
}

.c-activities-list {
  border-radius: 6px;
  height: 100%;
  overflow-y: auto;
}
.c-activities-list li + li {
  border-top: 1px solid #eef0f3;
}
.c-activities-list li a {
  text-decoration: none;
  height: auto;
  padding: 28px 0;
}
.c-activities-list li a:hover {
  background-color: rgba(243, 244, 246, 0.5);
  text-decoration: none;
}
.c-activities-list li a:hover .item-thumb-wrapper-play .u-icon-play {
  display: flex !important;
}
.c-activities-list li a .item-thumb-wrapper-play {
  margin-left: 21px;
  width: 119px;
  height: 88px;
}
.c-activities-list li a .item-thumb-wrapper-play .poster {
  object-fit: cover;
  border-radius: 15px;
}
.c-activities-list li a .list-item-title {
  color: #273d52;
  font-size: 1.6rem;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.c-activities-list li a .list-item-description {
  display: block;
  color: #8996a2;
  font-size: 1.5rem;
  white-space: nowrap;
  margin-bottom: 8px;
}
.c-activities-list li a .details-date {
  color: #8996a2;
  font-size: 1.4rem;
  margin-right: 14px;
}
.c-activities-list li a .details-progress {
  width: 97px;
  margin-right: 12px;
  margin-top: 10px;
  height: 5px;
}
.c-activities-list li a .details-progress .progress-bar {
  background-color: #8b77fb;
}
.c-activities-list li a .details-percent {
  color: #8b77fb;
  margin-top: 6px;
}
.c-activities-list li a .details-completed {
  color: #27c281;
  font-size: 1.4rem;
}

.latest-activities {
  height: 100%;
  padding: 14px;
  overflow-y: auto;
}

.template-dashboard-parents.latest-activities {
  height: calc(100% - 74px);
}

.last-activities-scores {
  color: #273d52;
}

.template-stages__card {
  width: calc( 100% / 3 - 21px);
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  margin-right: 21px;
  margin-bottom: 21px;
}
.template-stages__card:nth-child(3n-2) {
  background: #8B77FB;
}
.template-stages__card:nth-child(2n) {
  background: #423cb0;
}
.template-stages__card:nth-child(3n) {
  background: #273d52;
}

.template-stages__container {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc( 100vh - 85px);
}
.template-stages__container .row {
  padding-top: 21px;
  padding-left: 21px;
}
.template-stages__container .u-dashboard-card__textual {
  position: relative;
  width: 65%;
  z-index: 2;
}
.template-stages__container .u-dashboard-card__title {
  padding-bottom: 20px;
  display: block;
  text-transform: uppercase;
  font-size: 1.5rem;
  color: #ffffff;
  margin-bottom: 0;
}
.template-stages__container .u-dashboard-card__content {
  padding-bottom: 28px;
  display: block;
  font-size: 1.5rem;
  line-height: 2.3rem;
  color: rgba(255, 255, 255, 0.7);
}
.template-stages__container .u-dashboard-card img {
  position: absolute;
  right: 25px;
  bottom: 35px;
  z-index: 1;
}

.template-stage-single__container {
  padding: 25px 27px;
  overflow-y: auto;
  height: calc( 100vh - 85px);
}

.template-stage-single__resume {
  background-color: #ffffff;
}

.template-stage-single__col--left {
  margin-right: 18px;
}

.template-stage-single__col--right {
  width: 60%;
}

.template-stage-single__col {
  border-radius: 6px;
  overflow: hidden;
}

.template-stage-single__resume__head {
  padding: 42px;
  position: relative;
  color: #ffffff;
  background-color: #423cb0;
}

.template-stage-single__resume__head__title {
  padding-bottom: 15px;
  display: block;
  font-size: 1.5rem;
  text-transform: uppercase;
  width: 70%;
}

.template-stage-single__resume__head__description {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.5rem;
  line-height: 2.3rem;
}

.template-stage-single__resume__head__image {
  position: absolute;
  right: 29px;
  bottom: 20px;
  cursor: pointer;
  width: 116px;
}

.block-picture {
  position: absolute;
  right: 29px;
  bottom: 20px;
  width: 134px;
  height: 100px;
  cursor: pointer;
}
.block-picture:hover i {
  font-size: 16px;
}
.block-picture i {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 10px;
  height: 20px;
  font-size: 14px;
}

.template-stage-single__resume__content {
  padding: 36px 45px;
  color: #617180;
  font-size: 1.6rem;
  line-height: 2.4rem;
}
.template-stage-single__resume__content ol > li {
  counter-increment: item;
  margin-bottom: 15px;
  list-style: none;
  position: relative;
}
.template-stage-single__resume__content ol > li ul {
  padding-left: 17px;
  list-style: disc;
}
.template-stage-single__resume__content ol > li:before {
  margin-right: 10px;
  content: counter(item);
  background: #E83279;
  border-radius: 50%;
  color: white;
  width: 1.5em;
  text-align: center;
  display: inline-block;
  height: 1.5em;
  position: absolute;
  left: -33px;
}

.template-stage-single__col--right {
  width: 58%;
}

.template-stage-single__col {
  border-radius: 6px;
  overflow: hidden;
}

.template-stage-single__form__step {
  background-color: #ffffff;
}

.template-stage-single__form__step__head {
  padding: 23px 31px;
  width: 100%;
  border-bottom: 1px solid #eef0f3;
}

.template-stage-single__form__step__head__label {
  display: inline-block;
  color: #273d52;
  text-transform: uppercase;
  font-size: 1.5rem;
  margin-right: 34px;
}

.template-stage-single__form__step__head__select {
  min-width: 200px;
}
.template-stage-single__form__step__head__select i {
  position: absolute;
  top: 50%;
  right: 15px;
  font-size: 1rem;
}

.u-select {
  position: relative;
}
.u-select .u-select__input {
  padding: 10px 35px 10px 13px;
  font-size: 1.5rem;
  border-radius: 6px;
  border: 1px solid rgba(137, 150, 162, 0.3);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  color: #273d52;
  width: 100%;
  -webkit-transition: border-color 0.35s ease;
  -o-transition: border-color 0.35s ease;
  transition: border-color 0.35s ease;
  cursor: pointer;
}

.template-stage-single__form__step__subjects {
  padding: 23px 31px;
}
.template-stage-single__form__step__subjects .template-stage-single__form__step__subjects__label {
  display: inline-block;
  color: #273d52;
  text-transform: uppercase;
  font-size: 1.5rem;
  margin-bottom: 30px;
}
.template-stage-single__form__step__subjects .template-stage-single__form__step__subjects__list {
  padding: 0;
  margin-bottom: 15px;
}
.template-stage-single__form__step__subjects .template-stage-single__form__step__subjects__list .template-stage-single__form__step__subjects__item {
  padding: 24px 27px;
  height: 74px;
  width: calc( 100% / 3 - 8px);
  font-size: 1.5rem;
  line-height: 2.1rem;
  border-radius: 6px;
  -webkit-box-shadow: 0 10px 20px 1px rgba(5, 16, 43, 0.15);
  box-shadow: 0 10px 20px 1px rgba(5, 16, 43, 0.15);
  margin-right: 8px;
  margin-bottom: 8px;
  -webkit-transition: background-color 0.35s ease, color 0.35s ease;
  -o-transition: background-color 0.35s ease, color 0.35s ease;
  transition: background-color 0.35s ease, color 0.35s ease;
  cursor: pointer;
}
.template-stage-single__form__step__subjects .template-stage-single__form__step__subjects__list .template-stage-single__form__step__subjects__item:hover {
  color: #ffffff;
  background-color: #27c281;
}
.template-stage-single__form__step__subjects .template-stage-single__form__step__subjects__list .template-stage-single__form__step__subjects__item.active {
  color: #ffffff;
  background-color: #27c281;
}
.template-stage-single__form__step__subjects .template-stage-single__form__step__subjects__actions {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.template-stage-single__form__step__subjects .template-stage-single__form__step__subjects__actions button, .template-stage-single__form__step__subjects .template-stage-single__form__step__subjects__actions a {
  padding: 0px 20px 1px 20px;
  height: 46px;
  font-size: 1.5rem;
  line-height: 1;
  text-align: center;
  border-radius: 30px;
  -webkit-box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.11), 0 7px 10px 0 rgba(39, 61, 82, 0.13);
  box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.11), 0 7px 10px 0 rgba(39, 61, 82, 0.13);
}

.template-stage-single__form__step__head__message {
  font-size: 1.5rem;
  line-height: 2.4rem;
  color: #617180;
  max-width: 50%;
}

.template-stage-single__form__step__head__btn {
  height: 46px;
  font-size: 1.5rem;
  line-height: 1;
  text-align: center;
  border-radius: 30px;
  -webkit-box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.11), 0 7px 10px 0 rgba(39, 61, 82, 0.13);
  box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.11), 0 7px 10px 0 rgba(39, 61, 82, 0.13);
  padding: 0px 20px 1px 20px;
}

.template-stage-single__form__step__days {
  padding: 31px 31px 41px 31px;
}
.template-stage-single__form__step__days .template-stage-single__form__step__day {
  background-color: #ffffff;
  border-radius: 6px;
  -webkit-box-shadow: 0 10px 20px 1px rgba(5, 16, 43, 0.15);
  box-shadow: 0 10px 20px 1px rgba(5, 16, 43, 0.15);
  margin-bottom: 12px;
}
.template-stage-single__form__step__days .template-stage-single__form__step__day__head {
  padding: 25px 27px;
  position: relative;
  color: #273d52;
  border-bottom: 1px solid #eef0f3;
  cursor: pointer;
}
.template-stage-single__form__step__days .template-stage-single__form__step__day__head .template-stage-single__form__step__day__head__label {
  text-transform: uppercase;
  font-size: 1.5rem;
}
.template-stage-single__form__step__days .template-stage-single__form__step__day__head i {
  position: absolute;
  top: 50%;
  right: 35px;
  font-size: 1.5rem;
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  -o-transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  pointer-events: none;
  height: 1em;
}

.template-stage-single__form__step__day__content__row {
  padding: 20px 27px;
  border-bottom: 1px solid #eef0f3;
}
.template-stage-single__form__step__day__content__row .template-stage-single__form__step__day__content__left .template-stage-single__form__step__day__content__subject {
  display: block;
  color: #273d52;
  font-size: 1.5rem;
  line-height: 2.3rem;
}
.template-stage-single__form__step__day__content__row .template-stage-single__form__step__day__content__left .template-stage-single__form__step__day__content__details {
  color: #617180;
  font-size: 1.6rem;
  line-height: 2.4rem;
}
.template-stage-single__form__step__day__content__row .template-stage-single__form__step__day__content__btn {
  padding: 0px 20px 1px 20px;
  height: 46px;
  font-size: 1.5rem;
  line-height: 1;
  text-align: center;
  border-radius: 30px;
  -webkit-box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.11), 0 7px 10px 0 rgba(39, 61, 82, 0.13);
  box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.11), 0 7px 10px 0 rgba(39, 61, 82, 0.13);
}
.template-stage-single__form__step__day__content__row .template-stage-single__form__step__day__content__select i {
  position: absolute;
  top: 50%;
  right: 15px;
  font-size: 1rem;
}

.template-stage-single__form__step__day__content {
  display: none;
}

.template-stage-single__next-btn-mobile {
  display: none;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0 !important;
  z-index: 30;
  height: 73px !important;
  font-size: 1.5rem;
  padding: 0px 20px 1px 20px;
}

@media (max-width: 767.98px) {
  .template-stage-single__next-btn-mobile {
    display: -webkit-inline-box !important;
    display: -webkit-inline-flex !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 73px !important;
    text-align: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 0 !important;
    z-index: 30;
    width: 100%;
  }

  .template-stage-single__form__step__head__message {
    max-width: 100%;
  }
}
.menu-list-programs {
  position: absolute;
  border-radius: 0px;
  top: 0;
  left: 85px;
  bottom: 0;
  width: calc( 100% - 85px);
  background-color: rgba(0, 0, 0, 0.78);
  overflow: hidden;
  pointer-events: none;
  max-width: 1920px;
  margin: 0 auto;
  opacity: 0;
  -webkit-transition: opacity 0s ease 0.35s;
  -o-transition: opacity 0s ease 0.35s;
  transition: opacity 0s ease 0.35s;
  z-index: 45;
}
.menu-list-programs.open {
  opacity: 1;
  -webkit-transition: opacity 0s ease 0s;
  -o-transition: opacity 0s ease 0s;
  transition: opacity 0s ease 0s;
  pointer-events: all;
}
.menu-list-programs.open .list-programs-main {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.17);
  box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.17);
  pointer-events: all;
}
.menu-list-programs .list-programs-mobile-bar {
  width: 100%;
  height: 70px;
  color: #ffffff;
  background-color: #302b98;
}

.list-programs-main {
  left: 0;
  background-color: #302b98;
  width: 25%;
  z-index: 30;
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transition: -webkit-transform 0.4s ease, -webkit-box-shadow 0.4s ease;
  transition: -webkit-transform 0.4s ease, -webkit-box-shadow 0.4s ease;
  -o-transition: transform 0.4s ease, box-shadow 0.4s ease;
  transition: transform 0.4s ease, box-shadow 0.4s ease, -webkit-transform 0.4s ease, -webkit-box-shadow 0.4s ease;
}
.list-programs-main .list-programs-items {
  display: block;
  height: calc( 100% - 85px);
}
.list-programs-main .programs-item {
  height: 88px;
}
.list-programs-col {
  position: absolute;
  top: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

.programs-head {
  padding: 19px 31px 18px 31px;
  height: 85px;
  background-color: #3a35a6;
  border-bottom: 1px solid rgba(226, 226, 230, 0.11);
}
.programs-head .head-select .select-label {
  position: absolute;
  top: 0;
  left: 0;
  color: rgba(255, 255, 255, 0.4);
  font-size: 1.4rem;
  pointer-events: none;
}
.programs-head .head-select #level {
  padding-top: 25px;
  padding-bottom: 5px;
  padding-left: 0;
  color: #ffffff;
  border: none !important;
  font-size: 1.5rem;
  cursor: pointer;
  appearance: none;
  background: #3A35A6;
}
.programs-head .head-select .fa-chevron-down {
  position: absolute;
  top: 50%;
  right: 15px;
  height: 1em;
  fill: currentColor;
  vertical-align: middle;
  overflow: hidden;
  color: white;
}

.list-programs-items {
  padding-left: 0;
  display: none;
  margin: 0;
  height: 100%;
  overflow-y: auto;
  list-style: none;
}
.list-programs-items .programs-item {
  padding: 32px 60px 31px 32px;
}
.list-programs-items .programs-item.mission-exam {
  background-color: #e83279;
  font-weight: bold;
  color: white;
}
.list-programs-items .programs-item .item-title {
  display: block;
  width: 100%;
  font-size: 1.6rem;
  line-height: 2.5rem;
}
.list-programs-items .programs-item .item-details {
  margin-top: 9px;
  color: #8996a2;
  font-size: 1.4rem;
}
.list-programs-items .programs-item .item-details .item-details-count {
  margin-right: 14px;
}
.list-programs-items .programs-item .item-details .details-progress {
  width: 97px;
  height: 5px;
  border-radius: 4px;
  margin-right: 12px;
  margin-top: 10px;
  background-color: #56548A;
}
.list-programs-items .programs-item .item-details .details-progress .progress-bar {
  background-color: #8B77FB;
}
.list-programs-items .programs-item .item-details .item-details-percent {
  color: #8B77FB;
}
.list-programs-items.active {
  display: block;
}

.programs-item {
  padding: 32px 60px 32px 45px;
  position: relative;
  height: 132px;
  width: 100%;
  color: rgba(255, 255, 255, 0.4);
  -webkit-transition: all 0.35s ease;
  -o-transition: all 0.35s ease;
  transition: all 0.35s ease;
  cursor: pointer;
  z-index: 5;
}
.programs-item.active, .programs-item:hover {
  color: white;
  background-color: rgba(0, 0, 0, 0.15);
}
.programs-item.active .u-icon-play, .programs-item:hover .u-icon-play {
  display: flex !important;
}
.programs-item i.fa-chevron-right {
  position: absolute;
  top: 50%;
  right: 50px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  font-size: 1.4rem;
  height: 1em;
}

.programs-item-title {
  display: block;
  width: 100%;
  font-size: 1.6rem;
  line-height: 2.5rem;
}

.col-second {
  left: 25%;
  width: 0%;
  background-color: #262278;
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transition: -webkit-transform 0.4s ease, -webkit-box-shadow 0.4s ease;
  transition: -webkit-transform 0.4s ease, -webkit-box-shadow 0.4s ease;
  -o-transition: transform 0.4s ease, box-shadow 0.4s ease;
  transition: transform 0.4s ease, box-shadow 0.4s ease, -webkit-transform 0.4s ease, -webkit-box-shadow 0.4s ease;
  pointer-events: none;
  z-index: 20;
}
.col-second.active, .col-second.open {
  width: 33%;
  -webkit-box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.17);
  box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.17);
  pointer-events: all;
  transform: translate(0px, 0);
}

.col-third {
  left: calc( 33% + 25%);
  width: 0%;
  background-color: #221f6c;
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transition: -webkit-transform 0.4s ease, -webkit-box-shadow 0.4s ease;
  transition: -webkit-transform 0.4s ease, -webkit-box-shadow 0.4s ease;
  -o-transition: transform 0.4s ease, box-shadow 0.4s ease;
  transition: transform 0.4s ease, box-shadow 0.4s ease, -webkit-transform 0.4s ease, -webkit-box-shadow 0.4s ease;
  z-index: 10;
}
.col-third.active, .col-third.open {
  width: calc( 100% - 25% - 30%);
  -webkit-box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.17);
  box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.17);
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  pointer-events: all;
}
.col-third .programs-item {
  text-decoration: none;
  padding: 32px 46px;
}
.col-third .programs-item.selected {
  background-color: rgba(0, 0, 0, 0.15);
}
.col-third .programs-item .item-thumb-wrapper {
  position: relative;
  width: 110px;
  height: 80px;
  border-radius: 6px;
  overflow: hidden;
}
.col-third .programs-item .item-thumb-wrapper .item-thumb {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.col-third .programs-item .item-thumb-wrapper .u-icon-play {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: none;
  width: 41px;
  height: 41px;
  border-radius: 50%;
  background-color: #ffffff;
  z-index: 1;
}
.col-third .programs-item .item-thumb-wrapper .u-icon-play i {
  color: #8B77FB;
  font-size: 1.4rem;
  margin-left: 4px;
  z-index: 2;
}
.col-third .programs-item .item-content {
  padding-left: 40px;
  width: calc(100% - 110px);
}
.col-third .programs-item .item-access-free {
  background-color: darkgreen;
  color: white;
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 3px;
  margin-bottom: 5px;
  font-weight: bold;
}

.template-search-container {
  height: 100%;
  overflow-y: auto;
}
.template-search-container .search-filters {
  padding-left: 21px;
  padding-right: 21px;
  height: 90px;
  background-color: #ffffff;
  margin-bottom: 25px;
}
.template-search-container .search-filters .filters__mobile-toggle {
  width: 53px;
  background-color: #8273f6;
  color: white;
  height: 50px;
  border-radius: 6px;
}
.template-search-container .search-filters .filters__mobile-toggle i {
  font-size: 26px;
}
.template-search-container .search-filters .query-search {
  padding: 23px 29px 23px 0;
  width: 35%;
  height: 100%;
  color: #273d52;
  font-size: 1.7rem;
  cursor: pointer;
  border-right: 1px solid #eef0f3;
  margin-right: 28px;
}
.template-search-container .search-filters .query-search i {
  width: 1.04em;
  margin-right: 17px;
  font-size: 2.4rem;
  color: #273d52;
}
.template-search-container .search-filters .template-search__filters__label {
  display: inline-block;
  color: #8996a2;
  font-size: 1.5rem;
  margin-right: 20px;
}
.template-search-container .search-filters .template-search__filters__select {
  margin-right: 12px;
}
.template-search-container .search-filters .u-select {
  position: relative;
}
.template-search-container .search-filters .u-select .template-search__filters__select__input {
  padding: 12px 45px 12px 15px;
  height: 52px;
  font-size: 1.7rem;
}
.template-search-container .search-filters .u-select .fa-chevron-down {
  width: 0.58333em;
  right: 28px;
  position: absolute;
  top: 38%;
  font-size: 1.5rem;
}
.template-search-container .search-results {
  padding-bottom: 25px;
}
.template-search-container .search-results .line-result {
  background-color: #ffffff;
  border-radius: 6px;
  margin-bottom: 15px;
  -webkit-transition: all 0.35s ease;
  -o-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.template-search-container .search-results .line-result.open .result-content-right .fa-chevron-up {
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.template-search-container .search-results .line-result .result-header {
  padding: 28px 0;
}
.template-search-container .search-results .line-result .result-header .thumb-wrapper {
  position: relative;
  margin-left: 21px;
  width: 115px;
  border-radius: 6px;
  overflow: hidden;
  display: block;
  height: 81px;
}
.template-search-container .search-results .line-result .result-header .thumb-wrapper img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.template-search-container .search-results .line-result .result-header .result-content {
  padding-left: 24px;
  padding-right: 10px;
  position: relative;
}
.template-search-container .search-results .line-result .result-header .result-content .template-search__result__title {
  display: block;
  color: #273d52;
  font-size: 1.6rem;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.template-search-container .search-results .line-result .result-header .result-content .template-search__result__description {
  padding-bottom: 8px;
  display: block;
  color: #8996a2;
  font-size: 1.5rem;
  #white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.template-search-container .search-results .line-result .result-header .result-content-right .template-search__result__details {
  color: #8996a2;
  font-size: 1.4rem;
}
.template-search-container .search-results .line-result .result-header .result-content-right .template-search__result__details .template-search__result__details__date {
  margin-right: 14px;
}
.template-search-container .search-results .line-result .result-header .result-content-right .template-search__result__details .progress {
  position: relative;
  display: inline-block;
  width: 97px;
  height: 5px;
  border-radius: 4px;
  background-color: #dedfe5;
  margin-right: 12px;
}
.template-search-container .search-results .line-result .result-header .result-content-right .template-search__result__details .progress .progress-bar {
  background-color: #8B77FB;
  height: 20px;
}
.template-search-container .search-results .line-result .result-header .result-content-right .template-search__result__details .template-search__result__details__timer__percent {
  color: #8B77FB;
}
.template-search-container .search-results .line-result .result-header .result-content-right .template-search__result__class {
  padding: 0 25px;
  border-left: 1px solid #eef0f3;
  border-right: 1px solid #eef0f3;
}
.template-search-container .search-results .line-result .result-header .result-content-right .template-search__result__class a {
  background-color: #f25a45;
  color: white;
  padding: 5px 10px;
  font-size: 14px;
}
.template-search-container .search-results .line-result .result-header .result-content-right .template-search__result__class a:hover {
  background-color: #db280f;
}
.template-search-container .search-results .line-result .result-header .result-content-right .template-search__result__class a.reload {
  background-color: #8B77FB !important;
  border: 1px solid #8B77FB;
  font-size: 15px;
}
.template-search-container .search-results .line-result .result-header .result-content-right .template-search__result__class a.reload:hover {
  background-color: #6145fa !important;
}
.template-search-container .search-results .line-result .result-header .result-content-right .template-search__result__class .template-search__result__class__content {
  padding: 14px 22px;
  display: block;
  border-radius: 25px;
  color: #ffffff;
  font-family: "RubikMd";
  font-size: 16px;
}
.template-search-container .search-results .line-result .result-header .result-content-right .template-search__result__icon {
  width: 116px;
  cursor: pointer;
}
.template-search-container .search-results .line-result .result-header .result-content-right .template-search__result__icon i {
  height: 1em;
  width: 0.58333em;
  font-size: 21px;
  -webkit-transition: -webkit-transform 0.35s ease;
  transition: -webkit-transform 0.35s ease;
  -o-transition: transform 0.35s ease;
  transition: transform 0.35s ease;
  transition: transform 0.35s ease, -webkit-transform 0.35s ease;
}
.template-search-container .search-results .line-result .result-dropdown {
  display: none;
}
.template-search-container .search-results .line-result .result-dropdown.open {
  display: block;
}
.template-search-container .search-results .line-result .result-dropdown .result-dropdown-container {
  border-top: 1px solid #eef0f3;
}
.template-search-container .search-results .line-result .result-dropdown .result-dropdown-container .row-result-dropdown {
  padding: 30px 0;
  width: 75%;
  border-bottom: 1px solid #eef0f3;
  margin: 0 auto;
}
.template-search-container .search-results .line-result .result-dropdown .result-dropdown-container .row-result-dropdown .result-dropdown-number {
  width: 32px;
  height: 32px;
  color: #ffffff;
  font-size: 1.4rem;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  margin-right: 24px;
}
.template-search-container .search-results .line-result .result-dropdown .result-dropdown-container .row-result-dropdown .result-dropdown-number.completed {
  border: none;
  background-color: #27c281;
}
.template-search-container .search-results .line-result .result-dropdown .result-dropdown-container .row-result-dropdown .result-dropdown-number.number {
  font-size: 1.4rem;
  text-align: center;
  background-color: #273d52;
}
.template-search-container .search-results .line-result .result-dropdown .result-dropdown-container .row-result-dropdown .template-search__result__dropdown__title {
  width: 175px;
}
.template-search-container .search-results .line-result .result-dropdown .result-dropdown-container .row-result-dropdown .template-search__result__dropdown__title .template-search__result__dropdown__title__type {
  display: block;
  color: #273d52;
  font-size: 1.5rem;
}
.template-search-container .search-results .line-result .result-dropdown .result-dropdown-container .row-result-dropdown .template-search__result__dropdown__title .template-search__result__dropdown__title__name {
  display: block;
  color: #8996a2;
  font-size: 1.4rem;
}
.template-search-container .search-results .line-result .result-dropdown .result-dropdown-container .row-result-dropdown .template-search__result__dropdown__title .template-search__result__dropdown__resume {
  color: #273d52;
  font-size: 1.4rem;
}
.template-search-container .search-results .line-result .template-program-launch a {
  background-color: #f25a45;
  color: white;
  padding: 5px 10px;
  font-size: 14px;
}
.template-search-container .search-results .line-result .template-program-launch a:hover {
  background-color: #db280f;
}
.template-search-container .search-results .line-result .template-program-launch a.reload {
  background-color: #8B77FB !important;
  border: 1px solid #8B77FB;
  font-size: 15px;
}
.template-search-container .search-results .line-result .template-program-launch a.reload:hover {
  background-color: #6145fa !important;
}
@media (min-width: 767.98px) {
  .template-search-container .search-results .line-result .line-search-left-block {
    width: 81%;
  }
  .template-search-container .search-results .line-result .line-search-left-block .thumb-wrapper {
    width: 12%;
  }
  .template-search-container .search-results .line-result .line-search-left-block .result-content {
    width: 88%;
  }
}

#js-search-popin-filer-mobile {
  height: 100vh;
}
#js-search-popin-filer-mobile .search-popin__container {
  padding: 150px 9% 0 9%;
}
#js-search-popin-filer-mobile .search-popin__container .u-select .fa-chevron-down {
  width: 0.58333em;
  right: 28px;
  position: absolute;
  top: 38%;
  font-size: 1.5rem;
}

.program-summary-header-mobile {
  display: none;
  width: 100%;
  color: #ffffff;
  background-color: #302b98;
}
.program-summary-header-mobile .top-bar-resume-mobile {
  padding: 15px 25px 15px 25px;
}
.program-summary-header-mobile .top-bar-resume-mobile .c-program-summary__top-bar__resume__content__hat {
  color: white;
  font-size: 1.8rem;
  font-weight: bold;
}
.program-summary-header-mobile .top-bar-resume-mobile .c-program-summary__top-bar__resume__content__title {
  color: #ffffff;
  font-size: 1.6rem;
}
.program-summary-header-mobile .top-bar-resume-mobile i {
  font-size: 1.5rem;
  display: inline;
  height: 1em;
  width: 0.58333em;
  -webkit-transition: -webkit-transform 0.35s ease 0s;
  transition: -webkit-transform 0.35s ease 0s;
  -o-transition: transform 0.35s ease 0s;
  transition: transform 0.35s ease 0s, -webkit-transform 0.35s ease 0s;
}
.program-summary-header-mobile .top-bar-resume-mobile i.active {
  webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.program-summary-header-mobile .exit-btn {
  width: 70px;
  height: 100%;
  border-left: 1px solid rgba(226, 226, 230, 0.11);
  color: white;
  text-decoration: none;
}
.program-summary-header-mobile .exit-btn i {
  font-size: 25px;
}

.program-summary-top-bar {
  height: 85px;
  width: calc( 100% - 85px);
  max-width: calc( 1920px - 85px);
  margin: 0 auto;
}
.program-summary-top-bar .top-bar-resume {
  height: 100%;
  width: 339px;
  background-color: #2f2a7c;
  border-bottom: 1px solid rgba(226, 226, 230, 0.11);
  position: relative;
  cursor: pointer;
}
.program-summary-top-bar .top-bar-resume i {
  color: white;
  margin-left: 15px;
  font-size: 26px;
}
.program-summary-top-bar .top-bar-resume .thumb-wrapper {
  width: 69px;
  height: 49px;
  border-radius: 6px;
  margin-left: 21px;
  margin-right: 15px;
  overflow: hidden;
}
.program-summary-top-bar .top-bar-resume .thumb-wrapper img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.program-summary-top-bar .top-bar-resume .resume-content {
  width: 50%;
}
.program-summary-top-bar .top-bar-resume .resume-content .c-program-summary__top-bar__resume__content__hat {
  display: block;
  color: rgba(255, 255, 255, 0.4);
  font-size: 1.4rem;
}
.program-summary-top-bar .top-bar-resume .resume-content .c-program-summary__top-bar__resume__content__title {
  display: block;
  color: #ffffff;
  font-size: 1.6rem;
}
.program-summary-top-bar .top-bar-resume .exit-btn {
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.program-summary-top-bar .top-bar-resume .exit-btn i {
  font-size: 2.7rem;
  color: white;
}
.program-summary-top-bar .top-bar-content {
  padding-left: 36px;
  padding-right: 20px;
  width: calc( 100% - 339px);
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.17);
  box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.17);
}
.program-summary-top-bar .top-bar-content .content-title {
  color: #273d52;
  font-family: "RubikMd", sans-serif;
  font-size: 1.7rem;
}
.program-summary-top-bar .top-bar-content .content-title .c-program-summary__top-bar__content__title__headline {
  margin-left: 17px;
  font-size: 1.6rem;
  color: #617180;
  font-family: "Rubik";
  font-weight: normal;
  display: none;
}
.program-summary-top-bar .top-bar-content .content-link {
  color: #273d52;
  font-size: 1.5rem;
  text-decoration: underline;
  margin-right: 31px;
}
.program-summary-top-bar .top-bar-content .button {
  padding: 13px 20px;
  color: #ffffff;
  font-size: 1.5rem;
  background-color: #e83279;
  border-radius: 27px;
  -webkit-box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.11), 0 7px 10px 0 rgba(39, 61, 82, 0.13);
  box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.11), 0 7px 10px 0 rgba(39, 61, 82, 0.13);
  -webkit-transition: all 0.35s ease;
  -o-transition: all 0.35s ease;
  transition: all 0.35s ease;
  text-decoration: none;
}
.program-summary-top-bar .top-bar-content .top-bar-content-right .qcm-navigation li {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  font-size: 1.3rem;
  color: #8996a2;
  border: 1px solid #8996a2;
  margin-right: 8px;
}
.program-summary-top-bar .top-bar-content .top-bar-content-right .qcm-navigation li.active {
  color: #ffffff;
  background-color: #e83279;
  border-color: #e83279;
  cursor: pointer;
}

.main-program {
  height: calc((var(--vh, 1vh) * 100) - 85px);
}
.main-program .list-programs-container-items .program-summary-container-items {
  width: 339px;
  height: calc(100% - 60px);
}
.main-program #template-media-container {
  width: calc( 100% - 339px);
  height: calc(100vh - 85px);
  overflow-y: auto;
}
.main-program #template-media-container .exo-content {
  padding: 57px 9%;
  height: 100%;
  overflow-y: auto;
}
.main-program #template-media-container .exo-content h2 {
  padding-bottom: 20px;
  display: block;
  color: #273d52;
  font-family: "RubikMd";
  font-size: 1.9rem;
  line-height: 2.3rem;
  margin-bottom: 0;
  font-weight: 500;
}
.main-program #template-media-container .exo-content .exo-head .template-program-qcm__content__head__separator {
  margin-top: 34px;
  margin-bottom: 38px;
}
.main-program #template-media-container .exo-content .line-question {
  /*     font-size: 16px;*/
  padding: 40px 0px 40px 0px !important;
}
.main-program #template-media-container .exo-content .line-question + .line-question {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.main-program #template-media-container .exo-content .line-question button {
  padding: 0px 20px 1px 20px;
  text-align: center;
  height: 46px;
  font-size: 1.5rem;
  line-height: 1;
}
.main-program #template-media-container .video-kumullus {
  width: 100%;
  height: 360px;
}
@media only screen and (max-width:767px) {
  .main-program #template-media-container .video-kumullus {
    width: 100%;
    height: 100%;
  }
}
.main-program #template-media-container .template-program-video-container .video-content {
  width: 100%;
}
.main-program #template-media-container .template-program-video-container .video-content .video-content-media-wrapper {
  position: relative;
  width: 100%;
  border-radius: 6px;
  margin-bottom: 55px;
  overflow: hidden;
}
.main-program #template-media-container .template-program-video-container .video-content .video-content-media-wrapper::before {
  content: "";
  display: block;
  height: 0;
  padding-bottom: 56.25%;
}
.main-program #template-media-container .template-program-video-container .video-content .video-content-media-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.main-program #template-media-container .template-program-video-container .video-content .video-content-media-wrapper .video-content-text-wrapper {
  padding-bottom: 30px;
}
.main-program #template-media-container .template-program-video-container .video-content h3 {
  font-size: 30px;
}
.main-program #template-media-container .template-program-video-container .video-content h4 {
  font-size: 2rem;
}
.main-program #template-media-container .template-program-objectexercise-container .qcm-content .qcm-questions .line-question {
  border-top: none;
}
.main-program #template-media-container .template-program-objectexercise-container .qcm-content .qcm-questions .line-question .qcm-questions-content.display-result .qcm-choice {
  pointer-events: none;
}
.main-program #template-media-container .template-program-objectexercise-container .qcm-content .qcm-questions .line-question .qcm-questions-content.display-result .qcm-choice.select.true-answer {
  background-color: #27c281;
}
.main-program #template-media-container .template-program-objectexercise-container .qcm-content .qcm-questions .line-question .qcm-questions-content.display-result .qcm-choice.select.true-answer .icones-result .fa-check-circle {
  display: block;
  color: white;
}
.main-program #template-media-container .template-program-objectexercise-container .qcm-content .qcm-questions .line-question .qcm-questions-content.display-result .qcm-choice.select:not(.true-answer) {
  background-color: #eb3c3c;
}
.main-program #template-media-container .template-program-objectexercise-container .qcm-content .qcm-questions .line-question .qcm-questions-content.display-result .qcm-choice.select:not(.true-answer) .icones-result .fa-times {
  display: block;
  color: white;
}
.main-program #template-media-container .template-program-objectexercise-container .qcm-content .qcm-questions .line-question .qcm-questions-content.display-result .qcm-choice.true-answer:not(.select) .icones-result .fa-check-circle {
  display: block;
  color: darkgreen;
}
.main-program #template-media-container .template-program-objectexercise-container .qcm-content .qcm-questions .line-question .qcm-questions-content .qcm-choice {
  padding: 20px 26px;
  display: block;
  font-size: 1.6rem;
  line-height: 2.3rem;
  background-color: #ffffff;
  color: #273d52;
  border-radius: 2px;
  -webkit-box-shadow: 0 10px 20px 1px rgba(5, 16, 43, 0.15);
  box-shadow: 0 10px 20px 1px rgba(5, 16, 43, 0.15);
  margin-bottom: 15px;
  cursor: pointer;
  -webkit-transition: all 0.35s ease;
  -o-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.main-program #template-media-container .template-program-objectexercise-container .qcm-content .qcm-questions .line-question .qcm-questions-content .qcm-choice p {
  margin: 0 !important;
}
.main-program #template-media-container .template-program-objectexercise-container .qcm-content .qcm-questions .line-question .qcm-questions-content .qcm-choice.select {
  background-color: #423cb0;
  color: #ffffff;
}
.main-program #template-media-container .template-program-objectexercise-container .qcm-content .qcm-questions .line-question .qcm-questions-content .qcm-choice .icones-result i {
  display: none;
  font-size: 20px;
}
.main-program #template-media-container .template-program-objectexercise-container .qcm-content .qcm-questions .line-question .qcm-questions-content .answer-comment {
  padding-top: 7px;
  padding-left: 60px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: none;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #273d52;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 32px;
}
.main-program #template-media-container .template-program-objectexercise-container .qcm-content .qcm-questions .line-question .qcm-questions-content .answer-comment i {
  margin-right: 10px;
  font-size: 18px;
}
.main-program #template-media-container .template-program-objectexercise-container .qcm-content .qcm-questions .line-question .user-action button {
  font-size: 1.5rem;
  line-height: 1;
  text-align: center;
}
.main-program #template-media-container .template-program-objectexercise-container .qcm-content .qcm-questions .line-question .user-action button.js-next-question, .main-program #template-media-container .template-program-objectexercise-container .qcm-content .qcm-questions .line-question .user-action button.js-result-final {
  display: none;
}
.main-program #template-media-container .template-program-objectexercise-container .qcm-content .qcm-questions .line-question:not(:first-child) {
  display: none;
}
.main-program #template-media-container .template-program-objectexercise-container .qcm-content .title-statement {
  display: block;
  color: #8996a2;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 20px;
}
.main-program #template-media-container .template-program-objectexercise-container .qcm-content .qcm-head-result, .main-program #template-media-container .template-program-objectexercise-container .qcm-content .explain-answer {
  display: none;
}
.main-program #template-media-container .template-program-objectexercise-container .qcm-content .user-action {
  display: flex;
}
.main-program #template-media-container .template-program-objectexercise-container .qcm-content.dispay-end-qcm .exo-head {
  display: none;
}
.main-program #template-media-container .template-program-objectexercise-container .qcm-content.dispay-end-qcm .qcm-head-result {
  display: block;
}
.main-program #template-media-container .template-program-objectexercise-container .qcm-content.dispay-end-qcm .qcm-head-result h2 {
  padding-bottom: 20px;
  display: block;
  color: #273d52;
  font-size: 1.9rem;
  line-height: 2.3rem;
  margin-bottom: 0;
}
.main-program #template-media-container .template-program-objectexercise-container .qcm-content.dispay-end-qcm .qcm-head-result h2 span {
  padding: 18px 21px;
  display: inline-block;
  color: #ffffff;
  background-color: #27c281;
  font-family: "Rubik";
  font-size: 1.9rem;
  line-height: 1;
  border-radius: 27px;
  margin-left: 7px;
}
.main-program #template-media-container .template-program-objectexercise-container .qcm-content.dispay-end-qcm .explain-answer {
  display: flex;
}
.main-program #template-media-container .template-program-objectexercise-container .qcm-content.dispay-end-qcm .explain-answer button {
  text-decoration: underline;
  font-size: 1.5rem;
  color: #273d52;
  border: none;
}
.main-program #template-media-container .template-program-objectexercise-container .qcm-content.dispay-end-qcm .user-action {
  display: none;
}
.main-program #template-media-container .template-program-objectexercise-container .qcm-content.dispay-end-qcm .line-question {
  display: block !important;
  padding: 40px 0px 40px 0px !important;
}
.main-program #template-media-container .template-program-objectexercise-container .qcm-content.dispay-end-qcm .answer-comment {
  display: flex !important;
}
.main-program #template-media-container .template-program-statement-line-container .block-question .line-question {
  /*font-size: 16px;*/
  padding: 40px 0px 40px 0px !important;
}
.main-program #template-media-container .template-program-statement-line-container .block-question .line-question + .line-question {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.main-program #template-media-container .template-program-statement-line-container .block-question .line-question .block-knowledge {
  display: none;
  margin-top: 40px;
  border-radius: 20px;
  border: 2px solid #e83279;
  padding: 15px;
}
.main-program #template-media-container .template-program-statement-line-container .block-question .line-question .block-knowledge .title-knowledge {
  color: #e83279;
  font-weight: 700;
  margin-bottom: 35px;
}
.main-program #template-media-container .template-program-statement-line-container .block-question .line-question button {
  padding: 0px 20px 1px 20px;
  text-align: center;
  height: 46px;
  font-size: 1.5rem;
  line-height: 1;
}
.js-template-popin#js-menu-program-summary-mobile {
  display: none;
  border-radius: 0px;
}

.list-programs-container-items .program-summary-container-items {
  background-color: #2f2a7c;
  overflow-y: auto;
  position: relative;
}
.list-programs-container-items .program-summary-container-items .summary-item {
  padding: 24px 35px;
  position: relative;
  text-decoration: none;
}
.list-programs-container-items .program-summary-container-items .summary-item:hover {
  opacity: 1 !important;
  text-decoration: none;
  background-color: rgba(33, 30, 106, 0.4);
}
.list-programs-container-items .program-summary-container-items .summary-item:hover .item-number {
  color: rgba(255, 255, 255, 0.8);
  border-color: rgba(255, 255, 255, 0.8);
}
.list-programs-container-items .program-summary-container-items .summary-item.active {
  background-color: #211e6a;
  opacity: 1 !important;
}
.list-programs-container-items .program-summary-container-items .summary-item.active .item-number {
  border: none;
  background-color: #ffffff;
  color: #423cb0;
}
.list-programs-container-items .program-summary-container-items .summary-item .item-number {
  width: 32px;
  height: 32px;
  color: rgba(255, 255, 255, 0.2);
  font-size: 1.4rem;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  margin-right: 24px;
  -webkit-transition: all 0.35s ease;
  -o-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.list-programs-container-items .program-summary-container-items .summary-item .item-number.finish {
  border: none;
  background-color: #ffffff;
  color: #423cb0;
}
.list-programs-container-items .program-summary-container-items .summary-item .item-icon {
  width: 32px;
  height: 32px;
  color: white;
  font-size: 1.4rem;
  text-align: center;
  margin-right: 24px;
}
.list-programs-container-items .program-summary-container-items .summary-item .item-icon.finish {
  color: #e83279;
}
.list-programs-container-items .program-summary-container-items .summary-item .item-content {
  width: calc( 100% - 60px);
}
.list-programs-container-items .program-summary-container-items .summary-item .item-content .c-program-summary__item__content__type {
  display: block;
  color: #ffffff;
  font-size: 1.5rem;
  line-height: 2.2rem;
}
.list-programs-container-items .program-summary-container-items .summary-item .item-content .c-program-summary__item__content__title {
  display: block;
  color: rgba(255, 255, 255, 0.4);
  font-size: 1.4rem;
  line-height: 2.2rem;
}
.list-programs-container-items .report-link-container {
  background-color: #2f2a7c;
  height: 60px;
}
.list-programs-container-items .report-link-container .report-link {
  color: rgba(255, 255, 255, 0.4);
  font-size: 1.5rem;
  line-height: 2.5rem;
  text-decoration: underline;
  bottom: 0px;
}
.list-programs-container-items .report-link-container .report-link:hover {
  color: white;
}

.program-exercice-popin h2 {
  margin-bottom: 25px;
}
.program-exercice-popin .content .list-tips li {
  color: black;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 10px;
}

.display-mobile-next-step {
  display: none;
}
.display-mobile-next-step a {
  height: 60px;
  color: white;
  background-color: #e83279;
  font-size: 1.5rem;
  text-decoration: none;
}
.display-mobile-next-step a:hover {
  background-color: #e83279;
}

.template-finish {
  padding: 57px 9%;
  height: 100%;
}
.template-finish .thumb-image {
  width: 218px;
  height: 154px;
}
.template-finish .thumb-image img {
  border-radius: 10px;
  object-fit: cover;
}
.template-finish h2 {
  display: block;
  color: #273d52;
  font-family: "RubikMd";
  font-size: 1.9rem;
  line-height: 2.3rem;
  margin-bottom: 40px;
}
.template-finish hr {
  margin-top: 40px;
  margin-bottom: 40px;
}
.template-finish #content {
  padding-left: 35px;
  font-size: 1.6rem;
  line-height: 2.4rem;
}
.template-finish ul {
  padding-left: 35px;
}
.template-finish ul li:hover .u-icon-play {
  display: flex !important;
}
.template-finish ul li .wrapper {
  width: 190px;
  height: 120px;
  position: relative;
}
.template-finish ul li .wrapper .u-icon-play {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: none;
  width: 41px;
  height: 41px;
  border-radius: 50%;
  background-color: #ffffff;
  z-index: 1;
}
.template-finish ul li .wrapper .u-icon-play i {
  color: #8B77FB;
  font-size: 1.4rem;
  margin-left: 4px;
  z-index: 2;
}
.template-finish ul li .wrapper img {
  object-fit: cover;
  border-radius: 10px;
}
.template-finish ul li a {
  color: black;
  text-decoration: none;
  font-size: 1.6rem;
}

.auto-eval-popin h3 {
  margin-bottom: 18px;
}
.auto-eval-popin .eval-text-left {
  margin-right: 12px;
}
.auto-eval-popin .eval-text-right {
  margin-left: 12px;
}
.auto-eval-popin .fa-star {
  cursor: pointer;
}
.auto-eval-popin .fa-star.hover, .auto-eval-popin .fa-star.checked {
  color: orange;
}
.auto-eval-popin .btn-auto-eval {
  padding: 0px 20px 1px 20px;
  text-align: center;
  height: 46px;
  font-size: 1.5rem;
  line-height: 1;
}
.auto-eval-popin a.btn-auto-eval {
  padding-top: 14px;
}

.program-activity-list {
  margin-top: 32px;
}
.program-activity-list .video-check .fa {
  color: #27c281;
  font-size: 20px;
}
.program-activity-list table tfoot {
  border: 2px solid #27c281;
}
.program-activity-list .redo-program {
  font-size: 1.5rem;
  height: 46px;
  border-radius: 3rem;
  padding: 10px 20px;
}

.template-account-profile-container {
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 6px;
  margin-top: 21px;
  margin-bottom: 21px;
  -webkit-box-shadow: 0 0 35px 1px rgba(5, 16, 43, 0.06);
  box-shadow: 0 0 35px 1px rgba(5, 16, 43, 0.06);
  height: calc(100% - 42px);
  overflow-y: auto;
}
.template-account-profile-container .profile-title {
  padding: 21px 26px;
  display: block;
  color: #273d52;
  border-bottom: 1px solid #eef0f3;
  font-size: 1.5rem;
  text-transform: uppercase;
}
.template-account-profile-container #nav-tabContent #nav-profile-update, .template-account-profile-container #nav-tabContent #nav-invoice {
  padding-top: 52px;
}
.template-account-profile-container #nav-tabContent #nav-subchild-tab #menu button {
  height: 38px;
  font-size: 14px;
}
.template-account-profile-container .account-profile-content .profile-content-left {
  text-align: center;
}
.template-account-profile-container .account-profile-content .profile-content-left .content-upload {
  position: relative;
  display: inline-block;
  width: 129px;
  height: 129px;
  cursor: pointer;
}
.template-account-profile-container .account-profile-content .profile-content-left .content-upload .u-upload__input {
  display: none;
}
.template-account-profile-container .account-profile-content .profile-content-left .content-upload .upload-preview:hover {
  background-color: #740d35;
}
.template-account-profile-container .account-profile-content .profile-content-left .content-upload .upload-preview img {
  object-fit: cover;
}
.template-account-profile-container .account-profile-content .profile-content-left .content-upload .upload-preview i {
  position: absolute;
  top: 50%;
  left: 50%;
  color: white;
  transform: translate(-50%, -50%);
  font-size: 2.7rem;
  display: none;
  z-index: 4;
}
.template-account-profile-container .account-profile-content .profile-content-left .content-upload .upload-preview.no-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  color: #ffffff;
  background-color: #e83279;
  position: relative;
}
.template-account-profile-container .account-profile-content .profile-content-left .content-upload .upload-preview.no-image .preview-letter {
  display: block;
  font-size: 6.2rem;
}
.template-account-profile-container .account-profile-content .profile-content-left .content-upload:hover .upload-preview:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: opacity 0.35s ease;
  -o-transition: opacity 0.35s ease;
  transition: opacity 0.35s ease;
  z-index: 3;
}
.template-account-profile-container .account-profile-content .profile-content-left .content-upload:hover i {
  display: block;
}
.template-account-profile-container .account-profile-content .profile-content-left .upload-label {
  margin-top: 12px;
  color: #939ea8;
  font-size: 1.4rem;
}
.template-account-profile-container .account-profile-content .profile-content-right .right-row {
  margin-bottom: 28px;
}
.template-account-profile-container .account-profile-content .profile-content-right .right-row .template-account-profile__content__input__label {
  font-size: 1.4rem;
  color: #939ea8;
  display: block;
  margin-bottom: 14px;
}
.template-account-profile-container .account-profile-content .profile-content-right .right-row .template-account-profile__content__input__label span {
  color: #8273f6;
}
.template-account-profile-container #nav-tab {
  overflow-x: auto;
  overflow-y: hidden;
}
.template-account-profile-container #nav-tab button.nav-link {
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #8996a2;
  border-bottom: 2px solid transparent;
  padding: 21px 26px;
}
.template-account-profile-container #nav-tab button.nav-link.active {
  border-bottom: 2px solid black;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  color: #273d52;
}
.template-account-profile-container #nav-tab button.nav-link:hover {
  border-bottom: 2px solid black;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  color: #273d52;
}
.template-account-profile-container #nav-tab button.nav-link:visited {
  border-bottom: 2px solid transparent;
}
.template-account-profile-container #nav-tab button.nav-link:focus, .template-account-profile-container #nav-tab button.nav-link:visited {
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}
.template-account-profile-container .tab-billing-card-details {
  padding: 0px 26px;
  width: 100%;
  border-bottom: 1px solid #eef0f3;
  padding-bottom: 40px;
}
.template-account-profile-container .tab-billing-card-details .billing-card-details-form .profile-tab-row .tab-label {
  display: block;
  margin-bottom: 14px;
}
.template-account-profile-container .tab-billing-card-details .billing-card-details-form .billing-card-details-terms .custom-control-label {
  padding-left: 12px;
  font-size: 1.3rem;
}
.template-account-profile-container .tab-billing-card-details .billing-card-details-form .billing-card-details-terms .custom-control-label a {
  color: #8273f6;
}
.template-account-profile-container .tab-invoice-list {
  padding: 32px 26px;
  width: 100%;
}
.template-account-profile-container .tab-invoice-list table td.status-error i {
  color: darkred;
}
.template-account-profile-container .tab-invoice-list table td.status-success i {
  color: darkgreen;
}
.template-account-profile-container .profile-tab-title {
  font-size: 1.7rem;
  color: #273d52;
  margin-bottom: 25px;
}

.billing-card-details-form .StripeElement {
  padding: 12px 13px;
  font-size: 1.5rem;
  border-radius: 6px;
  border: 1px solid rgba(137, 150, 162, 0.3);
  background: transparent;
}

#nav-subchild-tab .subscription-card {
  text-align: center;
  border: 1px solid #eef0f3;
  border-radius: 6px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
}
#nav-subchild-tab .subscription-card header .thumb-wrapper {
  margin: 0 auto;
  margin-bottom: 15px;
  width: 101px;
  height: 101px;
  border-radius: 50%;
  color: #ffffff;
  background-color: #e83279;
}
#nav-subchild-tab .subscription-card header .thumb-wrapper.thumb-wrapper-image img {
  object-fit: cover;
}
#nav-subchild-tab .subscription-card header .thumb-wrapper .preview-letter {
  font-size: 5rem;
  font-weight: bold;
}
#nav-subchild-tab .subscription-card header .resume-identity .name {
  text-align: center;
  color: #273d52;
  font-size: 1.6rem;
}
#nav-subchild-tab .subscription-card header .resume-identity .level {
  text-align: center;
  color: #8996a2;
  font-size: 1.5rem;
  line-height: 2.3rem;
}
#nav-subchild-tab .subscription-card .btn-offer {
  position: relative;
  display: inline-block;
  font-size: 1.5rem;
  text-decoration: none;
}
#nav-subchild-tab .subscription-card .btn-offer::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: block;
  width: 300%;
  height: 1px;
  background-color: #eef0f3;
  pointer-events: none;
  z-index: 1;
}
#nav-subchild-tab .subscription-card .btn-offer span {
  padding: 11px 22px;
  position: relative;
  display: block;
  color: #ffffff;
  background-color: #e83279;
  border-radius: 30px;
  box-shadow: 0 0 15px 1px rgba(5, 16, 43, 0.11);
  border: 1px solid #e83279;
  -webkit-transition: background-color 0.4s ease, color 0.4s ease, border-color 0.4s ease;
  -o-transition: background-color 0.4s ease, color 0.4s ease, border-color 0.4s ease;
  transition: background-color 0.4s ease, color 0.4s ease, border-color 0.4s ease;
  z-index: 2;
}
#nav-subchild-tab .subscription-card .btn-offer span:hover {
  background-color: #d5326a;
  border-color: #d5326a;
}
#nav-subchild-tab .subscription-card .btn-offer.active span {
  background-color: #8273f6;
  border: 1px solid #8273f6;
}
#nav-subchild-tab .subscription-card .sub-offer-title {
  text-align: center;
  color: #273d52;
  font-size: 1.4rem;
}
#nav-subchild-tab .subscription-card .option-end {
  text-align: center;
  font-size: 14px;
}
#nav-subchild-tab .subscription-card .sub-amount {
  text-align: center;
  font-family: "RubikMd";
  font-size: 1.9rem;
  color: #273d52;
  font-weight: bold;
}
#nav-subchild-tab .subscription-card .sub-option {
  padding: 0px 20px 1px 20px;
  height: 46px;
  font-size: 1.5rem;
  line-height: 1;
  text-align: center;
}
#nav-subchild-tab .subscription-card .discount {
  display: block;
  text-align: center;
  color: #e83279;
  font-size: 1.5rem;
}
#nav-subchild-tab .subscription-card .next-invoice {
  display: block;
  text-align: center;
  color: #8996a2;
  font-size: 1.5rem;
}
#nav-subchild-tab .subscription-card .update-subscription {
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 100%;
  height: 40px;
  width: 40px;
  border: 1px solid #273d52;
  cursor: pointer;
}
#nav-subchild-tab .subscription-card .update-subscription i {
  color: #273d52;
  font-size: 15px;
}
#nav-subchild-tab .subscription-card .update-subscription:hover {
  background-color: #273d52;
}
#nav-subchild-tab .subscription-card .update-subscription:hover i {
  color: white;
}

.template-assessments-container {
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 6px;
  margin-top: 21px;
  margin-bottom: 21px;
  height: calc(100% - 74px - 42px);
}
.template-assessments-container .assessments-container {
  -webkit-box-shadow: 0 0 35px 1px rgba(5, 16, 43, 0.06);
  box-shadow: 0 0 35px 1px rgba(5, 16, 43, 0.06);
  overflow-y: auto;
  height: 100%;
}
.template-assessments-container .assessments-container ul li {
  padding: 29px 39px;
  width: 100%;
}
.template-assessments-container .assessments-container ul li .item-label {
  display: inline-block;
  width: 145px;
  font-size: 1.6rem;
  color: #273d52;
  margin-right: 40px;
}
.template-assessments-container .assessments-container ul li + li {
  border-top: 1px solid #eef0f3;
}

.modal-header {
  border-radius: 0 !important;
}

.blocker {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 99;
  padding: 20px;
  box-sizing: border-box;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  text-align: center;
}

.blocker:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.05em;
}

.blocker.behind {
  background-color: transparent;
}

.modalcustom {
  display: none;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  max-width: 600px;
  box-sizing: border-box;
  width: 90%;
  background: #fff;
  padding: 30px 30px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 0 10px #000;
  -moz-box-shadow: 0 0 10px #000;
  -o-box-shadow: 0 0 10px #000;
  -ms-box-shadow: 0 0 10px #000;
  box-shadow: 0 0 10px #000;
  text-align: left;
}
.modalcustom .content {
  color: black;
  font-size: 1.6rem;
}
.modalcustom.template-popin-account {
  padding: 0 !important;
  border-radius: 0px;
}
.modalcustom.template-popin-account header {
  padding: 30px;
  background-color: #8273f6;
  color: white;
}
.modalcustom.template-popin-account .content {
  padding: 20px 30px;
}
.modalcustom.template-popin-account .content .but-submit {
  margin-top: 40px;
  padding: 0px 20px 0px 20px;
  height: 35px;
  font-size: 15px;
  min-width: 50%;
  margin: 0 auto;
}
.modalcustom#popin-menu a {
  text-decoration: none;
  height: 35px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px;
  font-size: 16px;
}
.modalcustom#popin-menu .menu-content {
  display: none;
}
.modalcustom#popin-menu .menu-content#reactivte-sub h4 {
  color: black;
  font-size: 18px;
}
.modalcustom#popin-menu .menu-content#reactivte-sub .line-offer {
  padding-bottom: 20px;
  padding-top: 20px;
}
.modalcustom#popin-menu .menu-content#reactivte-sub .line-offer + .line-offer {
  border-top: 1px solid #e3e4e4;
}
.modalcustom#popin-menu .menu-content#reactivte-sub .line-offer .title-offer {
  color: black;
}
.modalcustom#popin-menu .menu-content#reactivte-sub .line-offer .grab {
  font-size: 14px;
}
.modalcustom#popin-menu .menu-content#reactivte-sub .line-offer button {
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 16px;
}
.modalcustom#popin-options img {
  object-fit: cover;
}
.modalcustom#popin-options button {
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 16px;
}
.modalcustom#popin-options li {
  margin-bottom: 5px;
}
.modalcustom#popin-add-new-Subchild h3 {
  color: black;
}
.modalcustom#popin-add-new-Subchild input[type=submit] {
  width: 60%;
  height: 40px;
  font-size: 20px;
}
.modalcustom #block-offer .button-o, .modalcustom #block-display-offer .button-o, .modalcustom #block-display-offer-essai .button-o {
  font-size: 12px !important;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
}
.modalcustom #block-offer .line-offer, .modalcustom #block-display-offer .line-offer, .modalcustom #block-display-offer-essai .line-offer {
  margin-bottom: 15px;
}
.modalcustom #block-offer .choice-offer div.block, .modalcustom #block-display-offer .choice-offer div.block, .modalcustom #block-display-offer-essai .choice-offer div.block {
  width: calc(50% - 15px);
  background-color: #f7f7f7;
  padding: 10px;
}
.modalcustom #block-offer .choice-offer div.block h4, .modalcustom #block-display-offer .choice-offer div.block h4, .modalcustom #block-display-offer-essai .choice-offer div.block h4 {
  margin-bottom: -2px;
}
.modalcustom #block-offer .choice-offer div.block small, .modalcustom #block-display-offer .choice-offer div.block small, .modalcustom #block-display-offer-essai .choice-offer div.block small {
  font-size: 12px;
  display: block;
}
.modalcustom #block-offer .choice-offer div.block .price, .modalcustom #block-display-offer .choice-offer div.block .price, .modalcustom #block-display-offer-essai .choice-offer div.block .price {
  color: #e83279;
  font-weight: 400;
  display: block;
}
.modalcustom #block-offer .choice-offer div.block .detail li, .modalcustom #block-display-offer .choice-offer div.block .detail li, .modalcustom #block-display-offer-essai .choice-offer div.block .detail li {
  font-size: 14px;
}
.modalcustom #block-offer .choice-offer div.block .detail li i, .modalcustom #block-display-offer .choice-offer div.block .detail li i, .modalcustom #block-display-offer-essai .choice-offer div.block .detail li i {
  margin-right: 5px;
}
.modalcustom #block-offer .list-offers, .modalcustom #block-display-offer .list-offers, .modalcustom #block-display-offer-essai .list-offers {
  display: none;
}
.modalcustom #block-offer .list-offers .line-offer, .modalcustom #block-display-offer .list-offers .line-offer, .modalcustom #block-display-offer-essai .list-offers .line-offer {
  display: block;
}
.modalcustom #block-offer .list-offers.display-visio, .modalcustom #block-display-offer .list-offers.display-visio, .modalcustom #block-display-offer-essai .list-offers.display-visio {
  display: block;
}
.modalcustom #block-offer .list-offers.display-visio .line-offer.offer-visio, .modalcustom #block-display-offer .list-offers.display-visio .line-offer.offer-visio, .modalcustom #block-display-offer-essai .list-offers.display-visio .line-offer.offer-visio {
  display: flex;
}
.modalcustom #block-offer .list-offers.display-visio .line-offer:not(.offer-visio), .modalcustom #block-display-offer .list-offers.display-visio .line-offer:not(.offer-visio), .modalcustom #block-display-offer-essai .list-offers.display-visio .line-offer:not(.offer-visio) {
  display: none;
}
.modalcustom #block-offer .list-offers.display-essentielle, .modalcustom #block-display-offer .list-offers.display-essentielle, .modalcustom #block-display-offer-essai .list-offers.display-essentielle {
  display: block;
}
.modalcustom #block-offer .list-offers.display-essentielle .line-offer.offer-visio, .modalcustom #block-display-offer .list-offers.display-essentielle .line-offer.offer-visio, .modalcustom #block-display-offer-essai .list-offers.display-essentielle .line-offer.offer-visio {
  display: none;
}
.modalcustom #block-offer .list-offers.display-essentielle .line-offer:not(.offer-visio), .modalcustom #block-display-offer .list-offers.display-essentielle .line-offer:not(.offer-visio), .modalcustom #block-display-offer-essai .list-offers.display-essentielle .line-offer:not(.offer-visio) {
  display: flex;
}
.modalcustom #block-offer .list-offers-essai, .modalcustom #block-display-offer .list-offers-essai, .modalcustom #block-display-offer-essai .list-offers-essai {
  display: none;
}
.modalcustom #block-offer .list-offers-essai .line-offer-essai, .modalcustom #block-display-offer .list-offers-essai .line-offer-essai, .modalcustom #block-display-offer-essai .list-offers-essai .line-offer-essai {
  display: block;
}
.modalcustom #block-offer .list-offers-essai.display-visio-essai, .modalcustom #block-display-offer .list-offers-essai.display-visio-essai, .modalcustom #block-display-offer-essai .list-offers-essai.display-visio-essai {
  display: block;
}
.modalcustom #block-offer .list-offers-essai.display-visio-essai .line-offer-essai.offer-visio-essai, .modalcustom #block-display-offer .list-offers-essai.display-visio-essai .line-offer-essai.offer-visio-essai, .modalcustom #block-display-offer-essai .list-offers-essai.display-visio-essai .line-offer-essai.offer-visio-essai {
  display: flex;
}
.modalcustom #block-offer .list-offers-essai.display-visio-essai .line-offer-essai:not(.offer-visio-essai), .modalcustom #block-display-offer .list-offers-essai.display-visio-essai .line-offer-essai:not(.offer-visio-essai), .modalcustom #block-display-offer-essai .list-offers-essai.display-visio-essai .line-offer-essai:not(.offer-visio-essai) {
  display: none;
}
.modalcustom #block-offer .list-offers-essai.display-essentielle-essai, .modalcustom #block-display-offer .list-offers-essai.display-essentielle-essai, .modalcustom #block-display-offer-essai .list-offers-essai.display-essentielle-essai {
  display: block;
}
.modalcustom #block-offer .list-offers-essai.display-essentielle-essai .line-offer-essai.offer-visio-essai, .modalcustom #block-display-offer .list-offers-essai.display-essentielle-essai .line-offer-essai.offer-visio-essai, .modalcustom #block-display-offer-essai .list-offers-essai.display-essentielle-essai .line-offer-essai.offer-visio-essai {
  display: none;
}
.modalcustom #block-offer .list-offers-essai.display-essentielle-essai .line-offer-essai:not(.offer-visio-essai), .modalcustom #block-display-offer .list-offers-essai.display-essentielle-essai .line-offer-essai:not(.offer-visio-essai), .modalcustom #block-display-offer-essai .list-offers-essai.display-essentielle-essai .line-offer-essai:not(.offer-visio-essai) {
  display: flex;
}
.modalcustom #reduce-toto {
  border-top: 1px solid #8996a2;
  padding-top: 20px;
}
.modalcustom #reduce-toto #price-reduce-toto {
  color: #e83279;
  font-size: 25px;
}

@media (max-width: 766px) {
  #block-offer .choice-offer div, #block-display-offer .choice-offer div, #block-display-offer-essai .choice-offer div {
    width: 100% !important;
  }
}
.modalcustom a.close-modalcustom {
  position: absolute;
  top: -12.5px;
  right: -12.5px;
  display: block;
  width: 30px;
  height: 30px;
  text-indent: -9999px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAAA3hJREFUaAXlm8+K00Acx7MiCIJH/yw+gA9g25O49SL4AO3Bp1jw5NvktC+wF88qevK4BU97EmzxUBCEolK/n5gp3W6TTJPfpNPNF37MNsl85/vN/DaTmU6PknC4K+pniqeKJ3k8UnkvDxXJzzy+q/yaxxeVHxW/FNHjgRSeKt4rFoplzaAuHHDBGR2eS9G54reirsmienDCTRt7xwsp+KAoEmt9nLaGitZxrBbPFNaGfPloGw2t4JVamSt8xYW6Dg1oCYo3Yv+rCGViV160oMkcd8SYKnYV1Nb1aEOjCe6L5ZOiLfF120EjWhuBu3YIZt1NQmujnk5F4MgOpURzLfAwOBSTmzp3fpDxuI/pabxpqOoz2r2HLAb0GMbZKlNV5/Hg9XJypguryA7lPF5KMdTZQzHjqxNPhWhzIuAruOl1eNqKEx1tSh5rfbxdw7mOxCq4qS68ZTjKS1YVvilu559vWvFHhh4rZrdyZ69Vmpgdj8fJbDZLJpNJ0uv1cnr/gjrUhQMuI+ANjyuwftQ0bbL6Erp0mM/ny8Fg4M3LtdRxgMtKl3jwmIHVxYXChFy94/Rmpa/pTbNUhstKV+4Rr8lLQ9KlUvJKLyG8yvQ2s9SBy1Jb7jV5a0yapfF6apaZLjLLcWtd4sNrmJUMHyM+1xibTjH82Zh01TNlhsrOhdKTe00uAzZQmN6+KW+sDa/JD2PSVQ873m29yf+1Q9VDzfEYlHi1G5LKBBWZbtEsHbFwb1oYDwr1ZiF/2bnCSg1OBE/pfr9/bWx26UxJL3ONPISOLKUvQza0LZUxSKyjpdTGa/vDEr25rddbMM0Q3O6Lx3rqFvU+x6UrRKQY7tyrZecmD9FODy8uLizTmilwNj0kraNcAJhOp5aGVwsAGD5VmJBrWWbJSgWT9zrzWepQF47RaGSiKfeGx6Szi3gzmX/HHbihwBser4B9UJYpFBNX4R6vTn3VQnez0SymnrHQMsRYGTr1dSk34ljRqS/EMd2pLQ8YBp3a1PLfcqCpo8gtHkZFHKkTX6fs3MY0blKnth66rKCnU0VRGu37ONrQaA4eZDFtWAu2fXj9zjFkxTBOo8F7t926gTp/83Kyzzcy2kZD6xiqxTYnHLRFm3vHiRSwNSjkz3hoIzo8lCKWUlg/YtGs7tObunDAZfpDLbfEI15zsEIY3U/x/gHHc/G1zltnAgAAAABJRU5ErkJggg==");
}

.modalcustom-spinner {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  padding: 12px 16px;
  border-radius: 5px;
  background-color: #111;
  height: 20px;
}

.modalcustom-spinner > div {
  border-radius: 100px;
  background-color: #fff;
  height: 20px;
  width: 2px;
  margin: 0 1px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.modalcustom-spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.modalcustom-spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.modalcustom-spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.5);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}
@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.5);
    -webkit-transform: scaleY(0.5);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
#show-dialog h4 {
  font-size: 20px;
  color: #8273f6;
  font-weight: bold;
}
#show-dialog h4 i {
  color: #8273f6;
}
#show-dialog .flash-notice {
  font-size: 16px;
}

body {
  position: relative;
}
body .end-essai-gratuit-bandeau {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.w-45 {
  width: 45%;
}

.fs12 {
  font-size: 12px;
}

#children-checkboxes .checkbox-container {
  display: inline-block;
}
#children-checkboxes .checkbox-container + .checkbox-container {
  margin-left: 8px;
}
#children-checkboxes label {
  margin-left: 4px;
}

.popin-end-essai p {
  color: #ffffff;
}

.code-promo-wrapper {
  display: flex;
  flex-wrap: nowrap;
  margin: 12px 0;
}
.code-promo-wrapper input {
  padding: 12px;
}

.list-offers-essai .row + .row {
  margin-top: 24px;
}

.essai-gratuit-checkout .paiement-methods-wrapper {
  text-align: center;
  margin: 8px 0;
}
.essai-gratuit-checkout .formulaire-eleve input {
  border: 0;
  max-width: 100%;
}
.essai-gratuit-checkout #form-payment-tunnel .cardForm {
  padding-top: 15px;
  padding-bottom: 15px;
}
.essai-gratuit-checkout .select-parent-form {
  margin: 0;
  box-sizing: inherit;
  border: none;
  width: 100%;
  font-size: 15px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #ffffff !important;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 0px;
}
.essai-gratuit-checkout .block-form .select-parent-form {
  height: 44px;
}
.essai-gratuit-checkout #checkout-paiement {
  background-image: url("/public/build/wave/bg-yellow.svg");
  color: white;
  border-radius: 1.5rem !important;
}
.essai-gratuit-checkout #checkout-paiement #recp-toto {
  margin-bottom: 20px;
}
.essai-gratuit-checkout #checkout-paiement #recp-toto span {
  font-weight: bold;
  font-size: 30px;
}
.essai-gratuit-checkout #checkout-paiement .bg-white {
  color: black;
  font-size: 14px;
  line-height: 20px;
}

.block-user-access-content .container-access-content {
  background-color: white;
  padding: 30px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.36);
  border: 4px solid #273d52;
}
.block-user-access-content .container-access-content .block-user {
  font-size: 45px;
  font-weight: bold;
  line-height: 50px;
  margin-bottom: 40px;
}

@media (min-width: 1023.99px) {
  .block-user-access-content {
    padding: 5%;
  }
}
@media (max-width: 767.98px) {
  .latest-activities {
    height: 100%;
    overflow-y: auto;
  }

  .template-stage-single__form__step__subjects .template-stage-single__form__step__subjects__list .template-stage-single__form__step__subjects__item {
    width: 100%;
  }

  .contact-popin-form {
    padding: 55px 21px !important;
  }
}
@media (max-width: 1279.98px) {
  .template-stages__card {
    width: calc( 50% - 21px);
  }
}
@media (max-width: 1023.98px) {
  .menu__playlist-popin {
    width: 100%;
    height: 50vh;
    border-radius: 0 !important;
    top: 0;
  }

  .template-login__logo {
    top: 0px !important;
  }

  .block-main {
    height: auto !important;
  }

  .template-dashboard .menu__left {
    display: none;
  }

  .template-stage-single__resume__head__image {
    position: absolute;
    right: 29px;
    bottom: 58px;
    width: 85px;
  }

  /**** PLaylist *****************/
  .js-template-popin#js-menu-program-summary-mobile.open {
    position: absolute;
    top: 70px;
    width: 100%;
    color: black;
    left: 0;
    z-index: 30;
    display: block !important;
  }
  .js-template-popin#js-menu-program-summary-mobile.open .list-programs-container-items .program-summary-container-items {
    height: calc((var(--vh, 1vh) * 100) - 130px);
  }

  .program-summary-header-mobile {
    display: flex;
  }

  .program-summary-header-desktop {
    display: none !important;
  }

  .main-program {
    width: 100% !important;
    height: auto;
  }
  .main-program .list-programs-container-items {
    display: none;
  }
  .main-program #template-media-container {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
  }

  .template-dashboard #template-dashboard-main {
    width: 100%;
    height: 100%;
  }

  .display-mobile-next-step {
    display: block;
  }

  /**** fin ****/
  .contact-popin.open .contact-popin-container {
    width: 100%;
  }

  .search-popin-css.open {
    width: 100% !important;
    left: 0px !important;
  }

  .menu-mobile {
    border-radius: 0px;
    background-color: #302b98;
    width: 0;
    overflow: hidden;
    visibility: visible;
    -webkit-transition: all 0.45s ease 0.25s;
    -o-transition: all 0.45s ease 0.25s;
    transition: all 0.45s ease 0.25s;
    z-index: 10;
    color: white;
  }
  .menu-mobile.open {
    width: 100%;
    -webkit-transition: all 0.35s ease 0s;
    -o-transition: all 0.35s ease 0s;
    transition: all 0.35s ease 0s;
    pointer-events: all !important;
    opacity: 1;
  }
  .menu-mobile .mobile-container {
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    -webkit-transition: opacity 0.35s ease 0s;
    -o-transition: opacity 0.35s ease 0s;
    transition: opacity 0.35s ease 0s;
    z-index: 5;
  }
  .menu-mobile .mobile-container .mobile-items {
    padding: 0px 33px 0 33px;
    list-style: none;
    margin-bottom: 10px;
  }
  .menu-mobile .mobile-container .mobile-items li {
    position: relative;
    color: rgba(255, 255, 255, 0.6);
    border-bottom: 1px solid rgba(255, 255, 255, 0.11);
    cursor: pointer;
    opacity: 0.6;
  }
  .menu-mobile .mobile-container .mobile-items li.active {
    opacity: 1;
  }
  .menu-mobile .mobile-container .mobile-items li a {
    padding: 30px 0;
    text-decoration: none;
    color: white;
  }
  .menu-mobile .mobile-container .mobile-items li a .icone {
    font-size: 2.4rem;
    width: 1em;
    height: 1em;
  }
  .menu-mobile .mobile-container .mobile-items li a .mobile-item__label {
    font-size: 1.6rem;
    margin-left: 25px;
  }
  .menu-mobile .mobile-container .menu-mobile-profile {
    padding: 19px 26px 25px 26px;
    position: relative;
    color: #ffffff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.11);
    cursor: pointer;
  }
  .menu-mobile .mobile-container .menu-mobile-profile .mobile-profile-thumb-wrapper {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
  }
  .menu-mobile .mobile-container .menu-mobile-profile .mobile-profile-thumb-wrapper img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .menu-mobile .mobile-container .menu-mobile-profile .profile-name {
    margin-left: 15px;
    font-size: 16px;
  }
  .menu-mobile .mobile-container .menu-mobile-profile .fa-chevron-right {
    position: absolute;
    top: 50%;
    right: 30px;
    font-size: 1.4rem;
  }

  .template-stage-single__container {
    padding: 20px 20px 90px 20px;
    display: block;
  }

  .template-stage-single__col {
    width: 100%;
    margin-bottom: 10px;
  }

  .template-login__logo {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 209px;
    margin: 45px auto 38px auto;
  }

  .menu__profile-popin, .menu__notification-popin {
    padding: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0px;
    width: 100%;
    max-height: 100%;
  }

  #block-left {
    overflow-y: hidden;
    height: auto;
  }

  .u-dashboard-card {
    width: 100%;
    margin-bottom: 11px;
  }
  .u-dashboard-card a {
    padding: 5px 14px 6px 14px;
    height: 42px;
    font-size: 1.4rem;
  }

  .menu-list-programs {
    left: 0;
    width: 100%;
  }

  .list-programs-col {
    top: 70px;
    left: 0;
    width: 100%;
    -webkit-transform: translate(100%, 0);
    -ms-transform: translate(100%, 0);
    transform: translate(100%, 0);
    background-color: #302b98;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    z-index: 20;
  }
  .list-programs-col.active, .list-programs-col.open {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .list-programs-col.col-second {
    z-index: 25;
    width: 100%;
  }
  .list-programs-col.col-third {
    width: 100%;
    z-index: 30;
  }
  .list-programs-col.col-third .item-thumb-wrapper {
    width: 88px;
    height: 62px;
  }
  .list-programs-col.col-third .item-content {
    padding-left: 20px;
    width: calc( 100% - 88px);
  }
  .list-programs-col.col-third .item-content .item-title {
    font-size: 1.5rem;
    line-height: 2.3rem;
  }
  .list-programs-col.col-third .item-content .item-details {
    margin-top: 6px;
    font-size: 1.4rem;
  }
  .list-programs-col.col-third .item-content .item-details .details-progress {
    width: 60px;
  }
  .list-programs-col.col-third .programs-item, .list-programs-col.col-second .programs-item {
    padding: 23px 25px;
  }

  .template-stages__container .template-stages__card {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .template-stages__container .row {
    padding: 21px;
    margin: 0;
  }
  .template-stages__container .img-training {
    width: 30%;
    max-width: 140px;
  }

  .u-dashboard-card {
    padding: 33px 23px;
  }

  /***** Search page *******/
  .template-search-container .search-filters {
    padding-left: 0px;
    padding-right: 0px;
    height: 70px;
  }
  .template-search-container .search-filters .template-search__filters__label, .template-search-container .search-filters .template-search__filters__select, .template-search-container .search-filters .template-search__filters__label, .template-search-container .search-filters button:not(.js-open-search-filters) {
    display: none !important;
  }
  .template-search-container .search-filters .query-search {
    padding-left: 28px;
    width: calc( 100% - 70px);
    font-size: 1.6rem;
    margin-right: 0;
  }
  .template-search-container .search-filters .filters__mobile-toggle {
    display: flex;
  }
  .template-search-container .template-search__result__class {
    padding: 0px !important;
    font-size: 1.3rem;
    border: none !important;
  }
  .template-search-container .template-search__result__class .template-search__result__class__content {
    padding: 8px 14px !important;
  }
  .template-search-container .template-search__result__icon {
    width: 66px !important;
  }

  /********** REport **********/
  .template-assessments-container {
    height: calc((var(--vh, 1vh) * 100) - 74px - 42px - 85px);
  }

  .template-dashboard-parents.latest-activities {
    height: calc( (var(--vh, 1vh) * 100) - 85px - 74px);
  }
}
@media (min-width: 1023.99px) {
  .menu__playlist-popin {
    left: 7px;
    width: 346px;
    height: 50vh;
    top: 4px;
  }

  .template-search-container .search-filters button.js-open-search-filters {
    display: none !important;
  }

  .template-dashboard #template-dashboard-main {
    margin-left: 85px;
  }
}
@media (max-width: 1260px) {
  .statistics-items .item-chart, .statistics-items .statistics-item-content {
    width: 100% !important;
  }
}
@media (max-width: 426px) {
  #nav-invoice table .m-hide {
    display: none;
  }

  .contact-popin .contact-popin-container .contact-popin-form .form-row .form-input {
    width: 100% !important;
  }

  .result-content-right {
    position: absolute;
    right: 0px;
  }

  /*************** CHOICE ACCOUNT ***************/
  .template-account-choice__container {
    padding-top: 34px !important;
    padding-bottom: 34px !important;
  }
  .template-account-choice__container .template-account-choice__container__add-student {
    margin-top: 19px !important;
  }

  .template-account-choice__container__title {
    font-size: 1.8rem !important;
  }

  .c-account-choice-slider__items {
    overflow-y: auto;
    height: 300px;
    border-top: 1px solid #eef0f3;
    border-bottom: 1px solid #eef0f3;
  }
  .c-account-choice-slider__items .c-account-choice-slider__item {
    padding-top: 20px;
    padding-bottom: 20px;
    border-right: none !important;
  }
  .c-account-choice-slider__items .c-account-choice-slider__item .u-admin-clock {
    top: 0 !important;
    right: -10px !important;
    height: 29px !important;
    width: 29px !important;
  }
  .c-account-choice-slider__items .c-account-choice-slider__item + .c-account-choice-slider__item {
    border-top: 1px solid #eef0f3;
  }
  .c-account-choice-slider__items .c-account-choice-slider__item .c-account-choice-slider__item__thumb-wrapper {
    width: 77px !important;
    height: 77px !important;
  }

  #block-left .dashboard-news a {
    position: unset;
  }

  /************ Dash Home ***************/
  .activity-list a.list-item {
    padding: 28px 0px;
  }
  .activity-list a.list-item .list-item-details .details-progress {
    width: 69px;
  }

  /************   FIN ******************/
  /************  Playlist *************/
  .template-finish #content {
    padding-left: 0 !important;
  }

  .template-finish {
    padding: 52px 4%;
  }

  /************   FIN ******************/
}
/********* Class Boostrap ***********/
@media (max-width: 991px) {
  #block-right {
    height: auto;
  }

  .template-stage-single__col--left {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .template-stage-single__col--left {
    width: 42%;
  }
}
.banner-code {
  background-color: #5251d4;
  color: #fff;
  font-family: roboto, Sans-serif;
  font-size: 25px;
  font-weight: 600;
}
.banner-code span {
  color: #f5d361;
}

.infos-offer {
  font-size: 15px;
  background-color: #FBB03B;
  padding: 25px;
}

.tunnel {
  background-color: #EFF1F3;
}
.tunnel .fw-bold-title {
  font-weight: 800;
}
.tunnel .block-rounded {
  border-radius: 10px;
}
.tunnel .wrapper-img {
  width: 120px;
}
.tunnel .wrapper-img img {
  object-fit: contain;
}
.tunnel small {
  color: #6D757D;
}
.tunnel .title {
  text-align: center;
  margin-bottom: 50px;
}
.tunnel .title h2 {
  color: #EB4E3C;
  margin-bottom: 20px;
}
.tunnel .title h2 span {
  color: #1f2660;
}
.tunnel .card-infos-user {
  border: 1px solid #3B763D;
  background-color: #DFEFD9;
  color: #3B763D;
  padding: 20px;
  margin-bottom: 60px;
  border-radius: 10px;
}
.tunnel .fa-check {
  color: #00CC00;
  width: 25px;
}
.tunnel .fa-times {
  color: darkred;
  width: 25px;
}
.tunnel select#nbr-child {
  width: 150px;
}
.tunnel h4 {
  color: #1f2660;
}
.tunnel .table-responsive {
  margin-top: 40px;
}
.tunnel ul.ul-check li {
  list-style: none;
  position: relative;
}
.tunnel ul.ul-check li:after {
  content: "";
  ackground-color: transparent;
  position: absolute;
  left: -24px;
  top: 6px;
  width: 7px;
  border-bottom: 3px solid #4D7C2A;
  height: 14px;
  border-right: 3px solid #4D7C2A;
  transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.btn-validate {
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  font-style: normal;
  text-decoration: none;
  letter-spacing: 0;
  word-spacing: 0;
  fill: #fff;
  color: #fff;
  background-color: #fbb03b;
  border-style: solid;
  border-width: 1px;
  border-color: #fbb03b;
  border-radius: 100px 100px 100px 100px;
  padding: 16px 55px;
}
.btn-validate:hover {
  color: #fbb03b;
  background-color: white;
}
.btn-validate.blue {
  background-color: #544ddc !important;
  border-color: #544ddc !important;
}
.btn-validate.blue:hover {
  color: #544ddc !important;
  background-color: white !important;
}

.u-spinner {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition: all 0.35s ease;
  -o-transition: all 0.35s ease;
  transition: all 0.35s ease;
  visibility: hidden;
  opacity: 0;
  z-index: 99999999;
}
.u-spinner.open {
  visibility: visible;
  opacity: 1;
}
.u-spinner svg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  fill: #ffffff;
  font-size: 12rem;
}
.u-spinner .svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  overflow: hidden;
}

.speaking {
  font-family: "NanumBrushScript", cursive;
  font-size: 21px;
  line-height: 30px;
}

.block-yellow {
  background-color: #FECC33;
  padding: 10px 25px;
}

.block-yellow-2 {
  background-color: #F4D989;
  padding: 10px 25px;
}

@media (min-width: 768px) {
  .tunnel .select-offer {
    padding: 30px 20px 35px 20px;
    border-radius: 10px;
    border: 1px solid #E7E7E7;
    margin-bottom: 30px;
  }
  .tunnel .select-offer .best-price {
    font-weight: bolder;
    padding: 0 9px;
    background-color: #EB4E3C;
    color: white;
    display: inline-block;
    border-radius: 5px;
  }
  .tunnel .select-offer .form-check {
    align-items: center;
    display: flex;
  }
  .tunnel .select-offer label {
    margin-left: 15px;
    font-size: 25px;
    font-weight: bold;
  }
  .tunnel .select-offer label span {
    font-size: 18px;
    margin-left: 50px;
  }
  .tunnel .select-offer .infos-offer {
    width: 300px;
  }
  .tunnel .select-offer.actif {
    border: 3px solid #FBB03B !important;
  }
  .tunnel .select-offer.most-offer {
    padding: 0 !important;
  }
  .tunnel .select-offer.most-offer .block-details-price {
    padding: 10px;
  }
}
@media (max-width: 767.98px) {
  .tunnel .select-offer {
    padding: 30px 20px 35px 20px;
    border-radius: 10px;
    border: 1px solid #E7E7E7;
    margin-bottom: 30px;
  }
  .tunnel .select-offer .best-price {
    font-weight: bolder;
    background-color: #EB4E3C;
    color: white;
    padding-right: 20px;
    margin-bottom: 11px;
    padding-left: 6px;
  }
  .tunnel .select-offer .form-check {
    align-items: start;
    display: flex;
    margin-top: 34px;
  }
  .tunnel .select-offer .form-check .form-check-input[type=radio] {
    margin-top: 11px;
  }
  .tunnel .select-offer label {
    margin-left: 15px;
    font-size: 25px;
    font-weight: bold;
  }
  .tunnel .select-offer label span {
    font-size: 18px;
    display: block;
  }
}