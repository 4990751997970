.block-main{
  height: 100%;
  &.template-dashboard-parents{
    height: calc( 100% - 74px);
  }
}
#block-right{

  height: 100%;
  header{
    border-bottom: 1px solid #eef0f3;
    padding: 35px 26px 26px 25px;
  }
}
#block-left{
  height: 100%;
  overflow-y:auto;
  .dashboard-news{
    position:relative;
    a{
      position: absolute;
      right: 30px;
      bottom: 30px;
      height: 46px;
      padding: 0px 20px 1px 20px;
      font-size: 1.5rem;
      /* line-height: 1; */
      text-align: center;
      border-radius: 30px;
      -webkit-box-shadow: 0 0 15px 1px rgb(5 16 43 / 11%), 0 7px 10px 0 rgb(39 61 82 / 13%);
      box-shadow: 0 0 15px 1px rgb(5 16 43 / 11%), 0 7px 10px 0 rgb(39 61 82 / 13%);
    }
  }
}
.activity-list{

  overflow-y: auto;
  height: calc( 100% - 91px);
  a.list-item{
    padding: 28px 26px;
    text-decoration: none;
    &+.list-item{
      border-top: 1px solid #eef0f3;
    }

    &:hover{
      background-color: rgba(243, 244, 246, 0.5);
      text-decoration: none;
      .item-thumb-wrapper{
        .u-icon-play{
          display: flex !important;
        }
      }
    }
    .item-thumb-wrapper{
      .u-icon-play{
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        display: none;
        width: 41px;
        height: 41px;
        border-radius: 50%;
        background-color: #ffffff;
        z-index: 1;
        i{
          color: #8B77FB;
          font-size: 1.4rem;
          margin-left: 4px;
          z-index: 2;
        }
      }
      height: 112px;
      img{
        object-fit:cover;
        width:100%;
        height:100%;
        border-radius: 15px;
      }
    }
    .list-item-title{
      color: #273d52;
      font-size: 1.6rem;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .list-item-description{
      display: block;
      color: #8996a2;
      font-size: 1.5rem;
      white-space: nowrap;
      margin-bottom: 8px;
    }
    .list-item-details{
      .details-date{
        color: #8996a2;
        font-size: 1.4rem;
        margin-right: 14px;
      }
      .details-progress{
        width: 97px;
        margin-right: 12px;
        margin-top: 10px;
        height: 5px;
        .progress-bar{
          background-color: #8B77FB;
        }
      }
      .details-percent{
        color: #8B77FB;
        margin-top: 6px;
      }
      .details-completed{
        color: #27c281;
        font-size: 1.4rem;
      }
    }
  }
}
.section-home{
  border-radius: 6px;
  padding: 35px 21px 35px 41px;
}
.news__description{
  color: #8996a2;
  font-size: 1.5rem;
  line-height: 2.3rem;
}
.u-dashboard-card{
  position:relative;
  width: calc( 50% - 10px);
  margin-bottom: 21px;
  background-color: #8B77FB;
  border-radius: 6px;
  -webkit-box-shadow: 0 0 35px 1px rgb(5 16 43 / 6%);
  box-shadow: 0 0 35px 1px rgb(5 16 43 / 6%);
  padding: 35px 21px 35px 41px;
  .card-title{
    text-transform: uppercase;
    font-size: 1.5rem;
    color: #ffffff;
    margin-bottom: 20px;
  }
  .card-content{
    font-size: 1.5rem;
    line-height: 2.3rem;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 28px;
  }

  img {
    position: absolute;
    right: 25px;
    bottom: 35px;
    z-index: 1;
    width: 147px;
    height: 123px;
  }
  .btn{
    height: 46px;
    font-size: 1.5rem;
    line-height: 1;
    text-align: center;
    border-radius: 30px;
    -webkit-box-shadow: 0 0 15px 1px rgb(5 16 43 / 11%), 0 7px 10px 0 rgb(39 61 82 / 13%);
    box-shadow: 0 0 15px 1px rgb(5 16 43 / 11%), 0 7px 10px 0 rgb(39 61 82 / 13%);
    padding: 0px 20px 1px 20px;
  }
}

.dashboard-statistics{
  margin-bottom: 21px;
  border-radius: 6px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 35px 1px rgb(5 16 43 / 6%);
  box-shadow: 0 0 35px 1px rgb(5 16 43 / 6%);
  h2{
    color: #273d52;
    font-size: 1.5rem;
    text-transform: uppercase;
    margin: 0;
    &::after{
      content: '';
      display: inline-block;
      width: 1px;
      height: 23px;
      margin: 0 20px 0 19px;
      background-color: #eef0f3;
    }
  }
  .statistics-head{
    padding: 31px 26px 26px 26px;
    border-bottom: 1px solid #eef0f3;
    .head-navigation{
      .navigation-list{
        min-width: 115px;
        text-align: center;
        .template-dashboard__statistics__head__navigation__list__item {
          display: none;
          color: #273d52;
          font-size: 1.5rem;
          text-transform: uppercase;
          &.active {
            display: block;
          }
        }

      }
      .navigation-arrow{
        padding: 0 7px;
        margin-top: 1px;
        font-size: 1.4rem;
        opacity: 1;
        pointer-events: all;
        -webkit-transition: opacity 0.35s ease;
        -o-transition: opacity 0.35s ease;
        transition: opacity 0.35s ease;
        cursor: pointer;
        &.disabled{
          opacity: 0.3;
          pointer-events: none;
        }
      }
    }
  }
  .statistics-items{
    display:block;
    .statistics-item{
      display:none;
      &.active{
        display: flex;
      }
      .item-chart{
        padding: 37px 35px 37px 44px;
        width: calc( 100% - 289px);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-right: 1px solid #eef0f3;
        canvas{
          width: 35% !important;
          height: auto !important;
        }
        .item-chart__title{
          padding-bottom: 27px;
          display: block;
          width: 100%;
          color: #273d52;
          text-align: center;
          font-size: 1.5rem;
          line-height: 2.3rem;
        }
        .ul-statistics-item-labels{
          width: 65%;
          li{
            position: relative;
            display: block;
            width: 100%;
            vertical-align: top;
            font-size: 1.5rem;
            line-height: 1.3;
            margin-bottom: 20px;
            margin-right: 40px;
            .template-dashboard__statistics__item__label__color {
              position: relative;
              display: inline-block;
              vertical-align: top;
              width: 13px;
              height: 13px;
              border-radius: 13px;
              margin-top: 2px;
              margin-right: 8px;
              &.exo{
                background-color: rgb(255,144,77);
              }
              &.video{
                background-color: rgb(2,191,98);
              }
              &.sj{
                background-color: rgb(213,50,105);
              }
            }
            .template-dashboard__statistics__item__label__percentage {
              display: inline-block;
              vertical-align: top;
              margin-right: 10px;
            }
            .template-dashboard__statistics__item__label__content {
              display: inline;
              vertical-align: top;
              color: #8996a2;
            }
          }
        }
      }
      .statistics-item-content{
        width: 289px;
        .statistics-item-content__row{
          padding: 28px 39px 24px 39px;
          border-bottom: 1px solid #eef0f3;
          .statistics-item-content__label{
            color: #8996a2;
            font-size: 1.5rem;
            line-height: 2.5rem;
            strong {
              padding-bottom: 5px;
              display: block;
              color: #273d52;
              font-weight: normal;
              font-size: 3rem;
            }
          }
        }
      }
      .template-dashboard__statistics__item__no-stats {
        padding: 47px 0px 88px 0px;
        text-align: center;
        width: 100%;
      }
      .template-dashboard__statistics__item__no-stats__text {
        display: block;
        width: 55%;
        margin: 23px auto 0 auto;
        color: #273d52;
        font-size: 1.6rem;
        text-align: center;
        line-height: 2.9rem;
      }
    }
  }
}
.menu-mobile{
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  height: 100%;
  right: 0;
  bottom: 0;
}

.missionexam{
  h4{
    color: #273d52;font-size: 1.5rem;text-transform: uppercase;
    padding: 31px 26px 26px 26px;
  }
  .block-table{
    padding: 0 26px 26px 26px;
    th{
      background-color: #8B77FB;
      color: white;
      font-weight: bold;
      vertical-align: middle;
    }
    tr{
      td:nth-child(1){
        font-weight: bold;
      }
      td:nth-child(3),td:nth-child(4){
        vertical-align: middle;height: 100%;margin: auto;text-align: center;
        color: #273d52;
        font-weight: normal;
        font-size: 20px;
      }

    }
    .line-program{
      padding: 3px;
      &:nth-child(even) {background: #F1F2F4}
      div:nth-child(2){
        font-weight: bold;
      }
    }
  }
}