.template-account-profile-container{
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 6px;
  margin-top: 21px;
  margin-bottom: 21px;
  -webkit-box-shadow: 0 0 35px 1px rgb(5 16 43 / 6%);
  box-shadow: 0 0 35px 1px rgb(5 16 43 / 6%);
  height: calc(100% - 42px);

  overflow-y:auto;
  .profile-title {
    padding: 21px 26px;
    display: block;
    color: #273d52;
    border-bottom: 1px solid #eef0f3;
    font-size: 1.5rem;
    text-transform: uppercase;
  }
  #nav-tabContent{
    #nav-profile-update,#nav-invoice{
      padding-top: 52px;
    }
    #nav-subchild-tab{
      #menu{
        button{
          height: 38px;
          font-size: 14px;
        }
      }
    }
  }
  .account-profile-content{
    .profile-content-left{
      text-align: center;
      .content-upload{
        position: relative;
        display: inline-block;
        width: 129px;
        height: 129px;
        cursor: pointer;
        .u-upload__input{
          display:none;
        }
        .upload-preview{
          &:hover{
            background-color: darken(#e83279,30);
          }
          img{
            object-fit: cover;
          }
          i{
            position:absolute;
            top: 50%;
            left: 50%;
            color:white;
            transform: translate(-50%, -50%);
            font-size: 2.7rem;
            display:none;
            z-index: 4;
          }
          &.no-image{
            width: 100%;
            height: 100%;
            border-radius: 50%;
            color: #ffffff;
            background-color: #e83279;
            position:relative;
            .preview-letter{
              display: block;
              font-size: 6.2rem;
            }
          }
        }
        &:hover{
          .upload-preview:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.5);
            -webkit-transition: opacity 0.35s ease;
            -o-transition: opacity 0.35s ease;
            transition: opacity 0.35s ease;
            z-index: 3;
          }
          i{
            display:block;
          }
        }
      }
      .upload-label{
        margin-top: 12px;
        color: #939ea8;
        font-size: 1.4rem;
      }
    }
    .profile-content-right{
      .right-row{
        margin-bottom: 28px;
        .template-account-profile__content__input__label {
          font-size: 1.4rem;
          color: #939ea8;
          display:block;
          margin-bottom: 14px;
          span{
            color: $purple;
          }
        }
      }
    }
  }
  #nav-tab{
    overflow-x:auto;
    overflow-y:hidden;
    button.nav-link{
      font-size: 1.5rem;
      text-transform: uppercase;
      color: #8996a2;
      border-bottom:2px solid transparent;
      padding: 21px 26px;
      &.active{
        border-bottom:2px solid black;
        border-top:1px solid transparent;
        border-left:1px solid transparent;
        border-right:1px solid transparent;
        color: #273d52;
      }
      &:hover{
        border-bottom:2px solid black;
        border-top:1px solid transparent;
        border-left:1px solid transparent;
        border-right:1px solid transparent;
        color: #273d52;
      }
      &:visited{
        border-bottom:2px solid transparent;
      }
      &:focus,&:visited{
        border-top:1px solid transparent;
        border-left:1px solid transparent;
        border-right:1px solid transparent;
      }
    }
  }
  .tab-billing-card-details{
    padding: 0px 26px;
    width: 100%;
    border-bottom: 1px solid #eef0f3;
    padding-bottom: 40px;
    .billing-card-details-form{
      .profile-tab-row{
        .tab-label{
          display: block;
          margin-bottom: 14px;
        }
      }
      .billing-card-details-terms{
        .custom-control-label{
          padding-left: 12px;
          font-size: 1.3rem;
          a{
            color: #8273f6;
          }
        }
      }

    }
  }
  .tab-invoice-list{
    padding: 32px 26px;
    width: 100%;
    table{

      td{
        &.status-error{
          i{
            color: darkred;
          }
        }
        &.status-success{
          i{
            color: darkgreen;
          }
        }
      }
    }
  }
  .profile-tab-title{
    font-size: 1.7rem;
    color: #273d52;
    margin-bottom: 25px;
  }
}
.billing-card-details-form{
  .StripeElement{
    padding: 12px 13px;
    font-size: 1.5rem;
    border-radius: 6px;
    border: 1px solid rgba(137, 150, 162, 0.3);
    background: transparent;
  }
}



#nav-subchild-tab{
  .subscription-card{
    text-align: center;
    border: 1px solid #eef0f3;
    border-radius: 6px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    overflow:hidden;
    position:relative;
    header{
      .thumb-wrapper{
        margin:0 auto;
        margin-bottom: 15px;
        width: 101px;
        height: 101px;
        border-radius: 50%;
        color: #ffffff;
        background-color: #e83279;

        &.thumb-wrapper-image{
          img{
            object-fit: cover;
          }
        }

        .preview-letter{
          font-size: 5rem;
          font-weight: bold;
        }
      }
      .resume-identity{
        .name{
          text-align: center;
          color: #273d52;
          font-size: 1.6rem;
        }
        .level{
          text-align: center;
          color: #8996a2;
          font-size: 1.5rem;
          line-height: 2.3rem;
        }
      }
    }
    .btn-offer{
      position: relative;
      display: inline-block;
      font-size: 1.5rem;
      text-decoration: none;
      &::before{
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        display: block;
        width: 300%;
        height: 1px;
        background-color: #eef0f3;
        pointer-events: none;
        z-index: 1;
      }
      span{
        padding: 11px 22px;
        position: relative;
        display: block;
        color: #ffffff;
        background-color: #e83279;
        border-radius: 30px;
        box-shadow: 0 0 15px 1px rgb(5 16 43 / 11%);
        border: 1px solid #e83279;
        -webkit-transition: background-color 0.4s ease, color 0.4s ease, border-color 0.4s ease;
        -o-transition: background-color 0.4s ease, color 0.4s ease, border-color 0.4s ease;
        transition: background-color 0.4s ease, color 0.4s ease, border-color 0.4s ease;
        z-index: 2;
        &:hover{
          background-color: #d5326a;
          border-color: #d5326a;
        }
      }
      &.active{
        span{
          background-color: $purple;
          border: 1px solid $purple;
        }
      }
    }
    .sub-offer-title{
      text-align: center;
      color: #273d52;
      font-size: 1.4rem;
    }
    .option-end{
      text-align: center;
      font-size: 14px;
    }
    .sub-amount{
      text-align: center;
      font-family: "RubikMd";
      font-size: 1.9rem;
      color: #273d52;
      font-weight: bold;
    }
    .sub-option{
      padding: 0px 20px 1px 20px;
      height: 46px;
      font-size: 1.5rem;
      line-height: 1;
      text-align: center;
    }
    .discount{
      display: block;
      text-align: center;
      color: #e83279;
      font-size: 1.5rem;
    }
    .next-invoice{
      display: block;
      text-align: center;
      color: #8996a2;
      font-size: 1.5rem;
    }
    .update-subscription{
      position:absolute;
      top:20px;
      right:20px;
      border-radius: 100%;
      height: 40px;
      width: 40px;
      border: 1px solid #273d52;
      cursor:pointer;
      i{
        color:#273d52;
        font-size: 15px;
      }
      &:hover{
        background-color: #273d52;
        i{
          color:white;
        }
      }
    }
  }

}