.bg-lbp{
  background-image: url("/public/static/img/commun/background-login.jpeg");
  background-size: cover;
  background-position: center;
  background-color: #423cb0;
}

.btn-raduis{
  border-radius: 30px;
}
.btn-pink {
  color: #fff;
  background-color: $purple!important;
  border-color: $purple!important;
  &.btn-form{
    -webkit-box-shadow: 0 0 15px 1px rgb(5 16 43 / 11%), 0 7px 10px 0 rgb(39 61 82 / 13%);
    box-shadow: 0 0 15px 1px rgb(5 16 43 / 11%), 0 7px 10px 0 rgb(39 61 82 / 13%);
    padding: 0px 20px 0px 20px;
    height: 46px;
    font-size: 1.5rem;
    line-height: 1;
  }
  &:hover{
    background-color: #6868bf;
    border-color: #6868bf;
    color: #fff;
  }
}

.btn-pink-o {
  color: $purple;
  background-color: white;
  border-color: $purple;
  &:hover{
    background-color: #6868bf;
    border-color: #6868bf;
    color: #fff;
  }
}

.btn-purple {
  color: #fff;
  background-color: #e83279;
  border-color: #e83279;
  &.btn-form{
    -webkit-box-shadow: 0 0 15px 1px rgb(5 16 43 / 11%), 0 7px 10px 0 rgb(39 61 82 / 13%);
    box-shadow: 0 0 15px 1px rgb(5 16 43 / 11%), 0 7px 10px 0 rgb(39 61 82 / 13%);
    padding: 0px 20px 0px 20px;
    height: 46px;
    font-size: 1.5rem;
    line-height: 1;
  }
  &:hover{
    background-color: #d5326a;
    border-color: #d5326a;
    color: #fff;
  }
}

.btn-green {
  color: #fff;
  background-color: #27c281;
  border-color: #27c281;
  &:hover{
    background-color: darken(#27c281,10);
    border-color: darken(#27c281,10);
    outline: none;
    color: #fff;
  }
}

.btn-grey {
  color: black;
  background-color: #ececec;
  border-color: #ececec;
  &:hover{
    background-color: darken(#ececec,15);
    border-color: darken(#ececec,15);
    outline: none;

  }
}

.btn-white-o {
  color: black;
  background-color: white;
  border-color: white;
  &:hover{
    background-color: transparent;
    color: #fff;
  }
}

.btn-update-o{
  @extend .btn-raduis;
  border: 1px solid #273d52;
  color: black;
  -webkit-box-shadow: 0 0 15px 1px rgb(5 16 43 / 11%);
  box-shadow: 0 0 15px 1px rgb(5 16 43 / 11%);
  background-color: white;
  &:hover{
  background-color: $purple;
  border-color: $purple;
  color: #fff;
    text-decoration: none;
  }
}
.popin-style{
background-color: #ffffff;
border-radius: 6px;
-webkit-box-shadow: 0 10px 25px 5px rgb(5 16 43 / 24%);
box-shadow: 0 10px 25px 5px rgb(5 16 43 / 24%);
  .back-btn{
    .back-btn-label{
      font-size: 16px;
      i{
        margin-right: 20px;
      }
    }
  }
  .popin__close{
    position: absolute;
    top: 0px;
    right: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 70px;
    height: 70px;
    background-color: #8B77FB;
    border: none;
    cursor: pointer;
    color:white;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: background-color 0.35s ease 0s, -webkit-transform 0.45s ease 0s;
    transition: background-color 0.35s ease 0s, -webkit-transform 0.45s ease 0s;
    -o-transition: transform 0.45s ease 0s, background-color 0.35s ease 0s;
    transition: transform 0.45s ease 0s, background-color 0.35s ease 0s;
    transition: transform 0.45s ease 0s, background-color 0.35s ease 0s, -webkit-transform 0.45s ease 0s;


    i{
      font-size: 20px;
    }
  }
  &.open{
    .popin__close{
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
      -webkit-transition: -webkit-transform 0.4s ease 0s;
      transition: -webkit-transform 0.4s ease 0s;
      -o-transition: transform 0.4s ease 0s;
      //transition: transform 0.4s ease 0s;
      transition: transform 0.4s ease 0s, -webkit-transform 0.4s ease 0s;
    }
  }
}

.thumb-wrapper{
  position: relative;
  width: 130px;
  height: 130px;
  overflow: visible;
  .u-admin-clock {
    position: absolute;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    top: -10px;
    right: 0;
    height: 46px;
    width: 46px;
    border-radius: 50%;
    color: white;
    font-size:15px;
    background-color: #423cb0;
  }

}

.item-thumb-wrapper-play{
  position:relative;
  .u-icon-play{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: none;
    width: 41px;
    height: 41px;
    border-radius: 50%;
    background-color: #ffffff;
    z-index: 1;
    i{
      color: $purple;
      font-size: 1.4rem;
      margin-left: 4px;
      z-index: 2;
    }
  }
}

  .u-input textarea, .u-textarea textarea {
    height: 149px;
    width: 100%;
  }

.u-input-label, .u-textarea-label {
  font-size: 1.4rem;
  color: #939ea8;
}

  .u-input input, .u-input textarea, .u-textarea input, .u-textarea textarea {
    padding: 10px 13px;
    font-size: 1.5rem;
    border-radius: 6px;
    border: 1px solid rgba(137, 150, 162, 0.3);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    color: #273d52;
    -webkit-transition: border-color 0.35s ease;
    -o-transition: border-color 0.35s ease;
    transition: border-color 0.35s ease;
    resize: none;
  }
  .custom-select{
    select{
      padding: 10px 35px 10px 13px;
      font-size: 1.5rem;
      border-radius: 6px;
      border: 1px solid rgba(137, 150, 162, 0.3);
      background: transparent;
      color: #273d52;
      transition: border-color 0.35s ease;
      cursor: pointer;
    }
    i{
      position: absolute;
      top: 20px;
      right: 15px;
      font-size: 1.2rem;
    }
  }
  .hide-element{
    display:none;
  }
  .thumb-wrapper{
    .thumb-wrapper__letter{
      width:100%;
      height:100%;
      border-radius: 100px;
      display: flex;
      color:white;
      font-size: 3rem;
      background-color: #8273f6;
    }
    &.female{
      .thumb-wrapper__letter{
        background-color: $pink;
      }

    }
  }
  .portrait{
    object-fit: cover;
  }

  ol.custom{
    margin-left: 11px;
    &.pink > li:before{
      background: #E83279;
    }
    &.purple > li:before{
      background: $purple;
    }
    & > li{
      counter-increment: item;
      margin-bottom: 7.5px;
      list-style: none;
      position: relative;


      &:before {
        margin-right: 10px;
        content: counter(item);
        border-radius: 50%;
        color: white;
        width: 1.5em;
        text-align: center;
        display: inline-block;
        height: 1.5em;
        position: absolute;
        left: -33px;
      }
    }
  }