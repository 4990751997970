main {
  position: relative;
  min-height: 100%;
  background-color: #f3f4f6;
}
a{

  &:hover{
    text-decoration: none;
  }
}
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}
user agent stylesheet
main {
  display: block;
}
style attribute {
  --vh: 3.2px;
}
:root {
  --swiper-navigation-size: 44px;
  /* --swiper-navigation-color: var(--swiper-theme-color); */
}
:root {
  --swiper-theme-color: #007aff;
}
body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-size: 16px;

}
html {
  font-size: 62.5%;
}
html {

  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
*, *:before, *:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
*, *::before, *::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

thead, tbody, tfoot, tr, td, th {
  border-width: 1px;
}

table {
  margin-bottom:10px;
}

.u-spinner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition: all 0.35s ease;
  -o-transition: all 0.35s ease;
  transition: all 0.35s ease;
  visibility: hidden;
  opacity: 0;
  z-index: 99999999;
  &.open{
    visibility: visible;
    opacity: 1;

  }
   svg {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    fill: #ffffff;
    font-size: 12rem;
  }

  .svg-icon {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    overflow: hidden;
  }
}