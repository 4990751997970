/***** CONNEXION ******/
.template-account-choice{
  overflow:hidden;

  .template-login__logo {
    position: absolute;
    top: 36px;
    left: 35px;
    width: 232px;
  }
  .template-login__container {

    background-color: #ffffff;
    border-radius: 6px;
    -webkit-box-shadow: 0 0 35px 1px rgb(5 16 43 / 6%);
    box-shadow: 0 0 35px 1px rgb(5 16 43 / 6%);
    padding: 48px 42px;
    margin-top: 125px;
    min-width: 450px;
    margin-bottom: 125px;

    form[name="reset_password_request_form"],form[name="change_password_form"]{
      .errors{
        color: darkred;
        font-size: 14px;
        margin-bottom: 15px;
      }
      input{
        height: 52px;
        font-size: 1.7rem;
        color: #273d52;
        &::placeholder {
          text-align: center;
        }
        &::-ms-input-placeholder {
          text-align: center;
        }
        &::-ms-input-placeholder {
          text-align: center;
        }
      }
      small{
        font-size: 12px;
      }
      label{
        font-size: 1.4rem;
        color: black;
      }
      button{
        height: 40px;
        font-size: 15px;
      }
    }

    .custom-control-label,.template-login__container__form__forgotten-password{
      font-size: 14px;
    }
    label:not(.custom-control-label){
      display: block;
      font-size: 1.4rem;
      color: #939ea8;
      margin-bottom: 12px;
    }
    .template-login__container__form__title {
      display: block;
      font-size: 2.8rem;
      color: #273d52;
      margin-bottom: 32px;
    }
    .template-login__container__form__group{
      input{
        height: 52px;
        font-size: 1.7rem;
        color: #273d52;
      }
    }

    .template-login__container__form button {
      margin-top: 45px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      font-size: 1.7rem !important;
      height: 60px !important;
      width: 100%;
    }
  .template-login__container__form__forgotten-password{

  }
    .form-control {
      display: block;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      &:focus{
          color: #495057;
          background-color: #fff;
          border-color: #edebfe;
          outline: 0;
          -webkit-box-shadow: 0 0 0 0.2rem rgb(130 115 246 / 25%);
          box-shadow: 0 0 0 0.2rem rgb(130 115 246 / 25%);
      }
    }
  }


}

/*************** CHOICE ACCOUNT ***************/

.template-account-choice__container{
  text-align: center;
  background-color: #ffffff;
  border-radius: 6px;
  -webkit-box-shadow: 0 0 35px 1px rgb(5 16 43 / 6%);
  box-shadow: 0 0 35px 1px rgb(5 16 43 / 6%);
  padding: 53px 0;
  margin-top: 125px;

  .template-account-choice__container__title{
    font-size: 2.8rem;
    color: #273d52;
  }
  .c-account-choice-slider{
    position: relative;
  }
  .c-account-choice-slider__items{
    .c-account-choice-slider__item{
      text-decoration: none;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      &+.c-account-choice-slider__item{
        border-left: 1px solid #eef0f3;
      }


      .c-account-choice-slider__item__thumb-wrapper{
        position: relative;
        width: 130px;
        height: 130px;
        overflow: visible;
        .u-admin-clock {
          position: absolute;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -webkit-justify-content: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          top: -10px;
          right: 0;
          height: 46px;
          width: 46px;
          border-radius: 50%;
          color: white;
          font-size:15px;
          background-color: #423cb0;
        }

      }
      .c-account-choice-slider__item__thumb{
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        -o-object-fit: cover;
        object-fit: cover;
        &.no-image{
          font-size: 7rem;
          background-color: #8B77FB;
          color: black;
          &.girly{
            background-color: $pink;
            color: white;
          }
        }
      }
      .c-account-choice-slider__item__textual{
        .c-account-choice-slider__item__name{
          display: block;
          font-size: 1.7rem;
          font-weight: normal;
          color: #273d52;
        }
        .c-account-choice-slider__item__detail{
          display: block;
          color: #8996a2;
          font-size: 1.5rem;
          line-height: 2.3rem;
        }
        .c-account-choice-slider__item__class{
          display: block;
          color: #8996a2;
          font-size: 1.5rem;
          line-height: 2.3rem;
        }
      }
    }
    .slick-track{
      display:flex;
      flex-direction: row;
    }
  }
  .template-account-choice__container__add-student {
    margin-top: 60px;
    display: inline-block;
    text-align: center;
    font-size: 1.6rem;
    color: #273d52;
  }
}

.c-account-choice-slider__navigation{
  .button-prev,.button-next{
    color: #ffffff;
    border-radius: 6px;
    -webkit-box-shadow: 0 0 15px 1px rgb(5 16 43 / 11%);
    box-shadow: 0 0 15px 1px rgb(5 16 43 / 11%);
    background-color: #211e6a;
    position: absolute;
    top: 0;
    width: 57px;
    height: 164px;
    border: none;
    outline: none;
    will-change: opacity, background-color, padding;
    pointer-events: all;
    z-index: 2;
    i{
      font-size: 20px;
    }
  }
  .button-prev{
    left: -29px;

  }
  .button-next{
    right: -29px;
  }
}

/************* DASHBOARD *****************/

.template-dashboard{
  #template-dashboard-main{
    width: calc(100vw - 85px);
    height:calc((var(--vh, 1vh) * 100)  - 85px) ;
  }
  .menu-top{
    height: 85px;
    position: relative;
    z-index: 5;
  }
  .logo-menu-top{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
    width: 85px;
    background-color: #423cb0;
    border-bottom: solid 1px rgba(226, 226, 230, 0.18);
    pointer-events: all;
    &.bg-parents{
      background-color: $pink;
    }
  }
.menu__top__content{
  -webkit-box-shadow: 0 0 15px 1px rgb(5 16 43 / 17%);
  box-shadow: 0 0 15px 1px rgb(5 16 43 / 17%);
  z-index: 5;
  width: calc(100% - 85px);
}
  .menu__top__content__right{
    align-items: center;
    .notifications{
      padding: 13px 25px;
      border-radius: 26px;
      -webkit-transition: background-color 0.4s ease;
      -o-transition: background-color 0.4s ease;
      transition: background-color 0.4s ease;
      border: none;
      color: #ffffff;
      font-size: 1.6rem;
      margin-right: 25px;
      background-color: rgba(137, 150, 162, 0.7);
      &.new-notification{
        background-color: #e83279;
      }
    }
    .menu__top__content__right__account{
      padding: 19px 51px 19px 24px;
      height: 100%;
      border-left: 1px solid #eef0f3;
      cursor: pointer;
      .menu__top__content__right__account__textual {
        font-size: 1.6rem;
        line-height: 1.2;
        .account__name{
          color: #273d52;
        }
        .account__level{
          color: #8996a2;
        }
      }
      .fa-chevron-down{
        transition: transform 0.3s ease, -webkit-transform 0.3s ease;
        margin-left: 50px;
        display:inline;
        -webkit-transition: -webkit-transform 0.3s ease;
        transition: -webkit-transform 0.3s ease;
        -o-transition: transform 0.3s ease;
        transition: transform 0.3s ease, -webkit-transform 0.3s ease;
        font-size: 18px;
        height: 1em;
        margin-top: 12px;
      }
      &.active{
        .fa-chevron-down{
          -webkit-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          transform: rotate(180deg);

        }

      }

      .c-site-menu__top__content__right__account__thumb-wrapper--no-image {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-align-content: center;
        -ms-flex-line-pack: center;
        align-content: center;
        border-radius: 50%;
        color: #ffffff;
        background-color: #e83279;
      }
      .menu__top__content__right__account__thumb-wrapper {
        display: inline-block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 15px;
        overflow: hidden;
      }
      .menu__top__content__right__account__thumb-wrapper--no-image{
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-align-content: center;
        -ms-flex-line-pack: center;
        align-content: center;
        border-radius: 50%;
        color: #ffffff;
        background-color: #e83279;
        text-align: center;
        line-height: 49px;
        .menu__top__content__right__account__thumb-wrapper__letter {
          display: block;
          font-size: 2.2rem;
        }
      }
    }
    .menu__top__content__right__burger{
      justify-content: center;
      height: 100%;
      width: 70px;
      lign-items: center;
      -webkit-align-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-box-pack: center;
      .menu__top__content__right__burger__wrapper{
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        background: none;
        i{
          font-size: 20px;
        }
      }
    }


  }

  .menu__left {
    &:hover{
      width: 16em;
      .menu__left__item__tooltip{
        width: calc(100% - calc(14rem - 50px))!important;
        opacity: 1!important;
        color: white;
        margin-left: 74px;
      }
    }
    transition: width .35s cubic-bezier(.175, .685, .32, 1);
    position: fixed;
    width: 85px;
    background-color: #423cb0;
    pointer-events: all;
    // z-index: 50; casse popin
    z-index: 50;
    height: calc( 100vh - 85px);
    overflow-y:auto;
    .menu__left__item{

      a{
        transition: all .2s cubic-bezier(.175, .685, .32, 1);
        .menu__left__item__tooltip{
          text-overflow: ellipsis;
          white-space: nowrap;
          transition: all .35s cubic-bezier(.175, .685, .32, 1);
          overflow: hidden;
          opacity: 0;
          display: block;
          text-align: left;

        }
      }
    }
    &.bg-parents{
      background-color: $pink;
    }
    ul{
      li{
        padding-top: 30px;
        padding-bottom: 30px;
        text-align: center;
        opacity: 0.2;
        .c-site-menu__left__item__tooltip{
          display:none;
        }
        &:hover{
          opacity: 1;
        }
        &.active{
          opacity: 1;
        }
        a{
          white-space: nowrap;
          display:block;
          position:relative;
          text-decoration: none;
          i{
            color:white;
            font-size: 2.8rem;
            position: absolute;
            left:28px;
          }
        }
      }
    }
    .menu__left__contact{

    }
  }
}
.menu__playlist-popin{
  position: absolute;
  -webkit-transform: translate(0, -10px);
  -ms-transform: translate(0, -10px);
  transform: translate(0, -10px);
  pointer-events: none;
  z-index: 2;
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  &.open{
    pointer-events: all;
    opacity: 1;
  }
}
.menu__playlist-popin{

  border-radius: 6px 6px 0 0!important;
   .list{
     overflow-x: hidden;
     overflow-y: auto;
     height:100% ;
   }

  &.open{
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  background-color: #302B98;
  ul{
    li{
      padding-left: 10px;
      padding-right: 10px;
      &:hover{
        background-color: darken(#302B98,10);
      }
      a{
        .block-img{
          height: 49px;width: 69px;
        }
        span{
          width: calc(100% - 69px);
        }
        color:white;
      }
    }
  }
  .exit{
    cursor: pointer;
    background-color: $pink;
    color: white;
    border-radius:0 0 6px 6px!important;
    i{
      font-size: 30px;
      margin-right: 10px;
    }
    a{
      color:white;
      text-decoration: none;
    }
  }

}
.menu__notification-popin,.menu__profile-popin{
  position: absolute;
  -webkit-transform: translate(0, -10px);
  -ms-transform: translate(0, -10px);
  transform: translate(0, -10px);
  pointer-events: none;
  z-index: 999;
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  &.open{
    pointer-events: all;
    opacity: 1;
  }
}
.menu__notification-popin{
  top: 105px;
  right: 60px;
  background-color: #ffffff;
  width: 430px;
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
  &.open{
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .notifications-list{
    .item{
      &:hover{
        background-color: rgba(243, 244, 246, 0.5);
      }
      &+.item{
        border-top: 1px solid #eef0f3;
      }
      padding: 28px;
      .item-icon{
        width: 59px;
        height: 59px;
        border-radius: 50%;
        background-color: #8996a2;
        i{
          color:white;
          font-size: 2.1rem;
        }
      }
      &.item-new{
        .item-icon{
          background-color: #e83279;
        }
      }
      .item-content{
        padding-left: 21px;
        width: calc( 100% - 59px);
        color: #8996a2;
        font-size: 1.5rem;
        line-height: 2.3rem;
        a,strong{
          color: #273d52;
          text-decoration: none;
        }
      }
    }
  }
}
.menu__profile-popin{
  padding: 30px 0;
  top: 105px;
  right: 20px;
  width: 346px;
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;

  &.open{
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }

.menu__profile-popin-container{
  .name {
    display: block;
    font-size: 1.6rem;
    color: $color-black-blue;
  }
  a{
    text-decoration: none;
  }

  .level {
    display: block;
    font-size: 1.5rem;
    line-height: 2.3rem;
    color: $color-grey-text;
  }
  .thumb-wrapper{
    position: relative;
    width: 100px;
    height: 100px;
    margin: 0 auto 17px auto;
    overflow: visible;
    .thumb{
      border-radius: 50%;
      -o-object-fit: cover;
      object-fit: cover;

    }
  }
  .btn-modify-profile{
    padding: 11px 22px;
    display: inline-block;
    font-size: 1.5rem;
    margin: 22px auto 0px auto;
    position:relative;
    z-index: 5;
    &:before{
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      display: block;
      width: 250%;
      height: 1px;
      background-color: #eef0f3;
      pointer-events: none;
      z-index: 1;
    }
  }
  .accounts-others{
    padding: 32px 35px;
    text-align: left;
    .others__label {
      padding-bottom: 15px;
      display: block;
      color: #8996a2;
      font-size: 1.4rem;
    }
    .others-items{
      .thumb-wrapper{
        display: inline-block;
        width: 65px;
        height: 65px;
        overflow: visible;
        .u-admin-clock{
          height: 19px!important;
          width: 19px!important;
          top: 0px!important;
          font-size: 10px;
        }
        &.no-image{
          align-content: center;
          border-radius: 50%;
          color: #ffffff;
          background-color: #e83279;
            span:first-child{
              font-size: 3rem;
            }

        }
      }

      .name {
        display: block;
        font-size: 1.5rem;
        color: $color-black-blue;
      }
      .level {
        display: block;
        font-size: 1.5rem;
        line-height: 2.3rem;
        color: $color-grey-text;
      }
    }
  }

}
  .popin-bottom{
    border-top: 1px solid #eef0f3;
    padding: 24px 0 0 0;
    a{
      display: inline-block;
      color: #273d52;
      margin-left: 35px;
      font-size: 1.6rem;
    }
  }
}
.search-popin-css{
  position: absolute;
  top: 0;
  left: 85px;
  bottom: 0;
  background-color: #2f2a7c;
  width: 0;
  overflow: hidden;
  pointer-events: none;
  max-width: 1920px;
  margin: 0 auto;
  -webkit-transition: all 0.45s ease 0.45s;
  -o-transition: all 0.45s ease 0.45s;
  transition: all 0.45s ease 0.45s;
  z-index: 45;

   &.open{
     width: calc( 100% - 85px);
     -webkit-transition: all 0.35s ease 0s;
     -o-transition: all 0.35s ease 0s;
     transition: all 0.35s ease 0s;
     pointer-events: all;
     .search-popin__container form{
       opacity: 1;
       -webkit-transition: opacity 0.35s ease 0.3s;
       -o-transition: opacity 0.35s ease 0.3s;
       transition: opacity 0.35s ease 0.3s;
     }
     .popin__close{
       -webkit-transform: scale(1);
       -ms-transform: scale(1);
       transform: scale(1);
       -webkit-transition: background-color 0.35s ease 0s, -webkit-transform 0.45s ease 0.45s;
       -o-transition: transform 0.45s ease 0.45s, background-color 0.35s ease 0s;
     }
   }

  .popin__close{
    position: absolute;
    top: 45px;
    right: 35px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #8B77FB;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: background-color 0.35s ease 0s, -webkit-transform 0.45s ease 0s;
    transition: background-color 0.35s ease 0s, -webkit-transform 0.45s ease 0s;
    -o-transition: transform 0.45s ease 0s, background-color 0.35s ease 0s;
    transition: transform 0.45s ease 0s, background-color 0.35s ease 0s;
    transition: transform 0.45s ease 0s, background-color 0.35s ease 0s, -webkit-transform 0.45s ease 0s;
    cursor: pointer;
    &:hover{
      background-color: #765efa;
    }
    i{
      color: #ffffff;
      font-size: 2.8rem;

    }
  }
  .search-popin__container{
    padding: 100px 9% 0 9%;
    form{
      position: relative;
      opacity: 0;
      .c-site-menu__search-popin__form__input {
        padding-left: 60px;
        display: block;
        height: 110px;
        width: 100%;
        font-size: 1.7rem;
        color: #ffffff;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        -webkit-transition: all 0.35s ease;
        -o-transition: all 0.35s ease;
        transition: all 0.35s ease;
        &:active {
          border-bottom: 1px solid rgba(255, 255, 255, 0.6);
          outline: none;
          -webkit-box-shadow: none;
          box-shadow: none;
        }
        &:focus-visible{
          outline: none;
        }
      }
      .c-site-menu__search-popin__form__button {
        position: absolute;
        top: 50%;
        left: 0;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        border: none;
        outline: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        background: none;
        i{
          color:white;
          font-size: 3rem;
        }
      }
    }
  }
}
.contact-popin{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  -webkit-transition: opacity 0.45s ease 0.45s;
  -o-transition: opacity 0.45s ease 0.45s;
  transition: opacity 0.45s ease 0.45s;
  z-index: 9999;
  .popin__close{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #8B77FB;
    cursor: pointer;
    z-index: 10;
    position: absolute;
    top: 30px;
    right: 35px;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: background-color 0.35s ease 0s, -webkit-transform 0.45s ease 0s;
    transition: background-color 0.35s ease 0s, -webkit-transform 0.45s ease 0s;
    -o-transition: transform 0.45s ease 0s, background-color 0.35s ease 0s;
    transition: transform 0.45s ease 0s, background-color 0.35s ease 0s;
    transition: transform 0.45s ease 0s, background-color 0.35s ease 0s, -webkit-transform 0.45s ease 0s;
    i{
      color:white;
      font-size: 20px;
    }
  }
  &.open{
    opacity: 1;
    -webkit-transition: opacity 0.35s ease 0s;
    -o-transition: opacity 0.35s ease 0s;
    transition: opacity 0.35s ease 0s;
    .popin__close{
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
      -webkit-transition: background-color 0.35s ease 0s, -webkit-transform 0.45s ease 0.4s;
      transition: background-color 0.35s ease 0s, -webkit-transform 0.45s ease 0.4s;
      -o-transition: transform 0.45s ease 0.4s, background-color 0.35s ease 0s;
      transition: transform 0.45s ease 0.4s, background-color 0.35s ease 0s;
      transition: transform 0.45s ease 0.4s, background-color 0.35s ease 0s, -webkit-transform 0.45s ease 0.4s;
      pointer-events: all;
    }
    .contact-popin-container{
      width: 875px;
      -webkit-transition: width 0.35s ease 0.15s;
      -o-transition: width 0.35s ease 0.15s;
      transition: width 0.35s ease 0.15s;
      pointer-events: all;
      .contact-popin-form{
        opacity: 1;
        -webkit-transition: opacity 0.4s ease 0.65s;
        -o-transition: opacity 0.4s ease 0.65s;
        transition: opacity 0.4s ease 0.65s;
      }
    }
  }
  .contact-popin-container{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 0px;
    overflow-y: auto;
    background-color: #ffffff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: width 0.55s ease 0.15s;
    -o-transition: width 0.55s ease 0.15s;
    transition: width 0.55s ease 0.15s;
    .contact-popin-form{
      padding: 64px 100px;
      position: relative;
      opacity: 0;
      -webkit-transition: opacity 0.35s ease 0s;
      -o-transition: opacity 0.35s ease 0s;
      transition: opacity 0.35s ease 0s;
      .form-title {
        padding-bottom: 30px;
        color: #273d52;
        font-size: 2.8rem;
        margin: 0;
      }
      .form-description {
        padding-bottom: 44px;
        color: #8996a2;
        font-size: 1.6rem;
        line-height: 2.7rem;
      }
      .form-row{
        width: 100%;
        margin-bottom: 39px;
        .form-input{
          width: 45%;
          .form-input-text{
            padding: 10px 13px;
            font-size: 1.5rem;
            border-radius: 6px;
            border: 1px solid rgba(137, 150, 162, 0.3);
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background: transparent;
            color: #273d52;
            -webkit-transition: border-color 0.35s ease;
            -o-transition: border-color 0.35s ease;
            transition: border-color 0.35s ease;
            resize: none;
          }
          .form-input-label{
            display: block;
            margin-bottom: 14px;
            font-size: 1.4rem;
            color: #939ea8;
          }
          .form-input-select{

            .fa-chevron-down{
              position: absolute;
              top: 50%;
              right: 15px;
              font-size: 1rem;
              height: 1em;
            }
          }
        }
      }
      .form-but-validate{
        button{
          font-size: 1.5rem;
          line-height: 1;
          text-align: center;
          border-radius: 30px;
          -webkit-box-shadow: 0 0 15px 1px rgb(5 16 43 / 11%), 0 7px 10px 0 rgb(39 61 82 / 13%);
          box-shadow: 0 0 15px 1px rgb(5 16 43 / 11%), 0 7px 10px 0 rgb(39 61 82 / 13%);
          padding: 2px 60px 1px 60px;
          height: 60px;
          z-index: 2;
        }

      }
    }
  }
}

/**************** FIN ***********/

/************* TEmplate children *************/

.change-account-children{
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 15px 1px rgb(5 16 43 / 17%);
  box-shadow: 0 0 15px 1px rgb(5 16 43 / 17%);
  z-index: 2;
  position:relative;
  .u-children-toggle__label {
    font-size: 1.5rem !important;
    line-height: 2.5rem !important;
  }
}
.mobile-profile-thumb-wrapper{
  height: 45px;
  width: 45px;
}