@media (max-width: 767.98px) {

  .latest-activities{
    height: 100%;
    overflow-y: auto;
  }
  .template-stage-single__form__step__subjects .template-stage-single__form__step__subjects__list .template-stage-single__form__step__subjects__item {
    width: 100%;
  }
.contact-popin-form{
  padding: 55px 21px!important;
}

}
@media (max-width: 1279.98px){
  .template-stages__card{
    width: calc( 50% - 21px);
  }
}
@media (max-width: 1023.98px){
  .menu__playlist-popin {

    width: 100%;
    height: 50vh;
    border-radius: 0!important;
    top:0;
  }
  .template-login__logo {
    top: 0px!important;
  }

  .block-main {
    height: auto!important;
  }
  .template-dashboard .menu__left {
    display:none;
  }
  .template-stage-single__resume__head__image {
    position: absolute;
    right: 29px;
    bottom: 58px;
    width: 85px;
  }
  /**** PLaylist *****************/
  .js-template-popin{
    &#js-menu-program-summary-mobile{
      &.open{
        position: absolute;
        top: 70px;
        width: 100%;
        color: black;
        left: 0;
        z-index: 30;
        display:block!important;
        .list-programs-container-items{
          .program-summary-container-items {

            height: calc((var(--vh, 1vh) * 100) - 130px);
          }
        }
      }
    }
  }
  .program-summary-header-mobile{
    display:flex;
  }
  .program-summary-header-desktop{
    display:none!important;
  }
  .main-program{
    width: 100%!important;
    height: auto;
    .list-programs-container-items{
      display:none;
    }
    #template-media-container {
      width: 100%;
      height: 100%;
      overflow-y:hidden;

    }
  }
  .template-dashboard #template-dashboard-main {
    width: 100%;
    height: 100%;
  }
  .display-mobile-next-step{
    display:block;
  }




  /**** fin ****/


  .contact-popin.open .contact-popin-container {
  width: 100%;
  }
  .search-popin-css.open{
    width: 100%!important;
    left:0px!important;
  }
  .menu-mobile{
    border-radius: 0px;
    background-color: #302b98;
    width: 0;
    overflow: hidden;
    visibility: visible;
    -webkit-transition: all 0.45s ease 0.25s;
    -o-transition: all 0.45s ease 0.25s;
    transition: all 0.45s ease 0.25s;
    z-index: 10;
    color: white;
    &.open{
      width: 100%;
      -webkit-transition: all 0.35s ease 0s;
      -o-transition: all 0.35s ease 0s;
      transition: all 0.35s ease 0s;
      pointer-events: all!important;
      opacity: 1;
    }
    .mobile-container{
      position: absolute;
      top: 70px;
      left: 0;
      right: 0;
      bottom: 0;
      overflow-y: auto;
      -webkit-transition: opacity 0.35s ease 0s;
      -o-transition: opacity 0.35s ease 0s;
      transition: opacity 0.35s ease 0s;
      z-index: 5;
      .mobile-items{
        padding: 0px 33px 0 33px;
        list-style: none;
        margin-bottom: 10px;
        li{
          position: relative;
          color: rgba(255, 255, 255, 0.6);
          border-bottom: 1px solid rgba(255, 255, 255, 0.11);
          cursor: pointer;
          opacity: 0.6;
          &.active{
            opacity: 1;
          }
          a{
            padding: 30px 0;
            text-decoration: none;
            color: white;
            .icone{
              font-size: 2.4rem;
              width: 1em;
              height: 1em;
            }
            .mobile-item__label{
              font-size: 1.6rem;
              margin-left: 25px;
            }
          }
        }
      }
      .menu-mobile-profile{
        padding: 19px 26px 25px 26px;
        position: relative;
        color: #ffffff;
        border-bottom: 1px solid rgba(255, 255, 255, 0.11);
        cursor: pointer;
        .mobile-profile-thumb-wrapper{
          width: 40px;
          height: 40px;
          border-radius: 50%;
          overflow: hidden;
          img{
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
          }
        }
        .profile-name{
          margin-left: 15px;
          font-size: 16px;
        }
        .fa-chevron-right{
          position: absolute;
          top: 50%;
          right: 30px;
          font-size: 1.4rem;
        }
      }
    }


  }
  .template-stage-single__container {
    padding: 20px 20px 90px 20px;
    display: block;
  }
  .template-stage-single__col {
    width: 100%;
    margin-bottom: 10px;
  }
  .template-login__logo {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 209px;
    margin: 45px auto 38px auto;
  }

  .menu__profile-popin,.menu__notification-popin {
    padding: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0px;
    width: 100%;
    max-height: 100%;
  }
  .menu__profile-popin.open{

  }
  #block-left{
    overflow-y:hidden;
    height: auto;
  }
  .u-dashboard-card {
    width: 100%;
    margin-bottom: 11px;
    a{
      padding: 5px 14px 6px 14px;
      height: 42px;
      font-size: 1.4rem;
    }
  }

  .menu-list-programs{
    left: 0;
    width: 100%;
  }

  .list-programs-col {
    top: 70px;
    left: 0;
    width: 100%;
    -webkit-transform: translate(100%, 0);
    -ms-transform: translate(100%, 0);
    transform: translate(100%, 0);
    background-color: #302b98;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
    z-index: 20;
    &.active,&.open{
      -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    &.col-second {
      z-index: 25;
      width: 100%;
    }
    &.col-third{
      width: 100%;
      z-index: 30;
      .item-thumb-wrapper{
        width: 88px;
        height: 62px;
      }
      .item-content{
        padding-left: 20px;
        width: calc( 100% - 88px);
        .item-title{
          font-size: 1.5rem;
          line-height: 2.3rem;
        }
        .item-details{
          margin-top: 6px;
          font-size: 1.4rem;
          .details-progress {
            width: 60px;
          }
        }
      }
    }
    &.col-third,&.col-second{
      .programs-item{
        padding: 23px 25px;
      }
    }
  }
  .template-stages__container{
    .template-stages__card{
      width: 100%;
      margin: 0 0 10px 0;
    }
    .row{
      padding: 21px;
      margin: 0;
    }
    .img-training{
      width: 30%;
      max-width: 140px;
    }

  }
  .u-dashboard-card {
    padding: 33px 23px;
  }

  /***** Search page *******/

.template-search-container{
  .search-filters{
    padding-left: 0px;
    padding-right: 0px;
    height: 70px;
    .template-search__filters__label,.template-search__filters__select,.template-search__filters__label,button:not(.js-open-search-filters){
      display:none!important;
    }
    .query-search{
      padding-left: 28px;
      width: calc( 100% - 70px);
      font-size: 1.6rem;
      margin-right: 0;
    }
    .filters__mobile-toggle{
      display:flex;
    }
  }
  .template-search__result__class {
    padding: 0px!important;
    font-size: 1.3rem;
    border:none!important;
    .template-search__result__class__content {
      padding: 8px 14px!important;
    }

  }
  .template-search__result__icon {
    width: 66px!important;
  }
}

/********** REport **********/
  .template-assessments-container {
    height: calc((var(--vh, 1vh) * 100) - 74px - 42px - 85px);
  }


  .template-dashboard-parents.latest-activities {
    height: calc( (var(--vh, 1vh) * 100) - 85px - 74px);
  }
}
@media(min-width:1023.99px){
  .menu__playlist-popin {
    left: 7px;
    width: 346px;
    height: 50vh;
    top:4px;
  }
  .template-search-container{
    .search-filters{
        button.js-open-search-filters{
        display:none!important;
      }}}
  .template-dashboard #template-dashboard-main{
    margin-left: 85px;
  }
}
@media (max-width: 1260px){
  .statistics-items{
    .item-chart,.statistics-item-content{
      width: 100%!important;
    }

  }
}
@media (max-width: 426px){

  #nav-invoice{
    table{
      .m-hide{
        display:none;
      }
    }
  }
  .contact-popin .contact-popin-container .contact-popin-form .form-row .form-input {
    width: 100%!important;
  }
  .result-content-right {
    position:absolute;
    right:0px;
  }
  /*************** CHOICE ACCOUNT ***************/
.template-account-choice__container{
  padding-top: 34px!important;
  padding-bottom: 34px!important;

  .template-account-choice__container__add-student{
    margin-top: 19px!important;
  }
}
  .template-account-choice__container__title{
    font-size: 1.8rem!important;
  }
.c-account-choice-slider__items {
  overflow-y:auto;
  height: 300px;
  border-top: 1px solid #eef0f3;
  border-bottom: 1px solid #eef0f3;
  .c-account-choice-slider__item {
    padding-top: 20px;
    padding-bottom: 20px;
    border-right: none !important;

    .u-admin-clock {
      top: 0 !important;
      right: -10px !important;
      height: 29px !important;
      width: 29px !important;
    }

    & + .c-account-choice-slider__item {
      border-top: 1px solid #eef0f3;

    }

    .c-account-choice-slider__item__thumb-wrapper {
      width: 77px !important;
      height: 77px !important;
    }
  }
}

  #block-left .dashboard-news a {
    position:unset;
  }
  /************ Dash Home ***************/
  .activity-list a.list-item {
    padding: 28px 0px;
    .list-item-details .details-progress {
      width: 69px;
    }
  }
  /************   FIN ******************/

  /************  Playlist *************/
  .template-finish #content {
    padding-left: 0!important;
  }
  .template-finish {
    padding: 52px 4%;
  }
  /************   FIN ******************/
}

/********* Class Boostrap ***********/

@media (max-width: 991px){
  #block-right{

    height: auto;
  }
  .template-stage-single__col--left {
    width: 100%;
  }

}
@media (min-width: 992px){
  .template-stage-single__col--left {
    width: 42%;
  }
}