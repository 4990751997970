.template-assessments-container{
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 6px;
  margin-top: 21px;
  margin-bottom: 21px;
  height: calc(100% - 74px - 42px);
  .assessments-container{
    -webkit-box-shadow: 0 0 35px 1px rgb(5 16 43 / 6%);
    box-shadow: 0 0 35px 1px rgb(5 16 43 / 6%);
    overflow-y:auto;
    height: 100%;
    ul{
      li{
        padding: 29px 39px;
        width: 100%;
        .item-label {
          display: inline-block;
          width: 145px;
          font-size: 1.6rem;
          color: #273d52;
          margin-right: 40px;
        }
        &+li{
          border-top: 1px solid #eef0f3;
        }

      }
    }
  }
}