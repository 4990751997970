.c-activities-list {
    border-radius: 6px;
    height: 100%;
    overflow-y: auto;
    li {
        & + li {
            border-top: 1px solid #eef0f3;
        }
        a {
            text-decoration: none;
            height: auto;
            padding: 28px 0;
            &:hover {
                background-color: rgba(243, 244, 246, 0.5);
                text-decoration: none;
                .item-thumb-wrapper-play {
                    .u-icon-play {
                        display: flex !important;
                    }
                }
            }
            .item-thumb-wrapper-play {
                margin-left: 21px;
                width: 119px;
                height: 88px;
                .poster {
                    object-fit: cover;
                    border-radius: 15px;
                }
            }

            .list-item-title {
                color: #273d52;
                font-size: 1.6rem;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .list-item-description {
                display: block;
                color: #8996a2;
                font-size: 1.5rem;
                white-space: nowrap;
                margin-bottom: 8px;
            }
            .details-date {
                color: #8996a2;
                font-size: 1.4rem;
                margin-right: 14px;
            }
            .details-progress {
                width: 97px;
                margin-right: 12px;
                margin-top: 10px;
                height: 5px;
                .progress-bar {
                    background-color: #8b77fb;
                }
            }
            .details-percent {
                color: #8b77fb;
                margin-top: 6px;
            }
            .details-completed {
                color: #27c281;
                font-size: 1.4rem;
            }
        }
    }
}
.latest-activities {
    height: 100%;
    padding: 14px;
    overflow-y: auto;
}
.template-dashboard-parents {
    &.latest-activities {
        height: calc(100% - 74px);
    }
}

.last-activities-scores {
    color: #273d52;
}
