
/*=============================================
=             Colours                         =
=============================================*/

$grey:            #f4f4f4;
$purple:          #8273f6;
$pink:            #e83279;
$color-black-blue: #273d52;
$color-grey-text: #8996a2;
$bg-training-1: #423cb0;
$bg-training-2: #273d52;


$fa-font-path: "/build/assets/fonts";


  /************ layout ***********************/

$background-nav:  $grey;