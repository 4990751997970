.modal-header {
  border-radius: 0 !important;
}

.blocker {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 99;
  padding: 20px;
  box-sizing: border-box;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  text-align: center
}

.blocker:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.05em
}

.blocker.behind {
  background-color: transparent
}

.modalcustom {
  display: none;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  max-width: 600px;
  box-sizing: border-box;
  width: 90%;
  background: #fff;
  padding: 30px 30px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 0 10px #000;
  -moz-box-shadow: 0 0 10px #000;
  -o-box-shadow: 0 0 10px #000;
  -ms-box-shadow: 0 0 10px #000;
  box-shadow: 0 0 10px #000;
  text-align: left;
  .content{
    color: black;
    font-size: 1.6rem;
  }
  &.template-popin-account{
    padding: 0!important;
    border-radius: 0px;
    header{
      padding: 30px;
      background-color: #8273f6;
      color:white;
    }
    .content{
      padding: 20px 30px;
      .but-submit{
        margin-top: 40px;
        padding: 0px 20px 0px 20px;
        height: 35px;
        font-size: 15px;
        min-width: 50%;
        margin: 0 auto;
      }
    }
  }
  &#popin-subscription{
    .content{

    }
  }
  &#popin-menu{
    a{
      text-decoration: none;
      height: 35px;
      padding-top:5px;
      padding-bottom:5px;
      margin-bottom: 10px;
      font-size: 16px;
    }
    .menu-content{
      display:none;
      &#reactivte-sub{
        h4{
          color: black;
          font-size: 18px;
        }
        .line-offer{
          &+.line-offer{
            border-top: 1px solid #e3e4e4;
          }

          padding-bottom: 20px;
          padding-top: 20px;

          .title-offer{
            color:black;
          }
          .grab{
            font-size: 14px;
          }
          button{
            height: 40px;
            padding-left: 15px;
            padding-right: 15px;
            font-size: 16px;
          }
        }

      }
    }
  }

  &#popin-options{
    img{
      object-fit: cover;
    }
    button{
      height: 40px;
      padding-left: 15px;
      padding-right: 15px;
      font-size: 16px;
    }
    li{
      margin-bottom: 5px;
    }
  }
  &#popin-add-new-Subchild{
    h3{
      color:black;
    }
    input[type="submit"]{
      width: 60%;
      height: 40px;
      font-size: 20px;
    }
  }
  #block-offer,#block-display-offer, #block-display-offer-essai{
    .button-o{
      //width: 96px; height: 39px;
      font-size: 12px!important; padding-left: 10px; padding-right: 10px;
      margin-left: auto;
    }
    .line-offer{
      margin-bottom:15px;
    }
    .choice-offer{
      div.block{
        width:calc(50% - 15px);
        background-color: #f7f7f7;
        padding: 10px;
        h4{
          margin-bottom: -2px;
        }
        small{
          font-size: 12px;
          display:block;
        }
        .price{
          color:$pink;
          font-weight: 400;
          display:block;
        }
        .detail{
          li{
            font-size:14px;
            i{
              margin-right: 5px;
            }
          }
        }
      }
    }
    .list-offers{
      display:none;
      .line-offer{
        display:block;
      }
      &.display-visio{
        display:block;
        .line-offer.offer-visio{
          display:flex;
        }
        .line-offer:not(.offer-visio){
          display:none;
        }
      }
      &.display-essentielle{
        display:block;
        .line-offer.offer-visio{
          display:none;
        }
        .line-offer:not(.offer-visio){
          display:flex;
        }
      }
    }
    .list-offers-essai{
      display:none;
      .line-offer-essai{
        display:block;
      }
      &.display-visio-essai{
        display:block;
        .line-offer-essai.offer-visio-essai{
          display:flex;
        }
        .line-offer-essai:not(.offer-visio-essai){
          display:none;
        }
      }
      &.display-essentielle-essai{
        display:block;
        .line-offer-essai.offer-visio-essai{
          display:none;
        }
        .line-offer-essai:not(.offer-visio-essai){
          display:flex;
        }
      }
    }
  }
  #reduce-toto{
    border-top: 1px solid #8996a2;
    padding-top: 20px;
    #price-reduce-toto{
      color:#e83279;
      font-size: 25px;
    }
  }
}

@media (max-width: 766px) {
  #block-offer,#block-display-offer, #block-display-offer-essai{
    .choice-offer{
      div{
        width:100%!important;}
    }
  }
}

.modalcustom a.close-modalcustom {
  position: absolute;
  top: -12.5px;
  right: -12.5px;
  display: block;
  width: 30px;
  height: 30px;
  text-indent: -9999px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAAA3hJREFUaAXlm8+K00Acx7MiCIJH/yw+gA9g25O49SL4AO3Bp1jw5NvktC+wF88qevK4BU97EmzxUBCEolK/n5gp3W6TTJPfpNPNF37MNsl85/vN/DaTmU6PknC4K+pniqeKJ3k8UnkvDxXJzzy+q/yaxxeVHxW/FNHjgRSeKt4rFoplzaAuHHDBGR2eS9G54reirsmienDCTRt7xwsp+KAoEmt9nLaGitZxrBbPFNaGfPloGw2t4JVamSt8xYW6Dg1oCYo3Yv+rCGViV160oMkcd8SYKnYV1Nb1aEOjCe6L5ZOiLfF120EjWhuBu3YIZt1NQmujnk5F4MgOpURzLfAwOBSTmzp3fpDxuI/pabxpqOoz2r2HLAb0GMbZKlNV5/Hg9XJypguryA7lPF5KMdTZQzHjqxNPhWhzIuAruOl1eNqKEx1tSh5rfbxdw7mOxCq4qS68ZTjKS1YVvilu559vWvFHhh4rZrdyZ69Vmpgdj8fJbDZLJpNJ0uv1cnr/gjrUhQMuI+ANjyuwftQ0bbL6Erp0mM/ny8Fg4M3LtdRxgMtKl3jwmIHVxYXChFy94/Rmpa/pTbNUhstKV+4Rr8lLQ9KlUvJKLyG8yvQ2s9SBy1Jb7jV5a0yapfF6apaZLjLLcWtd4sNrmJUMHyM+1xibTjH82Zh01TNlhsrOhdKTe00uAzZQmN6+KW+sDa/JD2PSVQ873m29yf+1Q9VDzfEYlHi1G5LKBBWZbtEsHbFwb1oYDwr1ZiF/2bnCSg1OBE/pfr9/bWx26UxJL3ONPISOLKUvQza0LZUxSKyjpdTGa/vDEr25rddbMM0Q3O6Lx3rqFvU+x6UrRKQY7tyrZecmD9FODy8uLizTmilwNj0kraNcAJhOp5aGVwsAGD5VmJBrWWbJSgWT9zrzWepQF47RaGSiKfeGx6Szi3gzmX/HHbihwBser4B9UJYpFBNX4R6vTn3VQnez0SymnrHQMsRYGTr1dSk34ljRqS/EMd2pLQ8YBp3a1PLfcqCpo8gtHkZFHKkTX6fs3MY0blKnth66rKCnU0VRGu37ONrQaA4eZDFtWAu2fXj9zjFkxTBOo8F7t926gTp/83Kyzzcy2kZD6xiqxTYnHLRFm3vHiRSwNSjkz3hoIzo8lCKWUlg/YtGs7tObunDAZfpDLbfEI15zsEIY3U/x/gHHc/G1zltnAgAAAABJRU5ErkJggg==')
}

.modalcustom-spinner {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  padding: 12px 16px;
  border-radius: 5px;
  background-color: #111;
  height: 20px
}

.modalcustom-spinner > div {
  border-radius: 100px;
  background-color: #fff;
  height: 20px;
  width: 2px;
  margin: 0 1px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out
}

.modalcustom-spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s
}

.modalcustom-spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s
}

.modalcustom-spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.5)
  }
  20% {
    -webkit-transform: scaleY(1.0)
  }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.5);
    -webkit-transform: scaleY(0.5)
  }
  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0)
  }
}

#show-dialog{
 h4{
   font-size: 20px;
   color: $purple;
   font-weight: bold;
   i{
     color: $purple;
   }
 }
  .flash-notice{
    font-size: 16px;
  }
}

//new css

body{
  position: relative;
  .end-essai-gratuit-bandeau{
    width: 100%; height: 100vh;
    background-color: rgba(0,0,0, 0.75);
    position: absolute; left: 0;top: 0;
    z-index: 10;
    display: flex; align-items: center; justify-content: center;
  }
}
.w-45{width: 45%;}
.fs12 {font-size: 12px;}


#children-checkboxes{
  .checkbox-container {
    display: inline-block;
    + .checkbox-container{margin-left: 8px;}
  }
  label{margin-left: 4px;}
}

.popin-end-essai{
  p{
  color: #ffffff;
  }
}

.code-promo-wrapper{
  display: flex;
  flex-wrap: nowrap;
  margin: 12px 0;
  input{
    padding: 12px;
  }
}

.list-offers-essai .row{
    +.row{
    margin-top: 24px;
  }
}


.essai-gratuit-checkout {
  .paiement-methods-wrapper{text-align: center; margin: 8px 0;}
  .formulaire-eleve input{
    border: 0; max-width: 100%;
  }
  #form-payment-tunnel .cardForm{
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .select-parent-form {
    margin: 0;
    box-sizing: inherit;
    border: none;
    width: 100%;

    font-size: 15px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #ffffff!important;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 0px;
  }

  .block-form .select-parent-form{
    height: 44px;
  }
  #checkout-paiement{
    background-image: url("/public/build/wave/bg-yellow.svg");
    color:white;
    border-radius: 1.5rem !important;
    #recp-toto{margin-bottom: 20px;}
    #recp-toto span{font-weight: bold; font-size: 30px;}
    .bg-white{
      color: black;
      font-size: 14px;
      line-height:20px;
    }
  }
}
