.block-user-access-content{
  .container-access-content{
    background-color: white;
    padding: 30px;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.36);
    border:4px solid $color-black-blue;
    .block-user{
      font-size: 45px;
      font-weight: bold;
      line-height: 50px;
      margin-bottom: 40px;
    }
  }

}
@media(min-width:1023.99px){
  .block-user-access-content{
    padding: 5%;
  }
}