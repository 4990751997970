@font-face {
  font-family: "Rubik";
  src: url("/assets/fonts/Rubik-Regular.ttf") format("ttf");
}
@font-face {
  font-family: "Rubik-Bold";
  src: url("/assets/fonts/Rubik-Bold.ttf") format("ttf");
}

@font-face {
  font-family: "NanumBrushScript";
  src: url("/assets/fonts/NanumBrushScript-Regular.ttf") format("ttf");
}