.template-stages__card {
  width: calc( 100% / 3 - 21px);
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  margin-right: 21px;
  margin-bottom: 21px;
  &:nth-child(3n-2) {
    background: #8B77FB;
  }
  &:nth-child(2n) {
    background: $bg-training-1;
  }
  &:nth-child(3n) {
    background: $bg-training-2;
  }
}

.template-stages__container{
  overflow-y: auto;
  overflow-x: hidden;
  height: calc( 100vh - 85px);

  .row {
    padding-top: 21px;
    padding-left: 21px;
  }
  .u-dashboard-card__textual {
    position: relative;
    width: 65%;
    z-index: 2;
  }

  .u-dashboard-card__title {
    padding-bottom: 20px;
    display: block;
    text-transform: uppercase;
    font-size: 1.5rem;
    color: #ffffff;
    margin-bottom: 0;
  }
  .u-dashboard-card__content {
    padding-bottom: 28px;
    display: block;
    font-size: 1.5rem;
    line-height: 2.3rem;
    color: rgba(255, 255, 255, 0.7);
  }
  .u-dashboard-card img {
    position: absolute;
    right: 25px;
    bottom: 35px;
    z-index: 1;
  }

}
.template-stage-single__container{
  padding: 25px 27px;
  overflow-y: auto;
  //overflow-x: hidden;
  height: calc( 100vh - 85px);
}

.template-stage-single__resume {
  background-color: #ffffff;
}

.template-stage-single__col--left {
  margin-right: 18px;
}

.template-stage-single__col--right {
  width: 60%;
}
.template-stage-single__col {
  border-radius: 6px;
  overflow: hidden;
}
.template-stage-single__resume__head {
  padding: 42px;
  position: relative;
  color: #ffffff;
  background-color: #423cb0;
}
.template-stage-single__resume__head__title {
  padding-bottom: 15px;
  display: block;
  font-size: 1.5rem;
  text-transform: uppercase;
  width:70%;
}

.template-stage-single__resume__head__description {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.5rem;
  line-height: 2.3rem;
}

.template-stage-single__resume__head__image {
  position: absolute;
  right: 29px;
  bottom: 20px;
  cursor: pointer;
  width: 116px;
}
.block-picture{
  position: absolute;
  right: 29px;
  bottom: 20px;
  width: 134px;
  height: 100px;
  cursor: pointer;
  &:hover{
    i{
      font-size:16px ;
    }
  }
  i{
    top: 0;
    bottom: 0;
    left:0;
    right:0;
    margin: auto;
    width: 10px;
    height: 20px;
    font-size: 14px;
  }
}

.template-stage-single__resume__content {
  padding: 36px 45px;
  color: #617180;
  font-size: 1.6rem;
  line-height: 2.4rem;
  ol{
    & > li {
      counter-increment: item;
      margin-bottom: 15px;
      list-style: none;
      position: relative;
      ul{
        padding-left: 17px;
        list-style: disc;
      }
    }
    & > li:before {
      margin-right: 10px;
      content: counter(item);
      background: #E83279;
      border-radius: 50%;
      color: white;
      width: 1.5em;
      text-align: center;
      display: inline-block;
      height: 1.5em;
      position: absolute;
      left:-33px;
    }
  }
}

.template-stage-single__col--right {
  width: 58%;
}

.template-stage-single__col {
  border-radius: 6px;
  overflow: hidden;
}

.template-stage-single__form__step {
  background-color: #ffffff;
}
.template-stage-single__form__step__head{
  padding: 23px 31px;
  width: 100%;
  border-bottom: 1px solid #eef0f3;
}

.template-stage-single__form__step__head__label {
  display: inline-block;
  color: #273d52;
  text-transform: uppercase;
  font-size: 1.5rem;
  margin-right: 34px;
}

.template-stage-single__form__step__head__select {
  min-width: 200px;
  i{
    position: absolute;
    top: 50%;
    right: 15px;
    font-size: 1rem;
  }
}

.u-select {
  position: relative;
  .u-select__input {
    padding: 10px 35px 10px 13px;
    font-size: 1.5rem;
    border-radius: 6px;
    border: 1px solid rgba(137, 150, 162, 0.3);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    color: #273d52;
    width: 100%;
    -webkit-transition: border-color 0.35s ease;
    -o-transition: border-color 0.35s ease;
    transition: border-color 0.35s ease;
    cursor: pointer;
  }
}

.template-stage-single__form__step__subjects{
  padding: 23px 31px;
  .template-stage-single__form__step__subjects__label {
    display: inline-block;
    color: #273d52;
    text-transform: uppercase;
    font-size: 1.5rem;
    margin-bottom: 30px;
  }
  .template-stage-single__form__step__subjects__list {
    padding: 0;
    margin-bottom: 15px;
    .template-stage-single__form__step__subjects__item {
      padding: 24px 27px;
      height: 74px;
      width: calc( 100% / 3 - 8px);
      font-size: 1.5rem;
      line-height: 2.1rem;
      border-radius: 6px;
      -webkit-box-shadow: 0 10px 20px 1px rgb(5 16 43 / 15%);
      box-shadow: 0 10px 20px 1px rgb(5 16 43 / 15%);
      margin-right: 8px;
      margin-bottom: 8px;
      -webkit-transition: background-color 0.35s ease, color 0.35s ease;
      -o-transition: background-color 0.35s ease, color 0.35s ease;
      transition: background-color 0.35s ease, color 0.35s ease;
      cursor: pointer;

      &:hover{
        color: #ffffff;
        background-color: #27c281;
      }
      &.active{
        color: #ffffff;
        background-color: #27c281;
      }
    }
  }
  .template-stage-single__form__step__subjects__actions{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
button,a{
  padding: 0px 20px 1px 20px;
  height: 46px;
  font-size: 1.5rem;
  line-height: 1;
  text-align: center;
  border-radius: 30px;
  -webkit-box-shadow: 0 0 15px 1px rgb(5 16 43 / 11%), 0 7px 10px 0 rgb(39 61 82 / 13%);
  box-shadow: 0 0 15px 1px rgb(5 16 43 / 11%), 0 7px 10px 0 rgb(39 61 82 / 13%);
}
}
}

.template-stage-single__form__step__head__message {
  font-size: 1.5rem;
  line-height: 2.4rem;
  color: #617180;
  max-width: 50%;
}
.template-stage-single__form__step__head__btn{
  height: 46px;
  font-size: 1.5rem;
  line-height: 1;
  text-align: center;
  border-radius: 30px;
  -webkit-box-shadow: 0 0 15px 1px rgb(5 16 43 / 11%), 0 7px 10px 0 rgb(39 61 82 / 13%);
  box-shadow: 0 0 15px 1px rgb(5 16 43 / 11%), 0 7px 10px 0 rgb(39 61 82 / 13%);
  padding: 0px 20px 1px 20px;
}

.template-stage-single__form__step__days {
  padding: 31px 31px 41px 31px;
  .template-stage-single__form__step__day {
    background-color: #ffffff;
    border-radius: 6px;
    -webkit-box-shadow: 0 10px 20px 1px rgb(5 16 43 / 15%);
    box-shadow: 0 10px 20px 1px rgb(5 16 43 / 15%);
    margin-bottom: 12px;
  }
  .template-stage-single__form__step__day__head {
    padding: 25px 27px;
    position: relative;
    color: #273d52;
    border-bottom: 1px solid #eef0f3;
    cursor: pointer;
    .template-stage-single__form__step__day__head__label {
      text-transform: uppercase;
      font-size: 1.5rem;
    }
    i{
      position: absolute;
      top: 50%;
      right: 35px;
      font-size: 1.5rem;
      -webkit-transition: -webkit-transform 0.4s ease;
      transition: -webkit-transform 0.4s ease;
      -o-transition: transform 0.4s ease;
      transition: transform 0.4s ease, -webkit-transform 0.4s ease;
      pointer-events: none;
      height: 1em;
    }
  }
}
.template-stage-single__form__step__day__content__row {
  padding: 20px 27px;
  border-bottom: 1px solid #eef0f3;

  .template-stage-single__form__step__day__content__left{
    .template-stage-single__form__step__day__content__subject {
      display: block;
      color: #273d52;
      font-size: 1.5rem;
      line-height: 2.3rem;
    }

    .template-stage-single__form__step__day__content__details {
      color: #617180;
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }
  .template-stage-single__form__step__day__content__btn{
    padding: 0px 20px 1px 20px;
    height: 46px;
    font-size: 1.5rem;
    line-height: 1;
    text-align: center;
    border-radius: 30px;
    -webkit-box-shadow: 0 0 15px 1px rgb(5 16 43 / 11%), 0 7px 10px 0 rgb(39 61 82 / 13%);
    box-shadow: 0 0 15px 1px rgb(5 16 43 / 11%), 0 7px 10px 0 rgb(39 61 82 / 13%);
  }
  .template-stage-single__form__step__day__content__select{
    i{
      position: absolute;
      top: 50%;
      right: 15px;
      font-size: 1rem;
    }

  }
}

.template-stage-single__form__step__day__content{
  display:none;
}

.template-stage-single__next-btn-mobile{
  display:none;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0 !important;
  z-index: 30;
  height: 73px !important;
  font-size: 1.5rem;
  padding: 0px 20px 1px 20px;
}

@media (max-width: 767.98px){
.template-stage-single__next-btn-mobile {
  display: -webkit-inline-box !important;
  display: -webkit-inline-flex !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 73px !important;
  text-align: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 0 !important;
  z-index: 30;
  width:100%;
}
  .template-stage-single__form__step__head__message{
    max-width: 100%;
  }
}