.banner-code{
  background-color: #5251d4;color: #fff;
  font-family: roboto, Sans-serif;
  font-size: 25px;
  font-weight: 600;
  span{
    color:#f5d361;
  }
}

.infos-offer{
  font-size: 15px;
  background-color: #FBB03B;
  padding: 25px;
}
.tunnel{
  background-color: #EFF1F3;
  .fw-bold-title{
    font-weight: 800;
  }
  .block-rounded{
    border-radius: 10px;
  }
  .wrapper-img{
    width: 120px;
    img{
      object-fit:contain;
    }
  }
  small{
    color:#6D757D;
  }
  .title{
    text-align: center;
    margin-bottom: 50px;
    h2{
      color:#EB4E3C;
      margin-bottom: 20px;
      span{
        color:#1f2660;
      }
    }
  }
  .card-infos-user{
    border:1px solid #3B763D;
    background-color: #DFEFD9;
    color:#3B763D;
    padding: 20px;
    margin-bottom: 60px;
    border-radius: 10px;
  }
  .fa-check{
    color:#00CC00;
    width: 25px;
  }
  .fa-times{
    color:darkred;
    width: 25px;
  }

  select#nbr-child{
    width: 150px;
  }
  h4{
    color:#1f2660;
  }
  .table-responsive{
    margin-top: 40px;
  }
  ul.ul-check{
    li{
      list-style: none;
      position:relative;
      &:after{
        content: "";
        ackground-color: transparent;
        position: absolute;
        left: -24px;
        top: 6px;
        width: 7px;
        border-bottom: 3px solid #4D7C2A;
        height: 14px;
        border-right: 3px solid #4D7C2A;
        transform: rotate(45deg);
        -o-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
    }
  }
}
.btn-validate{
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  font-style: normal;
  text-decoration: none;
  letter-spacing: 0;
  word-spacing: 0;
  fill: #fff;
  color: #fff;
  background-color: #fbb03b;
  border-style: solid;
  border-width: 1px;
  border-color: #fbb03b;
  border-radius: 100px 100px 100px 100px;
  padding: 16px 55px;
  &:hover{
    color:#fbb03b;
    background-color: white;
  }
  &.blue{
    background-color: #544ddc!important;
    border-color: #544ddc!important;
    &:hover{
      color:#544ddc!important;
      background-color: white!important;
    }
  }
}
.u-spinner {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition: all 0.35s ease;
  -o-transition: all 0.35s ease;
  transition: all 0.35s ease;
  visibility: hidden;
  opacity: 0;
  z-index: 99999999;
  &.open{
    visibility: visible;
    opacity: 1;

  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    fill: #ffffff;
    font-size: 12rem;
  }

  .svg-icon {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    overflow: hidden;
  }
}
.speaking{
  font-family: 'NanumBrushScript',cursive;
  font-size: 21px;
  line-height: 30px;
}

.block-yellow{
  background-color: #FECC33;
  padding: 10px 25px;
}
.block-yellow-2 {
  background-color: #F4D989;
  padding: 10px 25px;
}
@media (min-width: 768px) {
  .tunnel{
    .select-offer{
      padding: 30px 20px 35px 20px;
      border-radius: 10px;
      border:1px solid #E7E7E7;
      margin-bottom: 30px;
      .best-price{
        font-weight: bolder;
        padding: 0 9px;
        background-color: #EB4E3C;
        color: white;
        display: inline-block;
        border-radius: 5px;
      }
      .form-check{
        align-items: center;
        display:flex;
      }
      label{
        margin-left: 15px;
        font-size: 25px;
        font-weight: bold;
        span{
          font-size: 18px;
          margin-left: 50px;
        }
      }
      .infos-offer{
        width: 300px;
      }
      &.actif{
        border: 3px solid #FBB03B!important;

      }
      &.most-offer {
        padding: 0!important;
        .block-details-price{
          padding: 10px;
        }
      }
    }
  }

}
@media (max-width: 767.98px) {
  .tunnel{
    .select-offer{
      padding: 30px 20px 35px 20px;
      border-radius: 10px;
      border:1px solid #E7E7E7;
      margin-bottom: 30px;
      .best-price{
        font-weight: bolder;
        background-color:#EB4E3C;
        color:white;
        padding-right: 20px;
        margin-bottom: 11px;
        padding-left: 6px;
      }
      .form-check{
        align-items: start;
        display:flex;
        margin-top: 34px;
        .form-check-input[type=radio]{
          margin-top: 11px;
        }

      }
      label{
        margin-left: 15px;
        font-size: 25px;
        font-weight: bold;
        span{
          font-size: 18px;
          display:block;
        }
      }
    }
  }
}