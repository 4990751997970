$wmax:  #{"only screen and (max-width:1440px)"};
$w1200:  #{"only screen and (max-width:1199px)"};
$w992:     #{"only screen and (max-width:991px)"};
$w768:      #{"only screen and (max-width:767px)"};
$w650:      #{"only screen and (max-width:650px)"};
$w480: #{"only screen and (max-width:480px)"};
$w380: #{"only screen and (max-width:380px)"};
$w320: #{"only screen and (max-width:320px)"};

.program-summary-header-mobile{
  display:none;
  width: 100%;
  color: #ffffff;
  background-color: #302b98;
  .top-bar-resume-mobile{
    padding: 15px 25px 15px 25px;
    .c-program-summary__top-bar__resume__content__hat {
      color: white;
      font-size: 1.8rem;
      font-weight: bold;
    }
    .c-program-summary__top-bar__resume__content__title {
      color: #ffffff;
      font-size: 1.6rem;
    }
    i{
      font-size: 1.5rem;
      display: inline;
      height: 1em;
      width: 0.58333em;
      -webkit-transition: -webkit-transform 0.35s ease 0s;
      transition: -webkit-transform 0.35s ease 0s;
      -o-transition: transform 0.35s ease 0s;
      transition: transform 0.35s ease 0s, -webkit-transform 0.35s ease 0s;
      &.active{
        webkit-transform: rotate(
                        180deg
        );
        -ms-transform: rotate(180deg);
        transform: rotate(
                        180deg
        );
      }
    }
  }

  .exit-btn{
    width: 70px;
    height: 100%;
    border-left: 1px solid rgba(226, 226, 230, 0.11);
    color: white;
    text-decoration: none;
    i{
      font-size: 25px;
    }
  }
}
.program-summary-top-bar{
  height: 85px;
  width: calc( 100% - 85px);
  max-width: calc( 1920px - 85px);
  margin: 0 auto;
  .top-bar-resume{
    height: 100%;
    width: 339px;
    background-color: #2f2a7c;
    border-bottom: 1px solid rgba(226, 226, 230, 0.11);
    position:relative;
    cursor: pointer;
    i{
      color:white;
      margin-left: 15px;
      font-size: 26px;
    }
    .thumb-wrapper{
      width: 69px;
      height: 49px;
      border-radius: 6px;
      margin-left: 21px;
      margin-right: 15px;
      overflow: hidden;
      img{
        display: block;
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
      }

    }
    .resume-content{
      width: 50%;
      .c-program-summary__top-bar__resume__content__hat {
        display: block;
        color: rgba(255, 255, 255, 0.4);
        font-size: 1.4rem;
      }
      .c-program-summary__top-bar__resume__content__title {
        display: block;
        color: #ffffff;
        font-size: 1.6rem;
      }
    }
    .exit-btn{
      -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      transform: translate(0, -50%);
      i{
        font-size: 2.7rem;
        color: white;
      }
    }
  }
  .top-bar-content{
    padding-left: 36px;
    padding-right: 20px;
    width: calc( 100% - 339px);
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 15px 1px rgb(5 16 43 / 17%);
    box-shadow: 0 0 15px 1px rgb(5 16 43 / 17%);
    .content-title{
      color: #273d52;
      font-family: "RubikMd", sans-serif;;
      font-size: 1.7rem;
      .c-program-summary__top-bar__content__title__headline {
        margin-left: 17px;
        font-size: 1.6rem;
        color: #617180;
        font-family: "Rubik";
        font-weight: normal;
        display:none;
      }
    }
    .content-link{
      color: #273d52;
      font-size: 1.5rem;
      text-decoration: underline;
      margin-right: 31px;
    }
    .button{
      padding: 13px 20px;
      color: #ffffff;
      font-size: 1.5rem;
      background-color: #e83279;
      border-radius: 27px;
      -webkit-box-shadow: 0 0 15px 1px rgb(5 16 43 / 11%), 0 7px 10px 0 rgb(39 61 82 / 13%);
      box-shadow: 0 0 15px 1px rgb(5 16 43 / 11%), 0 7px 10px 0 rgb(39 61 82 / 13%);
      -webkit-transition: all 0.35s ease;
      -o-transition: all 0.35s ease;
      transition: all 0.35s ease;
      text-decoration: none;
    }
    .top-bar-content-right{
      .qcm-navigation{
        li{
          width: 30px;
          height: 30px;
          border-radius: 30px;
          font-size: 1.3rem;
          color: #8996a2;
          border: 1px solid #8996a2;
          margin-right: 8px;
          &.active {
            color: #ffffff;
            background-color: #e83279;
            border-color: #e83279;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.main-program{
height: calc((var(--vh, 1vh) * 100)  - 85px);
  .list-programs-container-items{
    .program-summary-container-items {
      width: 339px;
      height: calc(100% - 60px);
    }
  }
  #template-media-container{
    width: calc( 100% - 339px);
    height: calc(100vh - 85px);
    overflow-y:auto;
    .exo-content{
      padding: 57px 9%;
      height: 100%;
      overflow-y: auto;
      h2{
        padding-bottom: 20px;
        display: block;
        color: #273d52;
        font-family: "RubikMd";
        font-size: 1.9rem;
        line-height: 2.3rem;
        margin-bottom: 0;
        font-weight: 500;
      }
      .exo-head{
        .template-program-qcm__content__head__separator {
          margin-top: 34px;
          margin-bottom: 38px;
        }
      }
      .line-question{
   /*     font-size: 16px;*/
        padding: 40px 0px 40px 0px!important;
        &+.line-question{
          border-top:1px solid rgba(0, 0, 0, 0.1);
        }
        button{
          padding: 0px 20px 1px 20px;
          text-align: center;
          height: 46px;
          font-size: 1.5rem;
          line-height: 1;
        }
      }
    }
    .video-kumullus {
      width:100%;
      height:360px;
      @media #{$w768} {
        width:100%;
      height:100%;
      }

    }
    .template-program-video-container{
      .video-content{
        width: 100%;
        .video-content-media-wrapper{
          position: relative;
          width: 100%;
          border-radius: 6px;
          margin-bottom: 55px;
          overflow: hidden;
          &::before{
            content: '';
            display: block;
            height: 0;
            padding-bottom: 56.25%;
          }
          iframe{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
          }
          .video-content-text-wrapper{
            padding-bottom: 30px;
          }
        }
        h3 {
          font-size:30px;
        }
        h4 {
          font-size:2rem;
        }
      }
    }
    .template-program-objectexercise-container{
      .qcm-content{
        .qcm-questions{
          .line-question{
            border-top:none;
            .qcm-questions-content{
              &.display-result{
                .qcm-choice{
                  pointer-events:none;
                  &.select{
                    &.true-answer{
                      background-color: #27c281;
                      .icones-result{
                        .fa-check-circle{
                          display:block;
                          color:white;
                        }
                      }
                    }
                    &:not(.true-answer){
                      background-color: #eb3c3c;
                      .icones-result{
                        .fa-times{
                          display:block;
                          color: white;
                        }
                      }
                    }
                  }
                  &.true-answer:not(.select){
                    .icones-result{
                      .fa-check-circle{
                        display:block;
                        color: darkgreen;
                      }
                    }
                  }

                }

              }
              .qcm-choice{
                padding: 20px 26px;
                display: block;
                font-size: 1.6rem;
                line-height: 2.3rem;
                background-color: #ffffff;
                color: #273d52;
                border-radius: 2px;
                -webkit-box-shadow: 0 10px 20px 1px rgb(5 16 43 / 15%);
                box-shadow: 0 10px 20px 1px rgb(5 16 43 / 15%);
                margin-bottom: 15px;
                cursor: pointer;
                -webkit-transition: all 0.35s ease;
                -o-transition: all 0.35s ease;
                transition: all 0.35s ease;
                p{
                  margin: 0!important;
                }
                &.select{
                  background-color: #423cb0;
                  color: #ffffff;
                }
                .icones-result{
                  i{
                    display:none;
                    font-size: 20px;
                  }
                }
              }
              .answer-comment{
                padding-top: 7px;
                padding-left: 60px;
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: none;
                -webkit-flex-wrap: wrap;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                -webkit-align-content: center;
                -ms-flex-line-pack: center;
                align-content: center;
                -webkit-box-align: center;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;
                color: #273d52;
                font-size: 1.5rem;
                line-height: 2rem;
                margin-bottom: 32px;
                i{
                  margin-right:10px;
                  font-size: 18px;
                }
              }
            }
            .user-action{
              button{
                font-size: 1.5rem;
                line-height: 1;
                text-align: center;
                &.js-next-question,&.js-result-final{
                  display:none;
                }
              }

            }
            &:not(:first-child){
              display:none;
            }
          }

        }
        .title-statement{
          display: block;
          color: #8996a2;
          font-size: 1.6rem;
          line-height: 2.4rem;
          margin-bottom: 20px;
        }
        .qcm-head-result,.explain-answer{
          display:none;
        }
        .user-action{
          display:flex;
        }
        &.dispay-end-qcm{
          .exo-head{
            display:none;
          }
          .qcm-head-result{
            display:block;
            h2{
              padding-bottom: 20px;
              display: block;
              color: #273d52;
              font-size: 1.9rem;
              line-height: 2.3rem;
              margin-bottom: 0;
              span{
                padding: 18px 21px;
                display: inline-block;
                color: #ffffff;
                background-color: #27c281;
                font-family: "Rubik";
                font-size: 1.9rem;
                line-height: 1;
                border-radius: 27px;
                margin-left: 7px;
              }
            }
          }
          .explain-answer{
            display:flex;
            button{
              text-decoration: underline;
              font-size: 1.5rem;
              color: #273d52;
              border: none;
            }
          }
          .user-action{
            display:none;
          }
          .line-question{
            display:block!important;
            padding: 40px 0px 40px 0px!important;

          }
          .answer-comment{
            display:flex!important;
          }
        }
      }
    }
    .template-program-statement-line-container{
      .block-question{
        .line-question{
          /*font-size: 16px;*/
          padding: 40px 0px 40px 0px!important;
          &+.line-question{
            border-top:1px solid rgba(0, 0, 0, 0.1);
          }
          .block-knowledge{
            display: none;
            margin-top: 40px;
            border-radius: 20px;
            border:2px solid $pink;
            padding: 15px;
            .title-knowledge{
              color:$pink;
              font-weight: 700;
              margin-bottom: 35px;
            }
          }
          button{
            padding: 0px 20px 1px 20px;
            text-align: center;
            height: 46px;
            font-size: 1.5rem;
            line-height: 1;
          }
        }
      }
    }
    .template-program-sheet-container{

    }
  }
}
.js-template-popin{
  &#js-menu-program-summary-mobile{
    display:none;
    border-radius: 0px;
  }
}
.list-programs-container-items{
  .program-summary-container-items{
    background-color: #2f2a7c;
    overflow-y:auto;
    position:relative;
    .summary-item{
      padding: 24px 35px;
      position: relative;
      text-decoration: none;
      &:hover{
        opacity: 1 !important;
        text-decoration: none;
        background-color: rgba(33, 30, 106, 0.4);
        .item-number{
          color: rgba(255, 255, 255, 0.8);
          border-color: rgba(255, 255, 255, 0.8);
        }
      }
      &.active{
        background-color: #211e6a;
        opacity: 1 !important;
        .item-number{
          border: none;
          background-color: #ffffff;
          color: #423cb0;
        }
      }

      .item-number{
        width: 32px;
        height: 32px;
        color: rgba(255, 255, 255, 0.2);
        font-size: 1.4rem;
        text-align: center;
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 50%;
        margin-right: 24px;
        -webkit-transition: all 0.35s ease;
        -o-transition: all 0.35s ease;
        transition: all 0.35s ease;
        &.finish{
          border: none;
          background-color: #ffffff;
          color: #423cb0;
        }

      }
      .item-icon{
        width: 32px;
        height: 32px;
        color: white;
        font-size: 1.4rem;
        text-align: center;
        margin-right: 24px;
        &.finish{
          color: $pink;
        }

      }
      .item-content{
        width: calc( 100% - 60px);
        .c-program-summary__item__content__type {
          display: block;
          color: #ffffff;
          font-size: 1.5rem;
          line-height: 2.2rem;
        }
        .c-program-summary__item__content__title {
          display: block;
          color: rgba(255, 255, 255, 0.4);
          font-size: 1.4rem;
          line-height: 2.2rem;
        }
      }
    }
  }
  .report-link-container{
    background-color: #2f2a7c;
    height: 60px;
    .report-link{
      color: rgba(255, 255, 255, 0.4);
      font-size: 1.5rem;
      line-height: 2.5rem;
      text-decoration: underline;
      bottom:0px;

      &:hover{
        color:white;
      }
    }
  }
}
.program-exercice-popin{
  h2{
    margin-bottom: 25px;
  }
  .content{
    .list-tips{
      li{
        color: black;
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin-bottom:10px;
      }
    }
  }
}
.display-mobile-next-step{

  display:none;
  a{
    height: 60px;
    color:white;
    background-color: #e83279;
    font-size: 1.5rem;
    text-decoration: none;
    &:hover{
      background-color: #e83279;
    }
  }
}

.template-finish{
  padding: 57px 9%;
  height: 100%;
  .thumb-image{
    width: 218px;
    height: 154px;

    img{
      border-radius: 10px;
      object-fit:cover;
    }
  }
  h2{

    display: block;
    color: #273d52;
    font-family: "RubikMd";
    font-size: 1.9rem;
    line-height: 2.3rem;
    margin-bottom: 40px;
  }
  hr{
    margin-top:40px;
    margin-bottom: 40px;
  }
  #content{
    padding-left: 35px;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  ul{
    padding-left: 35px;
    li{
      &:hover{
        .u-icon-play {
          display: flex !important;
        }
      }
      .wrapper{
        width: 190px;
        height: 120px;
        position:relative;

        .u-icon-play {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          display: none;
          width: 41px;
          height: 41px;
          border-radius: 50%;
          background-color: #ffffff;
          z-index: 1;
          i {
            color: #8B77FB;
            font-size: 1.4rem;
            margin-left: 4px;
            z-index: 2;
          }
        }
        img{
          object-fit: cover;
          border-radius: 10px;
        }
      }
      a{
        color:black;
        text-decoration: none;
        font-size: 1.6rem;
      }
    }
  }

}

.auto-eval-popin {
  h3 {
    margin-bottom: 18px;
  }

  .eval-text-left {
    margin-right: 12px;
  }

  .eval-text-right {
    margin-left: 12px;
  }

  .fa-star {
    cursor: pointer;

    &.hover, &.checked {
      color: orange;
    }
  }

  .btn-auto-eval {
    padding: 0px 20px 1px 20px;
    text-align: center;
    height: 46px;
    font-size: 1.5rem;
    line-height: 1;
  }

  a.btn-auto-eval {
    padding-top: 14px;
  }
}

.program-activity-list {
  margin-top: 32px;

  .video-check .fa {
    color: #27c281;
    font-size: 20px;
  }

  table tfoot {
    border: 2px solid #27c281;
  }

  .redo-program {
    font-size: 1.5rem;
    height: 46px;
    border-radius: 3rem;
    padding: 10px 20px;
  }
}